import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WeightBalanceMobileRoutingModule } from './weight-balance-mobile-routing.module';
import { EffectsModule } from '@ngrx/effects';
import { WeightBalanceMobileEffects } from './state/weight-balance-mobile.effects';
import { StoreModule } from '@ngrx/store';
import * as fromWeightBalanceMobile from './state/weight-balance-mobile.reducer';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		WeightBalanceMobileRoutingModule,
		EffectsModule.forFeature([WeightBalanceMobileEffects]),
		StoreModule.forFeature(fromWeightBalanceMobile.weightBalanceMobileFeatureKey, fromWeightBalanceMobile.reducer),
	],
})
export class WeightBalanceMobileModule {}
