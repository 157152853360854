import { KeyValuePipe } from '@angular/common';
import { Component, effect, inject, input, output, signal } from '@angular/core';
import { FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Component as ComponentItem } from '@shared/data-provider/app-supporter';
import { NgZorroAntdModule } from 'src/app/ng-zorro-antd.module';
import { selectComponentsCurrentItem } from '../../store/control-service.selectors';
import { Store } from '@ngrx/store';
import { ControlServiceComponentActions } from '../../store/control-service.actions';
import { GlobalI18n } from '@settings/global-i18n';
import { Module } from '@shared/models/module-name';

@Component({
	selector: 'app-component-modal',
	standalone: true,
	imports: [NgZorroAntdModule, FormsModule, ReactiveFormsModule, KeyValuePipe],
	templateUrl: './component-modal.component.html',
	styleUrl: './component-modal.component.less',
})
export class ComponentModalComponent {
	componentNewModalIsVisible = input<boolean>();
	componentNewModalIsVisibleChange = output<boolean>();
	private readonly store = inject(Store);
	readonly currentComponent = this.store.selectSignal(selectComponentsCurrentItem);

	validateForm = this.fb.group({
		org: this.fb.control('', [Validators.required]),
		project: this.fb.control('', [Validators.required]),
		componentType: this.fb.control('', [Validators.required]),
		url: this.fb.control('', [Validators.required]),
		password: this.fb.control('', [Validators.required]),
	});

	componentList = ComponentItem.ComponentTypeEnum;

	isEditMode = false;

	passwordNewModalIsVisible = false;

	constructor(private fb: NonNullableFormBuilder, private globalI18n: GlobalI18n) {
		effect(() => {
			if (this.currentComponent()) {
				this.isEditMode = true;

				this.validateForm.setValue({
					org: this.currentComponent()?.org,
					project: this.currentComponent()?.project,
					componentType: this.currentComponent()?.componentType,
					url: this.currentComponent()?.url,
					password: this.currentComponent()?.password,
				});
			} else {
				this.isEditMode = false;
			}
		});
	}

	handleComponentNewOk(): void {
		if (this.validateForm.valid) {
			console.log('submit', this.validateForm.value);
			const component = this.validateForm.value as ComponentItem;
			this.componentNewModalIsVisibleChange.emit(false);
			if (this.isEditMode) {
				this.store.dispatch(
					ControlServiceComponentActions.update({ id: this.currentComponent().id, component: component })
				);
			} else {
				this.store.dispatch(ControlServiceComponentActions.create({ component }));
			}
		} else {
			Object.values(this.validateForm.controls).forEach(control => {
				if (control.invalid) {
					control.markAsDirty();
					control.updateValueAndValidity({ onlySelf: true });
				}
			});
		}
	}

	handleComponentNewCancel(): void {
		this.componentNewModalIsVisibleChange.emit(false);
	}

	get title(): string {
		if (this.isEditMode) {
			return this.globalI18n.getMessage(Module.ControlService, 'editComponent');
		} else {
			return this.globalI18n.getMessage(Module.ControlService, 'addComponent');
		}
	}
}
