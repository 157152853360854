export class FilterItem {
	constructor(section: 'object' | 'group' | 'parameters', field: string, expression: string, value: string) {
		this.section = section;
		this.field = field;
		this.expression = expression;
		this.value = value;
	}
	section: 'object' | 'group' | 'parameters';
	field: string;
	expression: string;
	value: string;
}

export class NotificationFilter {
	constructor(subscribe: string, filters: FilterItem[]) {
		this.subscribe = subscribe;
		this.filters = filters;
	}

	subscribe: string;
	filters: FilterItem[];
}
