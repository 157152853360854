import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ApiResponse, Component, Status } from '@shared/data-provider/app-supporter';

export const ControlServiceComponentActions = createActionGroup({
	source: 'Components',
	events: {
		'Read All': emptyProps(),
		'Read All Success': props<{ components: Component[] }>(),
		Create: props<{ component: Component }>(),
		'Create Success': props<{ apiResponse: ApiResponse }>(),
		Update: props<{ id: number; component: Component }>(),
		'Update Success': props<{ apiResponse: ApiResponse }>(),
		Read: props<{ id: number }>(),
		'Read Success': props<{ component: Component }>(),
		'New Item': emptyProps(),
		'New Item Success': emptyProps(),
		'Clear Current Item and Status': emptyProps(),
	},
});

export const ControlServiceDefaultActions = createActionGroup({
	source: 'Default',
	events: {
		Restart: props<{ id: number }>(),
		'Restart Success': props<{ apiResponse: ApiResponse }>(),
		Status: props<{ id: number }>(),
		'Status Success': props<{ status: Status }>(),
	},
});
