import { createReducer, on } from '@ngrx/store';
import { ControlServiceComponentActions, ControlServiceDefaultActions } from './control-service.actions';
import { ApiResponse, Component, Status } from '@shared/data-provider/app-supporter';

export const controlServiceFeatureKey = 'controlService';

export interface ComponentsState {
	items: Component[];
	currentItem: Component;
	status: Status;
	error: ApiResponse | null;
}

export const initialState: ComponentsState = {
	items: [],
	currentItem: null,
	status: null,
	error: null,
};

export const reducer = createReducer(
	initialState,
	on(
		ControlServiceComponentActions.readSuccess,
		(state, payload): ComponentsState => ({ ...state, currentItem: payload.component })
	),
	on(
		ControlServiceComponentActions.readAllSuccess,
		(state, payload): ComponentsState => ({ ...state, items: payload.components })
	),
	on(
		ControlServiceComponentActions.createSuccess,
		(state, payload): ComponentsState => ({ ...state, error: payload.apiResponse })
	),
	on(
		ControlServiceComponentActions.updateSuccess,
		(state, payload): ComponentsState => ({ ...state, error: payload.apiResponse })
	),
	on(
		ControlServiceDefaultActions.restartSuccess,
		(state, payload): ComponentsState => ({ ...state, error: payload.apiResponse })
	),
	on(
		ControlServiceDefaultActions.statusSuccess,
		(state, payload): ComponentsState => ({ ...state, status: payload.status })
	),
	on(
		ControlServiceComponentActions.newItemSuccess,
		(state): ComponentsState => ({
			...state,
			currentItem: null,
		})
	),
	on(
		ControlServiceComponentActions.clearCurrentItemAndStatus,
		(state): ComponentsState => ({
			...state,
			currentItem: null,
			status: null,
		})
	)
);
