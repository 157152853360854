import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WeightBalanceComponent } from './components/weight-balance.component';

const routes: Routes = [{ path: 'weight-balance', component: WeightBalanceComponent }];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class WeightBalanceRoutingModule {}
