export class Desktop {
	constructor() {
		this.id = null;
		this.name = '';
		this.dtRange = [new Date(), new Date(2099, 1, 1, 23, 59)];
	}
	id: number | null;
	name: string;
	dtRange: Array<Date>;
}
