import { createFeatureSelector, createSelector } from '@ngrx/store';
import { weightBalanceMobileFeatureKey, State } from './weight-balance-mobile.reducer';

export const selectWeightBalanceMobileStore = createFeatureSelector<State>(weightBalanceMobileFeatureKey);

export const selectAircraftTypes = createSelector(selectWeightBalanceMobileStore, (state: State) => {
	return state.aircraftTypes;
});

export const selectAirlines = createSelector(selectWeightBalanceMobileStore, (state: State) => {
	return state.airlines;
});

export const selectAirports = createSelector(selectWeightBalanceMobileStore, (state: State) => {
	return state.airports;
});

export const selectTails = createSelector(selectWeightBalanceMobileStore, (state: State) => {
	return state.tails;
});

export const selectFlights = createSelector(selectWeightBalanceMobileStore, (state: State) => {
	return state.flights;
});

export const selectDocument = createSelector(selectWeightBalanceMobileStore, (state: State) => {
	return state.document;
});

export const selectElectronDocuments = createSelector(selectWeightBalanceMobileStore, (state: State) => {
	return state.electronicDocuments;
});

export const selectDesktops = createSelector(selectWeightBalanceMobileStore, (state: State) => {
	return state.desktops;
});
