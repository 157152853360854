<nz-layout class="h-100">
	<nz-header class="bg-white border-bottom">
		<nz-space>
			<p
				*nzSpaceItem
				class="text-black-50"
				i18n="@@home.header.name">
				Home
			</p>
		</nz-space>
	</nz-header>
	<nz-content class="h-100 bg-white">
		<div
			nz-row
			class="h-100">
			<div
				nz-col
				nzSpan="4"
				class="bg-light">
				<nz-card
					[nzBordered]="false"
					class="bg-light">
					<div class="d-flex flex-column text-center">
						<div class="mb-2">
							<nz-avatar
								[nzSize]="userAvatarSize"
								nzSrc="{{ userDefaultAvatarImageUrl }}"></nz-avatar>
						</div>
						<div>
							<h3
								nz-typography
								class="m-0">
								{{ userFullName }}
							</h3>
							<span
								nz-typography
								nzType="secondary"
								>{{ userLogin }}</span
							>
						</div>
					</div>
				</nz-card>
			</div>
			<div
				nz-col
				nzSpan="20">
				<div
					nz-row
					class="h-100"
					nzJustify="space-around"
					nzAlign="middle">
					<div
						nz-col
						nzSpan="24"></div>
				</div>
			</div>
		</div>
	</nz-content>
	<!-- <nz-footer></nz-footer> -->
</nz-layout>
