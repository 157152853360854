/**
 * App supporter
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Component { 
    id?: number;
    org?: string;
    project?: string;
    componentType?: Component.ComponentTypeEnum;
    url?: string;
    password?: string;
    lastupdate?: string;
}
export namespace Component {
    export type ComponentTypeEnum = 'backend' | 'cron' | 'sender' | 'receiver' | 'emulator' | 'reports';
    export const ComponentTypeEnum = {
        Backend: 'backend' as ComponentTypeEnum,
        Cron: 'cron' as ComponentTypeEnum,
        Sender: 'sender' as ComponentTypeEnum,
        Receiver: 'receiver' as ComponentTypeEnum,
        Emulator: 'emulator' as ComponentTypeEnum,
        Reports: 'reports' as ComponentTypeEnum
    };
}


