<div class="px-4 py-2 border-bottom rounded-bottom bg-light">
	<div
		nz-flex
		[nzVertical]="false"
		[nzJustify]="'space-between'">
		<div class="flex-item">
			<span
				nz-typography
				class="text-uppercase"
				i18n="@@wb-mobile.flights.item.flight"
				>Flight</span
			>
			<p class="h2">
				{{ flight?.airlineIata }}<span *ngIf="flight?.airlineIata !== ''">-</span>{{ flight?.flightNumber }}
			</p>
		</div>
		<div class="flex-item">
			<span
				nz-typography
				class="text-uppercase"
				i18n="@@wb-mobile.flights.item.acreg"
				>A\C Reg</span
			>
			<p class="h2">{{ flight?.tail }}</p>
		</div>
	</div>

	<div
		nz-flex
		[nzVertical]="false"
		[nzJustify]="'space-between'">
		<div class="flex-item">
			<span
				nz-typography
				class="text-uppercase"
				i18n="@@wb-mobile.flights.item.datePlan"
				>Date plan</span
			>
			<p class="h5">{{ flight?.route[0].dtDeparture[0] | dateFormat : 'DD.MM.YY HH:mm' : userShowTime }}</p>
		</div>
		<div class="flex-item">
			<span
				nz-typography
				class="text-uppercase"
				i18n="@@wb-mobile.flights.item.route"
				>Route</span
			>
			<p class="h5">{{ flight.route[0].airportIata }}-{{ flight.route[1].airportIata }}</p>
		</div>
		<div class="flex-item">
			<span
				nz-typography
				class="text-uppercase"
				i18n="@@wb-mobile.flights.item.document"
				>Documents</span
			>
			<p
				*ngIf="flight?.edno !== 0 && flight?.electronicDocument !== null"
				class="h5">
				{{ flight?.edno }}
			</p>
		</div>
	</div>
	<nz-alert
		*ngIf="flight.electronicDocument.status === DocumentStatus.APPROVED"
		nzType="success"
		[nzMessage]="documentStatusText"
		[nzDescription]="flight.electronicDocument.note"
		nzShowIcon></nz-alert>
	<nz-alert
		*ngIf="flight.electronicDocument.status === DocumentStatus.REJECTED"
		nzType="error"
		[nzMessage]="documentStatusText"
		[nzDescription]="flight.electronicDocument.note"
		nzShowIcon></nz-alert>
	<ng-template #documentStatusText>
		<span class="text-capitalize">{{ getStatusName(flight.electronicDocument.status) }}</span
		>&nbsp;<span i18n="@@wb-mobile.flights.item.by">by</span>&nbsp;<span class="font-weight-bold">{{
			flight.electronicDocument.pilot
		}}</span
		>&nbsp;<span i18n="@@wb-mobile.flights.item.at">at</span>&nbsp;<span class="font-weight-bold">{{
			flight.electronicDocument.pilot_decision_date | dateFormat : 'DD.MM.YY HH:mm' : userShowTime
		}}</span>
	</ng-template>
</div>

<ul
	class="nav nav-tabs mt-1 mx-3"
	id="myTab"
	role="tablist">
	<li
		*ngFor="let document of documentType; let i = index"
		class="nav-item"
		role="presentation">
		<button
			class="nav-link"
			[ngClass]="{ active: currentDocumentIndex === i }"
			id="{{ document }} {{ i }}"
			data-toggle="tab"
			type="button"
			role="tab"
			(click)="documentIndexChange(i)">
			{{ document }}
		</button>
	</li>
</ul>
<div class="document-view">
	<div
		*ngIf="document === null; else showDocument"
		class="d-flex h-100 align-items-center justify-content-center">
		<nz-spin
			nzSimple
			[nzSize]="'large'"></nz-spin>
	</div>
	<ng-template #showDocument>
		<pre
			class="document-scroll p-3 text-body"
			[ngClass]="{
				'document-text-normal': currentDocumentIndex === 0,
				'document-text-small': currentDocumentIndex === 1
			}"
			>{{ document?.text }}</pre
		>
	</ng-template>
</div>
