import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WeightBalanceMobileComponent } from './components/weight-balance-mobile.component';
import { WeightBalanceMobileItemComponent } from './components/weight-balance-mobile-item/weight-balance-mobile-item.component';
import { AppAuthGuard } from '../../app.authguard';

const routes: Routes = [
	{
		path: 'weight-balance-mobile',
		component: WeightBalanceMobileComponent,
		canActivate: [AppAuthGuard],
		data: {
			roles: ['aac_balance-mobile'],
		},
		children: [{ path: 'flights/:id', component: WeightBalanceMobileItemComponent }],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class WeightBalanceMobileRoutingModule {}
