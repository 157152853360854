import { Component, OnInit, LOCALE_ID, Inject, AfterViewInit, inject } from '@angular/core';
import { Router, NavigationError } from '@angular/router';

import { KeycloakService } from 'keycloak-angular';
import { environment } from '../environments/environment';

import { DeviceDetectorService } from 'ngx-device-detector';
import { NzI18nService, en_US, ru_RU } from 'ng-zorro-antd/i18n';
import { SettingsService } from '@core/services/settings/settings.service';
import { LanguageItem } from '@core/services/settings/language';
import { firstValueFrom, skip, switchMap } from 'rxjs';
import { Module } from '@shared/models/module-name';
import { DOCUMENT } from '@angular/common';
import { NotificationsService } from '@core/services/notifications/notifications.service';
import { GlobalI18n } from '@settings/global-i18n';
import { ModuleItem } from '@core/services/settings/modules';
import { AppUpdateVersionService } from "@core/services/app-update-version/app-update-version.service";

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit, AfterViewInit {
	deviceInfo = null;
	interval: any;
	private _availableModules: ModuleItem[] = [];
	private languages: LanguageItem[] = [];
	private _module = Module;
  appUpdateVersionService = inject(AppUpdateVersionService);

	constructor(
		@Inject(LOCALE_ID) protected localeId: string,
		@Inject(DOCUMENT) private document: Document,
		private i18n: NzI18nService,
		private router: Router,
		private keycloakService: KeycloakService,
		private deviceService: DeviceDetectorService,
		private settingsService: SettingsService,
    private notificationsService: NotificationsService,
    private globalI18n: GlobalI18n,
	) {
		this.getDeviceInfo();
		settingsService.user.language$.subscribe(language => {
			if (language) {
				this.loadCurrentLanguageInterface(language);
			}
		});
		settingsService.module.availableForUser$.subscribe(modules => {
			if (modules) {
				// Сортировка модулей в алфавитном порядке
				modules.sort((a, b) => a.name.localeCompare(b.name));
				this._availableModules = modules;
			}
		});

		this.router.events.subscribe(e =>  {
			if (e instanceof NavigationError) {
				console.log(`Navigation error -> ${e}`);
				this.router.navigate(['/home']);
			}
		});

		settingsService.languageList.list$.subscribe(languages => {
			if (languages) {
				this.languages = languages;
			}
		});

		this.settingsService.user.settings$.subscribe(settings => {
			if (settings) {
				if (this.router.url === null || this.router.url === '' || this.router.url === '/') {
          // Если для работы доступен только 1 модуль, произвести его загрузку
          if (this.availableModules.length === 1) {
            this.router.navigate([this.availableModules[0].path]);
          } else if (settings.loadableModule !== Module.Unknown && this.settingsService.module.getModule(settings.loadableModule)?.path) {
            // Загрузка избранного модуля из настроек пользователя
						this.router.navigate([this.settingsService.module.getModule(settings.loadableModule).path]);
					} else {
            // Загрузка домашней страницы во всех других случаях
						this.router.navigate(['/home']);
					}
				}
			}
		});

    this.appUpdateVersionService.checkForUpdates()
	}

	tokenRefreshInterval: any;
	// Версия приложения из переменных окружения
	applicationVersion = environment.applicationVersion;
	// Хеш и время сборки приложения
	applicationBuild = environment.applicationBuild;
	production = environment.production;

	getDeviceInfo() {
		this.deviceInfo = this.deviceService.getDeviceInfo();
	}

	async ngOnInit() {
		if (await this.keycloakService.isLoggedIn()) {
			// Получение параметров токена после успешной авторизации
			await this.keycloakService.loadUserProfile().then(profile => {
				this.settingsService.user.setProfile(profile);
				this.settingsService.user.setRoles(this.keycloakService.getUserRoles());
				this.settingsService.user.setToken(this.keycloakService.getKeycloakInstance().tokenParsed);
			});
		}

		// Обновление токена Keycloak каждые 5 минут (300000)
		this.tokenRefreshInterval = setInterval(() => {
			this.keycloakService
				.updateToken(600)
				.then();
		}, 300000);
	}

  ngAfterViewInit() {
    this.notificationsService.initialization();
  }

	async logout() {
		await this.keycloakService.logout(this.document.location.origin);
	}

	get availableModules() {
		return this._availableModules;
	}

	get userFullName() {
    const firstName =
			this.settingsService.user.getFirstName() !== undefined ? this.settingsService.user.getFirstName() : '';
		const lastName =
			this.settingsService.user.getLastName() !== undefined ? this.settingsService.user.getLastName() : '';
    if (firstName === '' && lastName === '') {
      return this.globalI18n.getMessage(Module.General, 'userUnknown');
    } else {
      return firstName + ' ' + lastName;
    }
	}

	// Функция загрузки интерфейса на необходимом языке
	loadCurrentLanguageInterface(language) {
		// Если язык перехода есть в списке поддерживаемых, то перезагрузка
		// сайта на нужном языке
		let findLang = false;
		for (const lang of this.languages) {
			if (lang.code === this.getLocaleId().toUpperCase()) {
				findLang = true;
				break;
			}
		}
		if (findLang) {
			if (this.getLocaleId().toUpperCase() !== language) {
				const langURL = `/${language.toLowerCase()}${this.router.url}`;
				window.location.href = langURL;
			}
		}
		switch (language) {
			case 'EN':
				this.i18n.setLocale(en_US);
				break;
			case 'RU':
				this.i18n.setLocale(ru_RU);
				break;
			default:
				this.i18n.setLocale(en_US);
				break;
		}
	}

	getLocaleId(): string {
		return this.localeId;
	}

	get Module() {
		return this._module;
	}

	isUserHasRoleForAccessToModule(id: Module) {
		return this.settingsService.user.isUserHasRoleForAccessToModule(id);
	}
}

export function initializer(keycloak: KeycloakService, settingsService: SettingsService) {
	return (): Promise<boolean> =>
		firstValueFrom(
			settingsService.general.keycloakConfig$.pipe(
				skip(1),
				switchMap(config => {
					return keycloak.init({
						config: {
							url: config.issuer,
							realm: config.realm,
							clientId: config.clientId,
						},
						loadUserProfileAtStartUp: false,
						initOptions: {
							onLoad: 'login-required',
							checkLoginIframe: false,
						},
						bearerExcludedUrls: ['/assets'],
					});
				})
			)
		);
}
