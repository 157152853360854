export class NotificationMessage {
	constructor(title: string, message: string, id?: string, target?: number, object?: string) {
		this.id = id;
		this.object = object;
		this.target = target;
		this.title = title;
		this.message = message;
	}
	readonly id?: string;
	readonly object?: string;
	readonly target?: number;
	readonly title: string;
	readonly message: string;
}
