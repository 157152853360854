import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { firstValueFrom, throwError } from 'rxjs';
import { ScheduleModuleRestApiService } from '../schedule-module-data/schedule-module-rest-api.service';
import { WeightBalanceModuleRestApiService } from '../weight-balance-data/weight-ballance-rest-api-service';
import {
	Flight as FlightWB,
	AhmDataHoldsAndCompartments,
	BayMap,
	AhmConfiguration,
	ConfigurationSection,
} from '../weight-balance-data/weight-balance';
import { ReferanceBoardPass, RandomPassanger } from './referances';
import { FlightListItem, Flight, Route, Flights, Process, ProcessesResources } from '../schedule-module-data/flight';
import moment from 'moment';
import { LostFoundModuleRestApiService } from '../lost-found-module/services/lost-found-rest-api.service';
import { Ahm, AhmData, HoldsAndCompartments } from 'src/app/weight-balance-data/weight-balance';
import { BalanceRestApiService } from '../administration-module/services/balance-rest-api.service';
import * as bayMapFunctons from '@shared/functions/bayMap';
import { ReferanceModuleRestApiService } from '../referance-module-data/referance-module-rest-api.service';
import { ReferanceTail, ReferanceAirport, ReferanceAirline } from '../referance-module-data/referance';
import { FileSaverService } from 'ngx-filesaver';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { REFERENCES_LIST, References } from '../lost-found-module/types/types';
import { ReferencesService } from '../lost-found-module/services/references.service';
import { SettingsService } from '@core/services/settings/settings.service';

export class StatementStatus {
	constructor(obj?: StatementStatus, private lang?: string) {
		if (obj) {
			this.statementDt = new Date(obj.statementDt);
			this.statementName = obj.statementName;
			if (obj.baggages.length > 0) {
				this.baggages = [];
				obj.baggages.forEach(baggage => {
					this.baggages.push(new StatementStatusBaggage(baggage, this.lang));
				});
			}
		} else {
			this.statementDt = null;
			this.statementName = '';
			this.baggages = [];
		}
	}
	baggages: Array<StatementStatusBaggage>;
	statementDt: Date;
	statementName: string;
}

export class StatementStatusBaggage {
	constructor(obj: StatementStatusBaggage, private lang: string) {
		Object.assign(this, obj);
	}
	color: Array<string>;
	forwarding: { dt: Date; arrivalDt: Date };
	status: number;
	tag: string;
	type: Array<string>;

	private isDateNull(value: Date): boolean {
		if (!value || +value[0] < 2) {
			return false;
		} else {
			return true;
		}
	}

	get colorNameLocal() {
		return this.lang == 'RU' ? this.color[1] : this.color[0];
	}

	get typeNameLocal() {
		return this.lang == 'RU' ? this.type[1] : this.type[0];
	}

	get forwardingDt() {
		if (this.isDateNull(this.forwarding.dt)) {
			return new Date(this.forwarding.dt);
		} else {
			return null;
		}
	}

	get forwardingArrivalDt() {
		if (this.isDateNull(this.forwarding.arrivalDt)) {
			return new Date(this.forwarding.arrivalDt);
		} else {
			return null;
		}
	}
}

@Component({
	selector: 'app-test',
	templateUrl: './test.component.html',
	styleUrls: ['./test.component.less'],
})
export class TestComponent implements OnInit {
	referanceBoardPass: ReferanceBoardPass = new ReferanceBoardPass();
	flights: Array<FlightListItem> = [];
	flightsWB: Array<FlightWB> = [];
	flight: Flight = new Flight(1);
	selectedFlightId = -1;
	selectedFlightIdWB = -1;
	passangerId = 0;
	barCodeText = '';
	channel: any;
	message: any;
	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		}),
	};

	// Lost and found
	// references = {
	//   airlines: [],
	//   airports: [],
	//   baggage_categories: [],
	//   baggage_types: [],
	//   baggage_materials: [],
	//   baggage_colors: [],
	//   baggage_elements: [],
	//   statement_types: [],
	//   statement_manual_types: [],
	//   irregularity_codes: [],
	//   baggage_damage_types: [],
	//   baggage_damage_levels: [],
	//   baggage_damage_locations: [],
	//   baggage_internals: [],
	//   baggage_internal_categories: [],
	//   baggage_statuses: [],
	// };

	// selectLoadAnimation = {
	//   airlines: true,
	//   airports: true,
	//   baggage_categories: true,
	//   baggage_types: true,
	//   baggage_materials: true,
	//   baggage_colors: true,
	//   baggage_elements: true,
	//   statement_types: true,
	//   irregularity_codes: true,
	//   baggage_damage_types: true,
	//   baggage_damage_levels: true,
	//   baggage_damage_locations: true,
	//   baggage_internals: true,
	//   storages: false,
	//   notifications: false,
	//   worldTracer: false,
	//   baggage_statuses_animation: false,
	// };

	references: References = new References();

	// 1 Неприбытие, 5 Невостребованный, 7 Засланный, 8 Задержанный, 3 Поврежденный, 2 Недостача
	statementTypesOrder: [1, 5, 7, 8, 3, 2];
	statementManualOrder: [5, 7, 8];

	// Текущая просматриваемая вкладка
	activeTab = 'tab-balance';

	balance = {
		typeTelegram: '',
		textTelegram: '',
	};

	selectedAhmDataId = null;
	_ahmsData: AhmData[] = [];
	// ahmsData: AhmData[];
	ahmData: AhmData = new AhmData();
	ahms: Ahm[];
	ahm: Ahm = new Ahm();
	selectedAirlineData;
	ahmDataText = '';
	ahmDataTextCompartmentsCrews = '';

	ahmsDataSelect = false;
	weightAndBalanceResult = '';
	referenceAndBalanceResult = '';
	// для карты багажников
	trunkMap: bayMapFunctons.CompartmentsMap = new bayMapFunctons.CompartmentsMap();

	tail: ReferanceTail = new ReferanceTail();
	airport: ReferanceAirport = new ReferanceAirport();
	airline: ReferanceAirline = new ReferanceAirline();
	private privateAirports: Array<ReferanceAirport> = [];
	private privateAirlines: Array<ReferanceAirline> = [];

	statementStatusForm: UntypedFormGroup;

	private apiTestUrl: string;
	private apiWeightBalanceUrl: string;

	constructor(
		private http: HttpClient,
		public restApi: ScheduleModuleRestApiService,
		public restApiWB: WeightBalanceModuleRestApiService,
		public restApiLF: LostFoundModuleRestApiService,
		public restApiWBAdmin: BalanceRestApiService,
		public restApiReference: ReferanceModuleRestApiService,
		private settingsService: SettingsService,
		private fileSaverService: FileSaverService,
		public referencesService: ReferencesService,
		private fb: UntypedFormBuilder
	) {
		settingsService.general.applicationConfig$.subscribe(config => {
			this.apiTestUrl = config.apiTestURL;
			this.apiWeightBalanceUrl = config.apiWeightBalanceURL;
		});
		this.loadFlights();
		this.loadFlightsWB();
		// this.loadReferences();
	}

	async ngOnInit(): Promise<void> {
		this.statementStatusForm = this.fb.group({
			surname: ['', Validators.required],
			file: ['', Validators.required],
		});
		const referencesList = [
			// REFERENCES_LIST.airlines,
			// REFERENCES_LIST.airports,
			REFERENCES_LIST.baggage_statuses,
		];
		const statementTypesOrder = [1, 5, 7, 8, 3, 2];
		await Promise.all([
			this.referencesService.loadReferences(referencesList).then(data => {
				this.references = data;
			}),
			this.referencesService.loadStatementTypes(null, statementTypesOrder, this.settingsService.language).then(data => {
				this.references.statement_types = data;
			}),
		]);
	}

	changeTab(event: { target: { id: string } }) {
		this.activeTab = event.target.id;
		this.loadTab();
	}

	loadTab() {
		switch (this.activeTab) {
			case 'tab-comman': {
				break;
			}
			case 'tab-barcode': {
				this.loadFlights();
				this.selectedFlightId = -1;
				break;
			}
		}
	}

	showTab(item: string): boolean {
		return item === this.activeTab;
	}

	sendMessage(channel: string, message: string) {
		return this.addMessage(channel, message).subscribe((data: {}) => {
			this.message = '';
			this.channel = '';
		});
	}

	addMessage(channel: string, message: string) {
		return this.http
			.post(this.apiTestUrl + '/messages/' + channel, message)
			.pipe(retry(1), catchError(this.handleError));
	}

	sendBalanceTlg() {
		return this.sendTlg(this.balance).subscribe((data: {}) => {
			this.balance = {
				typeTelegram: '',
				textTelegram: '',
			};
		});
	}

	sendTlg(balance: any) {
		return this.http
			.post(this.apiWeightBalanceUrl + '/debug/' + balance.typeTelegram, balance.textTelegram)
			.pipe(retry(1), catchError(this.handleError));
	}

	// For Barcode generator
	loadFlights() {
		return this.restApi.getFlights(null).subscribe((data: Flights) => {
			for (const item of data.departure) {
				const flight = new FlightListItem();
				Object.assign(flight, item);
				this.flights.push(flight);
			}
			if (this.flights.length > 0) {
				this.generateBarcodeForFlight(this.flights[0].id);
			}
		});
	}

	loadFlightsWB() {
		this.restApiWB.getFlights(null, null).then(data => {
			this.flightsWB = [];
			data.forEach(el => {
				const flight = new FlightWB(this.settingsService.general.getHomeAirport());
				Object.assign(flight, el);
				this.flightsWB.push(flight);
			});
		});
	}

	loadFlight(id: number) {
		if (id == 0) {
			return;
		}
		return this.restApi.getFlight(id).subscribe((data: Flight) => {
			Object.assign(this.flight, data);
			this.flight.route = [];

			for (const item of data.route) {
				const route = new Route();
				Object.assign(route, item);
				this.flight.route.push(route);
			}

			for (const key in data.processes) {
				const processes = new Process();
				Object.assign(processes, data.processes[key]);
				if (processes.resources && processes.resources.length == 0) {
					processes.resources.push(new ProcessesResources());
				}
				this.flight.processes[key] = processes;
			}
		});
	}

	async generateBarcodeForFlight(id) {
		this.selectedFlightId = id;
		await this.loadFlight(id);
		// Barcode string
		// M1MAMLEEVA/LARISA     EFVMUYR SVXSVOSU 1419 057Y008E0052 100
		//
		// Format size and example fields:
		// Format Code: size 1 - 'M'
		// Number of Legs Encoded: size 1 - '1'
		// Passenger Name: size 20 - 'MAMLEEVA/LARISA     '
		// Electronic Ticket Indicator: size 1 - 'E'
		// Operating carrier PNR Code: size 7 - 'FVMUYR '
		// FromCityAirportCode: size 3 - 'SVX'
		// To City Airport Code: size 3 - 'SVO'
		// Operating carrier Designator: size 3 - 'SU '
		// Flight Number: size 5 - '1419 '
		// Date of Flight (Julian Date): size 3 - '057'
		// Compartment Code: size 1 - 'Y'
		// Seat Number: size 4 - '008E'
		// Check-in Sequence Number: size 5 - '0052 '
		// Passenger Status: size 1 - '1'
		// Field Size of variable size field (Conditional + Airline item 4): size 2 - '00'
	}

	setFlightIdWB(id) {
		this.selectedFlightIdWB = id;
	}

	getFlightWB() {
		for (const flight of this.flightsWB) {
			if (flight.id == this.selectedFlightIdWB) {
				return flight;
			}
		}
		return null;
	}

	addName(value) {
		this.referanceBoardPass.name = value;
		this.updateBarcodeLine();
	}

	addTicket(value) {
		this.referanceBoardPass.ticketType = value;
		this.updateBarcodeLine();
	}

	addPNR(value) {
		this.referanceBoardPass.pnr = value;
		this.updateBarcodeLine();
	}

	addSeatNumber(value) {
		this.referanceBoardPass.seatNumber = value;
		this.updateBarcodeLine();
	}

	addSequenceNumber(value) {
		this.referanceBoardPass.sequenceNumber = value;
		this.updateBarcodeLine();
	}

	updateBarcodeLine() {
		this.barCodeText =
			this.referanceBoardPass.formatCode +
			this.referanceBoardPass.name +
			this.referanceBoardPass.ticketType +
			this.referanceBoardPass.pnr +
			this.referanceBoardPass.from +
			this.referanceBoardPass.to +
			this.referanceBoardPass.airline +
			this.referanceBoardPass.flight +
			this.referanceBoardPass.date +
			this.referanceBoardPass.compartmentCode +
			this.referanceBoardPass.seatNumber +
			this.referanceBoardPass.sequenceNumber +
			this.referanceBoardPass.pasangerStatus +
			this.referanceBoardPass.fieldSize;
	}

	loadPassanger(number) {
		this.passangerId = number;
		switch (number) {
			case 1:
				this.referanceBoardPass = new ReferanceBoardPass();
				this.referanceBoardPass.name = 'MAMLEEVA/LARISA';
				this.referanceBoardPass.pnr = 'ABC123';
				this.referanceBoardPass.from = this.flight.route[0].airportIata;
				this.referanceBoardPass.to = this.flight.route[1].airportIata;
				this.referanceBoardPass.airline = this.flight.airline;
				this.referanceBoardPass.flight = this.flight.flight;
				this.referanceBoardPass.date = this.flight.route[0].dtDeparture[0].toString();
				this.referanceBoardPass.seatNumber = '8E';
				this.referanceBoardPass.sequenceNumber = '52';
				this.updateBarcodeLine();
				break;
			case 2:
				this.referanceBoardPass = new ReferanceBoardPass();
				this.referanceBoardPass.name = 'GRIGORYEV/DANIL';
				this.referanceBoardPass.pnr = 'DFH884';
				this.referanceBoardPass.from = this.flight.route[0].airportIata;
				this.referanceBoardPass.to = this.flight.route[1].airportIata;
				this.referanceBoardPass.airline = this.flight.airline;
				this.referanceBoardPass.flight = this.flight.flight;
				this.referanceBoardPass.date = this.flight.route[0].dtDeparture[0].toString();
				this.referanceBoardPass.seatNumber = '3B';
				this.referanceBoardPass.sequenceNumber = '78';
				this.updateBarcodeLine();
				break;
			case 3:
				this.referanceBoardPass = new ReferanceBoardPass();
				this.referanceBoardPass.name = 'SINITSYN/IGOR';
				this.referanceBoardPass.pnr = 'JJJ909';
				this.referanceBoardPass.from = this.flight.route[0].airportIata;
				this.referanceBoardPass.to = this.flight.route[1].airportIata;
				this.referanceBoardPass.airline = this.flight.airline;
				this.referanceBoardPass.flight = this.flight.flight;
				this.referanceBoardPass.date = this.flight.route[0].dtDeparture[0].toString();
				this.referanceBoardPass.seatNumber = '10A';
				this.referanceBoardPass.sequenceNumber = '107';
				this.updateBarcodeLine();
				break;
			case 4:
				this.referanceBoardPass = new ReferanceBoardPass();
				this.referanceBoardPass.name = 'BOLGARIN/EVGENII';
				this.referanceBoardPass.pnr = 'JH745';
				this.referanceBoardPass.from = this.flight.route[0].airportIata;
				this.referanceBoardPass.to = this.flight.route[1].airportIata;
				this.referanceBoardPass.airline = this.flight.airline;
				this.referanceBoardPass.flight = this.flight.flight;
				this.referanceBoardPass.date = this.flight.route[0].dtDeparture[0].toString();
				this.referanceBoardPass.seatNumber = '12F';
				this.referanceBoardPass.sequenceNumber = '45';
				this.updateBarcodeLine();
				break;
			case 5:
				// Random passanger
				const randomPassanger = new RandomPassanger();
				this.referanceBoardPass = new ReferanceBoardPass();
				this.referanceBoardPass.name = randomPassanger.name;
				this.referanceBoardPass.pnr = randomPassanger.pnr;
				this.referanceBoardPass.from = this.flight.route[0].airportIata;
				this.referanceBoardPass.to = this.flight.route[1].airportIata;
				this.referanceBoardPass.airline = this.flight.airline;
				this.referanceBoardPass.flight = this.flight.flight;
				this.referanceBoardPass.date = this.flight.route[0].dtDeparture[0].toString();
				this.referanceBoardPass.seatNumber = randomPassanger.seatNumber;
				this.referanceBoardPass.sequenceNumber = randomPassanger.sequenceNumber;
				this.updateBarcodeLine();
				break;
			case 6:
				this.referanceBoardPass = new ReferanceBoardPass();
				this.referanceBoardPass.name = 'PNEV/KONSTANTIN';
				this.referanceBoardPass.pnr = 'FGW394';
				this.referanceBoardPass.from = this.flight.route[0].airportIata;
				this.referanceBoardPass.to = this.flight.route[1].airportIata;
				this.referanceBoardPass.airline = this.flight.airline;
				this.referanceBoardPass.flight = this.flight.flight;
				this.referanceBoardPass.date = this.flight.route[0].dtDeparture[0].toString();
				this.referanceBoardPass.seatNumber = 'INF';
				this.referanceBoardPass.sequenceNumber = '345';
				this.updateBarcodeLine();
				break;
			case 7:
				this.referanceBoardPass = new ReferanceBoardPass();
				this.referanceBoardPass.name = 'MAZHARTSEV/DENIS';
				this.referanceBoardPass.pnr = 'RPT489';
				this.referanceBoardPass.from = this.flight.route[0].airportIata;
				this.referanceBoardPass.to = this.flight.route[1].airportIata;
				this.referanceBoardPass.airline = this.flight.airline;
				this.referanceBoardPass.flight = this.flight.flight;
				this.referanceBoardPass.date = this.flight.route[0].dtDeparture[0].toString();
				this.referanceBoardPass.seatNumber = '';
				this.referanceBoardPass.sequenceNumber = '345';
				this.updateBarcodeLine();
				break;
			default:
				this.referanceBoardPass = new ReferanceBoardPass();
				this.referanceBoardPass.name = 'KRUGLOV/SERGEI';
				this.referanceBoardPass.pnr = 'HIO583';
				this.referanceBoardPass.from = this.flight.route[0].airportIata;
				this.referanceBoardPass.to = this.flight.route[1].airportIata;
				this.referanceBoardPass.airline = this.flight.airline;
				this.referanceBoardPass.flight = this.flight.flight;
				this.referanceBoardPass.date = this.flight.route[0].dtDeparture[0].toString();
				this.referanceBoardPass.seatNumber = '34D';
				this.referanceBoardPass.sequenceNumber = '103';
				this.updateBarcodeLine();
				break;
		}
	}

	get currentFlightPlanDateTime(): string {
		if (this.flight && this.flight.route.length > 0) {
			return this.flight.route[0].dtDeparture[0].toString();
		} else {
			return '';
		}
	}

	setDefaultTelegramText() {
		if (this.balance.typeTelegram === 'wgtmsg') {
			this.balance.textTelegram =
				`ZCZC
QU ALLDESK
.LCLHOST
WGTMSG 05062022095837 73144 ` +
				this.getFlightWB().airlineIata +
				this.getFlightWB().flightNumber +
				` ` +
				moment(this.getFlightWB().homeRoute.dtDepartureShow).format('DDMMMYYYY').toUpperCase() +
				` ` +
				this.getFlightWB().homeRoute.airportIata +
				` ` +
				this.getFlightWB().nextRoute.airportIata +
				` KGS MTOW=317514 MLDW=251290 FUEL=12000 BURN=6400 TXOF=200 TXIF=NIL STD=1235.`;
		} else if (this.balance.typeTelegram === 'uws') {
			this.balance.textTelegram =
				`UWS
` +
				this.getFlightWB().airlineIata +
				this.getFlightWB().flightNumber +
				`/` +
				moment(this.getFlightWB().homeRoute.dtDepartureShow).format('DD').toUpperCase() +
				`.` +
				this.getFlightWB().homeRoute.airportIata +
				`
-PMC51233SU/` +
				this.getFlightWB().nextRoute.airportIata +
				`/2105P/C.PER
-PLA00137SU/` +
				this.getFlightWB().nextRoute.airportIata +
				`/1116P/M
-PMC69060SU/` +
				this.getFlightWB().nextRoute.airportIata +
				`/1315P/C.PER
-PNA69227SU/` +
				this.getFlightWB().nextRoute.airportIata +
				`/1230P/C.PER
-PNA69052SU/` +
				this.getFlightWB().nextRoute.airportIata +
				`/1648P/C.PER
BULK
-` +
				this.getFlightWB().nextRoute.airportIata +
				`/168P/E
-` +
				this.getFlightWB().nextRoute.airportIata +
				`/90P/E`;
		} else if (this.balance.typeTelegram === 'pnl_adl') {
			this.balance.textTelegram =
				`=HEADER
RCV,2019/10/05 12:00
=DESTINATION TYPE B
STX,SVXOP7X
STX,SVXKP7X
=ORIGIN
LEDPNFV
=MSGID
051200
=SMI
PNL
=TEXT
` +
				this.getFlightWB().airlineIata +
				this.getFlightWB().flightNumber +
				`/` +
				moment(this.getFlightWB().homeRoute.dtDepartureShow).format('DDMMM').toUpperCase() +
				` ` +
				this.getFlightWB().homeRoute.airportIata +
				` PART1 \
CFG/C14
RBD C/C Y/Y
AVAIL
  ` +
				this.getFlightWB().homeRoute.airportIata +
				`  ` +
				this.getFlightWB().nextRoute.airportIata +
				`
C008
Y066
-` +
				this.getFlightWB().homeRoute.airportIata +
				`000C
-` +
				this.getFlightWB().homeRoute.airportIata +
				`054Y
1AZMOUN/SARDAR KHALIL MR-A30
.C/CHARTGRP
.L/221K0C/FV
.L/5CPTZ7/1H
.R/TKNE HK1 1952434825236/1
.R/DOCS HK1/P/IR/U96415139/IR/01JAN95/M/16JUL24/AZMOUN/SARDAR
.RN/ KHALIL
1BIRIUKOV/MIKHAIL IUREVICH MR-B24
.C/CHARTGRP
.L/221K0G/FV
.L/5CPTW7/1H
.R/TKNE HK1 1952434825264/1
.R/DOCS HK1/P/RU/4005601477/RU/07MAY58/M/01JAN30/BIRIUKOV
.RN//MIKHAIL IUREVICH
1BULYCHEV/VALERII VITALEVICH MR-A30
.R/TKNE HK1 1952434825246/1
.R/DOCS HK1/P/RU/4010088151/RU/05JUN65/M/01JAN30/BULYCHEV
.RN//VALERII VITALEVICH
1CARMINATI/IVAN MR-B24
.R/TKNE HK1 1952434825266/1
.R/DOCS HK1/P/IT/YA9225072/IT/13SEP54/M/07MAR26/CARMINATI/IVAN
1DE/OLIVEIRA WILLIAM ARTUR MR-B24
.R/TKNE HK1 1952434825271/1
.R/DOCS HK1/P/BR/YB687025/BR/20OCT82/M/30AUG20/DE/OLIVEIRA
.RN/ WILLIAM ARTUR
ENDPNL`;
		}
	}

	getById(array, id) {
		if (array && id) {
			return array.find(el => el.id === id) || null;
		}
	}

	async loadAhms() {
		const data = await this.restApiWBAdmin.getAhms(null);
		this.ahms = [];
		data.forEach(el => {
			const ahm = new Ahm();
			Object.assign(ahm, el);
			this.ahms.push(ahm);
		});
		this.ahms.sort((a, b) => (a.airlineName > b.airlineName ? 1 : -1));
		this._ahmsData = [];
		this.ahm = new Ahm();
	}

	async selectAhm(id) {
		this.selectedAhmDataId = null;
		const data = await this.restApiWBAdmin.getAhm(id);
		this.ahm = new Ahm();
		Object.assign(this.ahm, data);
		this.loadAhmsData(this.ahm.id);
		this.selectedAirlineData = this.getById(this.references.airlines, this.ahm.airlineId);
		this.ahmsDataSelect = false;
	}

	async loadAhmsData(ahmId) {
		const data = await this.restApiWBAdmin.getAhmsData(ahmId, false);
		this._ahmsData = [];
		data.forEach(el => {
			const ahm = new AhmData();
			Object.assign(ahm, el);
			ahm['selected'] = false;
			this._ahmsData.push(ahm);
		});
		this._ahmsData.sort((a, b) => (a.tailName > b.tailName ? 1 : -1));
	}

	get ahmsData() {
		return this._ahmsData;
	}

	async uploadAhmData() {
		const ahmData = this.ahmDataText.length > 0 ? JSON.parse(this.ahmDataText) : {};
		ahmData.ahmId = this.ahm.id;

		this.weightAndBalanceResult = '';
		const updateTails = this.selectedTailsAhmRowId;
		if (updateTails.length > 0 && ahmData && Object.keys(ahmData).length !== 0) {
			updateTails.forEach(tailId => {
				ahmData.id = tailId;
				return this.restApiWBAdmin.updateAhmDataFromText(tailId, JSON.stringify(ahmData)).subscribe((data: {}) => {
					this.weightAndBalanceResult += this.weightAndBalanceResult.length == 0 ? tailId + ' ✓' : ' ' + tailId + ' ✓';
				});
			});
			this.ahmDataText = '';
		} else {
			if (updateTails.length == 0) {
				this.weightAndBalanceResult = 'не выбраны Бортовые номера';
			}
			if (!ahmData && Object.keys(ahmData).length === 0) {
				this.weightAndBalanceResult = 'не встравлен JSON для обновления';
			}
		}
		this.loadAhmsData(this.ahm.id);
	}

	async uploadAhmDataCompartmentsCrews() {
		const ahmData = this.ahmDataTextCompartmentsCrews.length > 0 ? JSON.parse(this.ahmDataTextCompartmentsCrews) : {};
		const updateTails = this.selectedTailsAhmRowId;
		this.weightAndBalanceResult = '';
		if (updateTails.length > 0) {
			for await (const tailId of updateTails) {
				await this.loadAhmData(tailId);
				await this.updateCompartments(ahmData.compartments);
				await this.updateSeats(ahmData.seats);
				await this.updateCrews(ahmData.crew);
				await this.saveAhmDataWithoutChangeCompatrmant();
			}
		} else {
			if (updateTails.length == 0) {
				this.weightAndBalanceResult = 'не выбраны Бортовые номера';
			}
			if (!ahmData && Object.keys(ahmData).length === 0) {
				this.weightAndBalanceResult = 'не встравлен JSON для обновления';
			}
		}
	}

	updateSeats(seats) {
		this.ahmData.seats = seats;
	}

	updateCompartments(compartment) {
		this.ahmData.holdsAndCompartments = compartment;
	}

	updateCrews(crews) {
		crews.forEach((element, index) => {
			if (this.ahmData.dowChanges.crew[index]) {
				// const dow = this.ahmData.dowChanges.crew[index].dow;
				// const doi = this.ahmData.dowChanges.crew[index].doi;
				element.dow = this.ahmData.dowChanges.crew[index].dow;
				element.doi = this.ahmData.dowChanges.crew[index].doi;
				this.ahmData.dowChanges.crew[index] = element;
			} else {
				// Нет компановки экипажа, предупредить
				this.weightAndBalanceResult +=
					'\r\n' + this.ahmData.tailName + ' ' + element.name + ' НЕТ КОМПАНОВКИ В ТЕКУЩЕМ АХМ';
			}
		});
	}

	/**
	 * Функция получения идентификаторов записей в базе АХМ
	 */
	get selectedTailsAhmRowId(): Array<number> {
		const selectedTailId = [];
		this.ahmsData.forEach(item => {
			if (item['selected']) {
				selectedTailId.push(item.id);
			}
		});
		return selectedTailId;
	}

	/**
	 * Функция получения Идентификаторов Бортовых номеров из справочников Бортовых
	 */
	get selectedTailsId(): Array<number> {
		const selectedTailId = [];
		this.ahmsData.forEach(item => {
			if (item['selected']) {
				selectedTailId.push(item.tailId);
			}
		});
		return selectedTailId;
	}

	selectTails() {
		this.ahmsDataSelect = !this.ahmsDataSelect;

		this.ahmsData.forEach(item => {
			item['selected'] = this.ahmsDataSelect;
		});
	}

	async calculateGeometry() {
		const updateTails = this.selectedTailsAhmRowId;
		this.weightAndBalanceResult = '';
		if (updateTails.length > 0) {
			for await (const tailId of updateTails) {
				await this.loadAhmData(tailId);
				await this.calculateBulkPositions();
				await this.saveAhmData();
			}
		} else {
			if (updateTails.length == 0) {
				this.weightAndBalanceResult = 'не выбраны Бортовые номера';
			}
		}
	}

	async saveAhmData() {
		const holdsAndCompartments = await this.createHoldsAndCompartments();
		await this.restApiWBAdmin.updateAhmData(this.ahmData.id, this.ahmData, holdsAndCompartments).then(data => {
			this.weightAndBalanceResult += '\r\n' + this.ahmData.id + ' ✓';
		});
	}

	async saveAhmDataWithoutChangeCompatrmant() {
		await this.restApiWBAdmin
			.updateAhmData(this.ahmData.id, this.ahmData, this.ahmData.holdsAndCompartments)
			.then(data => {
				this.weightAndBalanceResult += '\r\n' + this.ahmData.id + ' ✓';
			});
	}

	async createHoldsAndCompartments() {
		const holdsAndCompartments = {
			fwd: [],
			aft: [],
			limits: [],
			interceptions: [],
			map: {},
		};

		for (const key in this.ahmData.holdsAndCompartments) {
			if (
				Object.prototype.hasOwnProperty.call(this.ahmData.holdsAndCompartments, key) &&
				this.ahmData.holdsAndCompartments[key].length > 0 &&
				(key === 'fwd' || key === 'aft')
			) {
				holdsAndCompartments[key] = this.conversionCompartments(this.ahmData.holdsAndCompartments[key]);
			} else {
				holdsAndCompartments[key] = this.ahmData.holdsAndCompartments[key];
			}
		}

		holdsAndCompartments.interceptions = await this.createInterceptions(holdsAndCompartments);

		return holdsAndCompartments;
	}

	conversionCompartments(holdsAndCompartments) {
		const element = [];
		holdsAndCompartments.sort((a, b) => (a.name > b.name ? 1 : -1));
		let group = holdsAndCompartments[0].group;
		let index = 0;

		holdsAndCompartments.forEach(hold => {
			hold.name = hold.name.replace(/<\/?[^>]+(>|$)/g, '');
			const el = new HoldsAndCompartments();
			Object.assign(el, hold);

			if (group !== hold.group) {
				index++;
				group = hold.group;
			}

			if (group === hold.name) {
				element.push(el);
			} else {
				if (!element[index]) {
					const compartment = new HoldsAndCompartments();
					compartment.name = group;
					element.push(compartment);
				}
				element[index].bays.push(el);
			}
		});
		return element;
	}

	async createInterceptions(holdsAndCompartments) {
		const bayMap = document.createElement('div');
		bayMap.style.width = '1000px';

		const result = [];

		this.trunkMap.trunkMap.forEach(el => {
			el.bays.forEach(cmp1 => {
				el.bays.forEach(cmp2 => {
					const pos1 = cmp1.name.slice(-1).toUpperCase();
					const pos2 = cmp2.name.slice(-1).toUpperCase();
					if (!((pos1 === 'R' && pos2 === 'L') || (pos1 === 'L' && pos2 === 'R'))) {
						if (
							cmp1.name !== cmp2.name &&
							((cmp1.styles.right <= cmp2.styles.right && cmp1.styles.right + cmp1.styles.width > cmp2.styles.right) ||
								(cmp2.styles.right <= cmp1.styles.right && cmp2.styles.right + cmp2.styles.width > cmp1.styles.right))
						) {
							result.push({ cmp1: cmp1.name, cmp2: cmp2.name });
						}
					}
				});
			});
		});

		return result;
	}

	/**
	 * Функция загрузка данных по конкретному бортовому номеру из AHM
	 * @param id Идентификатор загружаемых данных AHM
	 */
	async loadAhmData(id) {
		await this.restApiWBAdmin.getAhmData(id).then(data => {
			this.ahmData = new AhmData();
			Object.assign(this.ahmData, data);
			this.ahmData.holdsAndCompartments = new AhmDataHoldsAndCompartments();

			for (const key in data.holdsAndCompartments) {
				if (Object.prototype.hasOwnProperty.call(data.holdsAndCompartments, key) && (key === 'fwd' || key === 'aft')) {
					const element = data.holdsAndCompartments[key];
					if (element && element.length > 0) {
						element.forEach(hold => {
							if (hold.bays.length > 0) {
								hold.bays.forEach(bay => {
									const el = new HoldsAndCompartments();
									Object.assign(el, bay);
									el.group = hold.name[0];
									this.ahmData.holdsAndCompartments[key].push(el);
								});
							}
							const el = new HoldsAndCompartments();
							Object.assign(el, hold);
							if (el.maxWeight !== 0 && el.index !== 0) {
								el.group = hold.name[0];
								el.bays = [];
								this.ahmData.holdsAndCompartments[key].push(el);
							}
						});
						this.ahmData.holdsAndCompartments[key].sort((a, b) => (a.name > b.name ? 1 : -1));
					}
				} else if (key === 'map' && !data.holdsAndCompartments[key]) {
					this.ahmData.holdsAndCompartments[key] = new BayMap();
				} else if (!data.holdsAndCompartments[key]) {
					this.ahmData.holdsAndCompartments[key] = [];
				} else {
					this.ahmData.holdsAndCompartments[key] = data.holdsAndCompartments[key];
				}
			}

			if (this.ahmData.configurations.length > 0) {
				this.ahmData.configurations = [];
				data.configurations.forEach(el => {
					const config = new AhmConfiguration();
					Object.assign(config, el);
					config.sections = [];
					el.sections.forEach(section => {
						const res = new ConfigurationSection();
						Object.assign(res, section);
						config.sections.push(res);
					});
					this.ahmData.configurations.push(config);
				});
			}
		});
	}

	calculateCenterForBay(element, ignoreFlag?) {
		if (ignoreFlag || (element.index !== 0 && element.index)) {
			element.centroid = this.toNumber((element.index * this.ahmData.c + this.ahmData.referenceArm).toFixed(2));
		}
	}

	async calculateBulkPositions() {
		await this.createBulkPositions(this.ahmData.holdsAndCompartments.fwd);
		await this.createBulkPositions(this.ahmData.holdsAndCompartments.aft);
		await this.fixAircrafttSize();
	}

	createBulkPositions(hold: Array<HoldsAndCompartments>) {
		if (hold && hold.length > 0) {
			let previousHold: HoldsAndCompartments = null;
			// Массив багажников/отсеков для которых необходимо расчитать геометрию
			const bayMap: Array<{
				hold;
				name: string;
				index;
				center;
				from;
				to;
				width: number;
			}> = [];
			// Имя текущего обрабатываего Багажника
			let holdName = null;
			// Список багажников обрабатывается в плоскую структуру при загруке данных
			// принадлежность к Багажнику хранится в параметры Group
			hold.forEach((item, index) => {
				// Пересчет центра нахождения контейнера по индексу, что бы старые дынные
				// не испортили расчеты
				this.calculateCenterForBay(item, true);
				// Сброс текущей геометрии отсека
				item.from = 0;
				item.to = 0;
				item.type = 'uld';
				// Запись текущего имени обрабатываемого Багажника
				if (holdName !== item.group) {
					holdName = item.group;

					// При смене группы, если есть запись о Багажнике, нужно ее сохранить
					// для дальнейшего расчета геометрии.
					// Если запись о Багажнике NULL, значит он описывает только ограничения
					// по весу и объему для группы, рисовать его ну нужно.
					if (previousHold) {
						bayMap.push({
							hold: previousHold.group,
							name: previousHold.name,
							index: previousHold.index,
							center: previousHold.centroid,
							from: 0,
							to: 0,
							width: 0,
						});
						previousHold = null;
					}
				}

				// Если текущий элемент является Багажников, запомнить его параметры
				if (item.name === holdName) {
					previousHold = item;
				} else {
					// Текущий элемент это отсек, добавим в массив расчета геометрии
					bayMap.push({
						hold: item.group,
						name: item.name,
						index: item.index,
						center: item.centroid,
						from: 0,
						to: 0,
						width: 0,
					});
					// Если внутри Багажника есть Отсеки для отрисовки, сам багажник
					// рисовать не нужно, удаляем данные о нем
					if (item.group === holdName) {
						previousHold = null;
					}
				}

				// Последений элемент може быть Багажником без Отсеков, логи вначале
				// не обработает его, так как нужна смена группы
				if (index === hold.length - 1) {
					if (previousHold) {
						bayMap.push({
							hold: previousHold.group,
							name: previousHold.name,
							index: previousHold.index,
							center: previousHold.centroid,
							from: 0,
							to: 0,
							width: 0,
						});
					}
				}
			});

			// Посчить длинны между центрами, что бы оперировать примерными размерами
			// отсеков
			// Для единственного отсека поставим фиксированную длинну
			if (bayMap.length === 1) {
				bayMap[0].from = bayMap[0].center - 1.5;
				bayMap[0].to = bayMap[0].center + 1.5;
			} else {
				bayMap.forEach((item, index, arr) => {
					if (index === arr.length - 1) {
						item.width = arr[index - 1].width;
					} else {
						item.width = +Number(arr[index + 1].center - item.center).toFixed(2);
					}
				});
			}

			// Сортировка отсеков по возрастанию длинны, расчет геометрии происходит
			// от меньших к большим для сохранения пропорций
			let sortMap = [...bayMap];
			sortMap = sortMap.sort((e1, e2) => e1.width - e2.width);
			sortMap.forEach(item => {
				const index = bayMap.findIndex(bay => bay.name === item.name);
				if (index < bayMap.length - 1) {
					this.calculateFromToPosition(bayMap[index], bayMap[index + 1]);
				} else {
					this.calculateFromToPosition(bayMap[index]);
				}
			});

			// Нормализация значений, что бы не было пустого места между отсеками,
			// проходим по массиву и стыкуем разрывы
			bayMap.forEach((item, index) => {
				if (index < bayMap.length - 1) {
					// Если конец позиции не совпадет с началом следующий,
					// то мы имеем разрыв в отрисовке.
					// Устраняется методом выставления единой точки соединения позиций.
					if (item.to !== bayMap[index + 1].from) {
						bayMap[index + 1].from = item.to;
					}
				}
			});

			// Вычесленные позиции записываем в исходный массив, отмечаем как Навалом
			hold.forEach(item => {
				const bay = bayMap.find(bay => bay.name === item.name);
				if (bay != undefined) {
					item.from = bay.from;
					item.to = bay.to;
					item.type = 'bulk';
				}
			});
		}
	}

	calculateFromToPosition(bayCurrent, bayNext?: { hold; name: string; index; center; from; to; width: number }) {
		if (bayNext) {
			// Найдем расстояние между двумя центрами
			const widthBetweenCenters = bayNext.center - bayCurrent.center;
			// От центра текущего контейнера в лево и право надо отложить найденное расстояние
			if (bayCurrent.from === 0) {
				bayCurrent.from = +Number(bayCurrent.center - widthBetweenCenters / 2).toFixed(2);
			}
			if (bayCurrent.to === 0) {
				bayCurrent.to = +Number(bayCurrent.center + widthBetweenCenters / 2).toFixed(2);
			}
			// Так же укажем расстояние в лево для следующей позиции,
			// правая длинна должна считаться в следующей паре
			if (bayNext.from === 0) {
				bayNext.from = +Number(bayNext.center - widthBetweenCenters / 2).toFixed(2);
			}
		} else {
			if (bayCurrent.from === 0) {
				bayCurrent.from = +Number(bayCurrent.center - bayCurrent.width / 2).toFixed(2);
			}
			if (bayCurrent.to === 0) {
				bayCurrent.to = +Number(bayCurrent.center + bayCurrent.width / 2).toFixed(2);
			}
		}
	}

	fixAircrafttSize() {
		let fwdFrom,
			fwdTo,
			aftFrom,
			aftTo = 0;

		// Поиск первой позиции в переднем Багажнике
		if (this.ahmData.holdsAndCompartments.fwd && this.ahmData.holdsAndCompartments.fwd.length > 0) {
			for (const item of this.ahmData.holdsAndCompartments.fwd) {
				if (item.from > 0) {
					fwdFrom = item.from;
					break;
				}
			}

			// Поиск последней позиции в переднем Багажнике
			const reverseFwd = [...this.ahmData.holdsAndCompartments.fwd];
			reverseFwd.reverse();
			for (const item of reverseFwd) {
				if (item.to > 0) {
					fwdTo = item.to;
					break;
				}
			}
		}

		// Поиск первой позиции в заднем Багажнике
		if (this.ahmData.holdsAndCompartments.aft && this.ahmData.holdsAndCompartments.aft.length > 0) {
			for (const item of this.ahmData.holdsAndCompartments.aft) {
				if (item.from > 0) {
					aftFrom = item.from;
					break;
				}
			}

			// Поиск последеней позиции в заднем Багажнике
			const reverseAft = [...this.ahmData.holdsAndCompartments.aft];
			reverseAft.reverse();
			for (const item of reverseAft) {
				if (item.to > 0) {
					aftTo = item.to;
					break;
				}
			}
		}

		// Удаление старых записей о размерах ВС
		this.ahmData.holdsAndCompartments.acSize = [];

		// Добавление новых размеров для передней части
		if (fwdFrom !== 0 && fwdFrom !== undefined && fwdTo !== 0 && fwdTo !== undefined) {
			this.ahmData.holdsAndCompartments.acSize.push({
				name: 'fwd',
				start: fwdFrom,
				finish: fwdTo,
			});
		}

		// Добавление новых размеров для задней части
		if (aftFrom !== 0 && aftFrom !== undefined && aftTo !== 0 && aftTo !== undefined) {
			this.ahmData.holdsAndCompartments.acSize.push({
				name: 'aft',
				start: aftFrom,
				finish: aftTo,
			});
		}
	}

	/**
	 * Функция обработки значения из редактируемой таблицы
	 * @param {string} value Строка, введеная пользователем
	 */
	toNumber(value) {
		if (value === '') {
			return null;
		}
		const num = parseFloat(
			value
				.replace(/<\/?[^>]+(>|$)/g, '')
				.replace(/^(-)|[^0-9.,]+/gi, '$1')
				.replace(/^-+/g, '-')
				.replace(',', '.')
		);
		return num ?? null;
	}

	async loadTail(id: number) {
		return await this.restApiReference.getTail(id).then((data: ReferanceTail) => {
			const tail = new ReferanceTail();
			Object.assign(tail, data);
			this.tail = tail;
		});
	}

	updateTail(id) {
		this.restApiReference.updateTail(id, this.tail);
	}

	async resaveTails() {
		const updateTails = this.selectedTailsId;
		this.weightAndBalanceResult = '';
		for await (const tail of updateTails) {
			await this.loadTail(tail);
			await this.updateTail(tail);
			this.weightAndBalanceResult += this.weightAndBalanceResult.length == 0 ? tail + ' ✓' : ' ' + tail + ' ✓';
		}
	}

	async loadAirports() {
		return await this.restApiReference.getAirports().then((data: ReferanceAirport[]) => {
			this.privateAirports.length = 0;
			for (const item of data) {
				const airport = new ReferanceAirport();
				Object.assign(airport, item);
				this.privateAirports.push(airport);
			}
		});
	}

	async loadAirlines() {
		return await firstValueFrom(this.restApiReference.getAirlines()).then(data => {
			this.privateAirlines.length = 0;
			for (const item of data) {
				const airline = new ReferanceAirline();
				Object.assign(airline, item);
				this.privateAirlines.push(airline);
			}
		});
	}

	async loadAirport(id: number) {
		return await this.restApiReference.getAirport(id).then((data: ReferanceAirport) => {
			const airport = new ReferanceAirport();
			Object.assign(airport, data);
			this.airport = airport;
		});
	}

	async loadAirline(id: number) {
		return await firstValueFrom(this.restApiReference.getAirline(id)).then(data => {
			const airline = new ReferanceAirline();
			Object.assign(airline, data);
			if (airline.codeEnglish == '' || airline.codeEnglish == null) {
				airline.codeEnglish = airline.iata;
			}
			if (airline.codeLocal == '' || airline.codeLocal == null) {
				airline.codeLocal = airline.icao;
			}
			this.airline = airline;
		});
	}

	updateAirport(id) {
		this.restApiReference.updateAirport(id, this.airport);
	}

	async updateAirline(id) {
		await firstValueFrom(this.restApiReference.updateAirline(id, this.airline));
	}

	async resaveAirports() {
		if (window.confirm('Пересохранить АЭРОПОРТЫ, уверен?')) {
			await this.loadAirports();
			this.referenceAndBalanceResult = '';
			let index = 0;
			const length = this.privateAirports.length;
			for await (const airport of this.privateAirports) {
				index++;
				await this.loadAirport(airport.id);
				await this.updateAirport(airport.id);
				this.referenceAndBalanceResult = 'обновлена запись ' + index.toString() + ' из ' + length;
			}
		}
	}

	async resaveAirline() {
		if (window.confirm('Пересохранить АВИАКОМПАНИИ, уверен?')) {
			await this.loadAirlines();
			this.referenceAndBalanceResult = '';
			let index = 0;
			const length = this.privateAirlines.length;
			for await (const airline of this.privateAirlines) {
				index++;
				await this.loadAirline(airline.id);
				await this.updateAirline(airline.id);
				this.referenceAndBalanceResult = 'обновлена запись ' + index.toString() + ' из ' + length;
			}
		}
	}

	async exportAhm() {
		const exportData = [];

		for await (const ahm of this.ahms) {
			if (ahm['selected']) {
				const ahmObject = ahm;
				ahmObject['tails'] = [];
				const data = await this.restApiWBAdmin.getAhmsData(ahm.id, false);
				data.forEach(el => {
					const ahmData = new AhmData();
					Object.assign(ahmData, el);
					ahmObject['tails'].push(ahmData);
				});
				exportData.push(ahmObject);
			}
		}
		const exportDataBlob = new Blob([JSON.stringify(exportData, null, 2)], {
			type: 'application/json',
		});

		const regexpSubdomain = /([a-z]{0,}:\/\/)([a-z0-9\-]{0,}).*/;
		const match = this.settingsService.general.keycloakConfig$.getValue().issuer.match(regexpSubdomain);
		this.fileSaverService.save(exportDataBlob, match[2] + '-' + moment().format('DDMMYYYY-HHmmss') + '.txt');
	}

	importAhm(files: File[]) {
		if (files[0]) {
			const reader = new FileReader();
			reader.readAsText(files[0]);
			reader.onload = async () => {
				const object = JSON.parse(reader.result.toString());
				// Загрузка списка AHM
				await this.loadAhms();
				for await (const importAhm of object) {
					// Найти AHM: по ID авиакомпании и ID типу ВС и номеру ревизии,
					// так как уникальные номера самих AHM в базах могут не совпадать
					for await (const currentAhm of this.ahms) {
						if (
							currentAhm.aircraftTypeId === importAhm.aircraftTypeId &&
							currentAhm.airlineId === importAhm.airlineId &&
							currentAhm.revision === importAhm.revision
						) {
							// Загрузка Бортовых по найденному AHM
							await this.loadAhmsData(currentAhm.id);
							// Перебор Бортовых из файла, поиск соответствий по
							// текущему списку Бортовых, сопоставляем по tailID,
							// так как идентификаторы на разных базах могут отличаться
							for await (const importTail of importAhm.tails) {
								const currentTail = this.ahmsData.find(el => el.tailId === importTail.tailId);
								if (currentTail !== undefined) {
									// Замена idAhm в импортируемой записи Бортового
									// на idAhm используемого в принимающей стороне
									importTail.id = currentTail.id;
									importTail.ahmId = currentTail.ahmId;
									await this.restApiWBAdmin
										.updateAhmDataFromText(importTail.id, JSON.stringify(importTail))
										.subscribe((data: {}) => {
											this.weightAndBalanceResult +=
												'\r\n' +
												importAhm.airlineName +
												' ' +
												importAhm.aircraftTypeName +
												' / ' +
												importTail.tailName +
												' ✓';
										});
								} else {
									this.weightAndBalanceResult +=
										'\r\n' +
										importAhm.airlineName +
										' ' +
										importAhm.aircraftTypeName +
										' / ' +
										importTail.tailName +
										' <<< НЕТ БОРТА В ТЕКУЩЕЙ БАЗЕ';
								}
							}
						}
					}
				}
			};
			reader.onerror = () => {
				console.log('Error is occured while reading file');
			};
		}
	}

	handleError(error) {
		let errorMessage = '';
		if (error.error instanceof ErrorEvent) {
			// Get client-side error
			errorMessage = error.error.message;
		} else {
			// Get server-side error
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		}
		window.alert(errorMessage);
		return throwError(errorMessage);
	}

	statementSearchStatus: StatementStatus = new StatementStatus();
	statementSearchResult: boolean | null = null;

	createStatementStatusForm() {
		this.statementStatusForm.reset();
		this.statementSearchResult = null;
		this.statementSearchStatus = {
			baggages: [],
			statementDt: null,
			statementName: '',
		};
	}

	searchStatementStatus() {
		this.restApiLF.getStatementStatus(this.statementStatusForm.value).subscribe({
			next: res => {
				this.statementSearchStatus = new StatementStatus(res, this.settingsService.language);
				this.statementSearchResult = true;
			},
			error: err => {
				this.statementSearchResult = false;
				this.statementSearchStatus = new StatementStatus();
			},
		});
	}

	getStatusName(id: number) {
		const names = this.references.baggage_statuses.data.find(el => el.id === id)?.name;
		if (names) {
			return this.settingsService.language == 'RU' ? names[1] : names[0];
		}
		return null;
	}
}
