// Типы фильтра
export class FilterTypes {
    id: number;
    name: Array<string>;
    lastupdate: string;

    get nameEnglish(): string {
      if ((this.name) && (this.name.length >= 1)) {
        return this.name[0];
      } else {
        return null;
      }
    }

    set nameEnglish(value: string) {
      this.name[0] = value;
    }
}

// Операции фильтра
export class FilterOperations {
    id: number;
    name: string;
    lastupdate: string;
}

// Поля фильтра
export class FilterFileds {
    id: number;
    name: string;
    operations: Array<number>;
    fieldTypeId: number;
    fieldType: string;
    enums: any;
    lastupdate: string;
}

// Типы полей фильтра
export class FieldTypes {
    id: number;
    name: string;
    lastupdate: string;
}

// Типы сообщений
export class MessageTypes {
    id: number;
    name: Array<string>;
    lastupdate: string;

    get nameEnglish(): string {
      if ((this.name) && (this.name.length >= 1)) {
        return this.name[0];
      } else {
        return null;
      }
    }

    set nameEnglish(value: string) {
      this.name[0] = value;
    }
}

// Папки
export class Folder {
    constructor() {
      this.name = '';
      this.dtRange = [new Date(), new Date('2099-01-01')];
      this.archiveRules = [];
      this.forwardingRules = [];
      this.channelId = null;
      this.senderId = null;
      this.messageTypeId = null;
      this.maxAge = 0;
      this.defaultAftnLang = 1;
      this.defaultAftnUrgencyId = null;
      this.autoReplySS = false;
    }

    id: number;
    name: string;
    time: number;
    channelId: number;
    channelName: string;
    senderId: number;
    senderName: string;
    aftnSenderId: number;
    aftnSenderName: string;
    emailSenderId: number;
    emailSenderName: string;
    sitatexSenderId: number;
    sitatexSenderName: string;
    messageTypeId: number;
    messageTypeName: string;
    archiveRules: Array<ArchiveRules>;
    forwardingRules: Array<ForwardingRule>;
    maxAge: number;
    dtRange: Array<Date>;
    lastupdate: Date;
    defaultAftnLang: number;
    defaultAftnUrgencyId: number;
    autoReplySS: boolean;

    get dtRangeStart(): Date {
        if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0]!==null) {
        return new Date(this.dtRange[0]);
        } else {
        return null;
        }
    }

    set dtRangeStart(value: Date) {
        this.dtRange[0] = value;
    }

    get dtRangeFinish(): Date {
        if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1]!==null) {
        return new Date(this.dtRange[1]);
        } else {
        return null;
        }
    }

    set dtRangeFinish(value: Date) {
        this.dtRange[1] = value;
    }

    get allSenders(): string {
      let array = [this.aftnSenderName, this.sitatexSenderName, this.emailSenderName];
      array = array.filter(el => el !== '');
      return array.join(', ');
    }
}

export class ForwardingRule {
  constructor() {
    this.channelId = 0;
    this.channelName = '';
    this.senderId = null;
    this.senderName = '';
    this.recipient = '';
    this.recipientList = [];
    this.withHeaders = false;
  }

  channelId: number;
  channelName: string;
  senderId: number;
  senderName: string;
  recipient: string;
  recipientList: Array<any>;
  withHeaders: boolean
  error: string;
}

export class ArchiveRules {
  constructor() {
    this.messageTypeIds = [];
    this.messageTypeNames = [];
    this.time = null;
  }

  id: number;
  messageTypeIds: Array<number>;
  messageTypeNames: Array<string>;
  time: number;
}

// Каналы
export class Channels {
    id: number;
    name: string;
    lastupdate: string;
}

// Аэропорты
export class Airport {
    constructor() {
      this.iata = '';
      this.icao = '';
      this.name = [null, null];
      this.tzOffset = [null, null];
      this.dtRange = [null, null];
      this.lastupdate = undefined;
    }

    id: number;
    iata: string;
    icao: string;
    private name: Array<string>;
    private tzOffset: Array<number>;
    private dtRange: Array<Date>;
    lastupdate: string;

    /**
     * Функции чтения/записи времени актуальности записи
     */

    get dtRangeStart(): Date {
      if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0]!==null) {
        return new Date(this.dtRange[0]);
      } else {
        return null;
      }
    }

    set dtRangeStart(value: Date) {
      this.dtRange[0] = new Date(value);
    }

    get dtRangeFinish(): Date {
      if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1]!==null) {
        return new Date(this.dtRange[1]);
      } else {
        return null;
      }
    }

    set dtRangeFinish(value: Date) {
      this.dtRange[1] = new Date(value);
    }

    /**
     * Функции чтения/записи смещения времени
     * относительно UTC в зимний и летний период
     */

    get tzOffsetWinter(): number {
      if ((this.tzOffset) && this.tzOffset.length >= 1) {
        return this.tzOffset[0];
      }
    }

    set tzOffsetWinter(value: number) {
      this.tzOffset[0] = value;
    }

    get tzOffsetSummer(): number {
      if ((this.tzOffset) && this.tzOffset.length === 2) {
        return this.tzOffset[1];
      }
    }

    set tzOffsetSummer(value: number) {
      this.tzOffset[1] = value;
    }

    /**
     * Функции чтения/записи наименований аэропорта
     * на английском и локальном языке
     */

    get nameEnglish(): string {
      if ((this.name) && (this.name.length >= 1)) {
        return this.name[0];
      } else {
        return null;
      }
    }

    set nameEnglish(value: string) {
      this.name[0] = value;
    }

    get nameLocal(): string {
      if ((this.name) && (this.name.length === 2)) {
        return this.name[1];
      } else {
        return null;
      }
    }

    set nameLocal(value: string) {
      this.name[1] = value;
    }

    /**
     * Функция вывода наименований аэропорта в строку
     */
    get names(): string {
      if ((this.name) && (this.name.length > 0)) {
        return this.name.join(',  ');
      } else {
        return null;
      }
    }
  }