import { Component, OnInit, ViewChild } from '@angular/core';
import { SlotCoordinationModuleRestApiService } from '../slot-coordination-module-data/slot-coordination-module-rest-api.service';
import { Slot, SlotRoutes, SlotRoute, SlotPeriod } from '../slot-coordination-module-data/slot';
import {
  ReferanceAirport,
  ReferanceAirline,
  ReferanceMovement,
  ReferanceAircraft,
  ReferanceGeoTypes,
  ReferanceSeasons,
  ReferanceScheduleTypes,
} from '../slot-coordination-module-data/referance';
import { GlobalI18n } from '@settings/global-i18n';
import { Module } from "@shared/models/module-name";
import { SettingsService } from '@core/services/settings/settings.service';

@Component({
  selector: 'app-slot-coordination-module',
  templateUrl: './slot-coordination-module.component.html',
             styleUrls: ['./slot-coordination-module.component.less']
           })
export class SlotCoordinationModuleComponent implements OnInit {

  constructor(
    public restApi: SlotCoordinationModuleRestApiService,
    private globalI18n: GlobalI18n,
		private settingsService: SettingsService
  ) {}

  slots: Slot[];
  slot: Slot = new Slot;
  referanceMovements: ReferanceMovement[];
  referanceAirport: ReferanceAirport[];
  referanceAirline: ReferanceAirline[];
  referanceAircraftTypes: ReferanceAircraft[];
  referanceGeoTypes: ReferanceGeoTypes[];
  referanceSeasons: ReferanceSeasons[];
  referanceScheduleTypes: ReferanceScheduleTypes[]
  errorMessage = '';
  errorType = '';
  activeState = 'state-append';
  // Для загрузки соответствующей вкладки
  activeTabRoute = 0;
  // Массив для удаления точек маршрута
  deleteQueueRoute = new Set();
  // Массив для удаления периодов
  deleteQueuePeriod = new Set();

  paramSlotsSeason: number;
  paramSlotsSchedule: number;

  // Блок переменных для работы ng-select списков
  // Размер отображаемых данных в выпадающем списке
  referanceBufferSize = 50;
  // Количество элементов до конца списка, перед загрузкой новой порции
  numberOfItemsFromEndBeforeFetchingMore = 10;

  // Статус загрузки/обновления справочника для отображения анимации
  // в компоненте ng-select для Аэропортов
  referanceAirportsLoading = false;
  // Подгружаемый список для вывода в выпадающий список
  referanceAirportsBuffer = [];

  // Статус загрузки/обновления справочника для отображения анимации
  // в компоненте ng-select для Авиалиний
  referanceAirlinesLoading = false;
  // Подгружаемый список для вывода в выпадающий список
  referanceAirlinesBuffer = [];

  // Загрузка
  loading = false;

  @ViewChild('slotModalForm') slotModalForm: any;
  @ViewChild('slotForm') slotForm: any;

  async ngOnInit() {
    Promise.all([
      this.loadSeasons(),
      this.loadScheduleTypes(),
    ]).then(() => {
      this.paramSlotsSeason = this.referanceSeasons[0].id;
      this.paramSlotsSchedule = 1;
      this.loadSlots(this.paramSlotsSeason, this.paramSlotsSchedule);
    });

    this.loadMovements();
    this.loadAirports();
    this.loadAircrafts();
    this.loadAirlines();
    this.loadGeoTypes();
  }

  loadSlots(season: number, schedule: number) {
        return this.restApi.getSlots(season, schedule).subscribe((data: Slot[]) => {
      this.slots = data;
    });
  }

  loadSlot(id: number, tab: number) {
    this.slotForm.form.markAsUntouched();
    return this.restApi.getSlot(id).subscribe((data: Slot) => {
      this.slot = data;
      this.activeTabRoute = tab;
      this.activeState = 'state-update';
    });
  }

  createSlot() {
    this.slot = new Slot();
    this.slotForm.form.markAsUntouched();
    this.activeState = 'state-append';
    this.activeTabRoute = 0;
  }

  addSlot() {
    this.errorMessage = '';
    const errors = [];
    const startSeason = new Date(this.referanceSeasons.filter( el => el.id == this.slot.seasonID)[0].range[0]);
    const finishSeason = new Date(this.referanceSeasons.filter( el => el.id == this.slot.seasonID)[0].range[1]);
    this.slot.routes.forEach(element => {
      element.periods.forEach( (period, index) => {
        if (!period.start || !period.finish) {
          errors.push(
            this.globalI18n.getMessage(Module.SlotCoord, 'period') +
            ' ' + (index + 1) + ': ' +
            this.globalI18n.getMessage(Module.SlotCoord, 'setDatesPeriod'));
        }
        if ((period.start) && (period.finish) && (period.start >= period.finish)) {
          errors.push(
            this.globalI18n.getMessage(Module.SlotCoord, 'period') +
            ' ' + (index + 1) + ': ' +
            this.globalI18n.getMessage(Module.SlotCoord, 'beginGraterFinish'));
        }
        if ((period.start < startSeason) || (period.finish > finishSeason)) {
          errors.push(
            this.globalI18n.getMessage(Module.SlotCoord, 'period') +
            ' ' + (index + 1) + ': ' +
            this.globalI18n.getMessage(Module.SlotCoord, 'periodOutsideSeason'));
        }
        if (period.frequency == '' || period.frequency == '.......') {
          errors.push(this.globalI18n.getMessage(Module.SlotCoord, 'periodFrequency') + ' ' + (index+1));
        }
      });
    });
    if (errors.length != 0) {
      this.errorMessage = 'Errors:\n' + errors.join('\n');
      this.errorType = 'error';
    } else {
      this.slotForm.form.markAllAsTouched();
      if (this.slotForm.valid) {
        this.loading = true;
        this.restApi.addSlot(this.slot).subscribe((data: {}) => {
            this.loadSlots(this.paramSlotsSeason, this.paramSlotsSchedule);
            this.slotModalForm.nativeElement.click();
            this.loading = false;
          }, err => {
            if (err.type) {
              this.errorType = err.type;
              this.errorMessage = err.message;
              console.log('Error: ' + err.message + '\ndetail:' + err.detail);
            }
          }
        );
      }
    }
  }

  updateSlot(id: number) {
    this.errorMessage = '';
    const errors = [];
    const startSeason = new Date(this.referanceSeasons.filter( el => el.id == this.slot.seasonID)[0].range[0]);
    const finishSeason = new Date(this.referanceSeasons.filter( el => el.id == this.slot.seasonID)[0].range[1]);
    this.slot.routes.forEach(element => {
      element.periods.forEach( (period, index) => {
        period.start = new Date(period.start);
        period.finish = new Date(period.finish);
        if (!period.start || !period.finish) {
          errors.push(
            this.globalI18n.getMessage(Module.SlotCoord, 'period') +
            ' ' + (index + 1) + ': ' +
            this.globalI18n.getMessage(Module.SlotCoord, 'setDatesPeriod'));
        }
        if ((period.start) && (period.finish) && (period.start >= period.finish)) {
          errors.push(
            this.globalI18n.getMessage(Module.SlotCoord, 'period') +
            ' ' + (index + 1) + ': ' +
            this.globalI18n.getMessage(Module.SlotCoord, 'beginGraterFinish'));
        }
        if ((period.start < startSeason) || (period.finish > finishSeason)) {
          errors.push(
            this.globalI18n.getMessage(Module.SlotCoord, 'period') +
            ' ' + (index + 1) + ': ' +
            this.globalI18n.getMessage(Module.SlotCoord, 'periodOutsideSeason'));
        }
        if (period.frequency == '' || period.frequency == '.......') {
          errors.push(this.globalI18n.getMessage(Module.SlotCoord, 'periodFrequency') + ' ' + (index+1));
        }
      });
    });
    if (errors.length != 0) {
      this.errorMessage = 'Errors:\n' + errors.join('\n');
      this.errorType = 'error';
    } else {
      this.slotForm.form.markAllAsTouched();
      if (this.slotForm.valid) {
        this.loading = true;
        this.restApi.updateSlot(id, this.slot).subscribe(data => {
          this.loadSlots(this.paramSlotsSeason, this.paramSlotsSchedule);
          this.slotModalForm.nativeElement.click();
          this.loading = false;
        }, err => {
          if (err.type) {
            this.errorType = err.type;
            this.errorMessage = err.message;
            console.log('Error: ' + err.message + '\ndetail:' + err.detail);
          }
        });
      }
    }
  }

  addRoutes() {
    this.slot.routes.push(new SlotRoutes());
  }

  addRoutePoint(id: number) {
    this.slot.routes[id].route.push(new SlotRoute());
  }

  addToDeleteQueueRoute(value) {
    if (this.deleteQueueRoute.has(value)) {
      this.deleteQueueRoute.delete(value);
    } else {
      this.deleteQueueRoute.add(value);
    }
  }

  deleteRoutePoint(id: number) {
    if ( ( this.slot.routes[id].route.length - this.deleteQueueRoute.size ) < 1 ) {
      this.errorMessage = 'Route must contain at least 1 point';
    } else {
      const deleteQueueRoute = this.deleteQueueRoute;
      this.slot.routes[id].route  = this.slot.routes[id].route.filter(function(el, i) {
        if (!deleteQueueRoute.has(i)) {
          return el;
        }
      });
      this.deleteQueueRoute = new Set();
    }
  }

  addPeriod(id: number) {
    this.slot.routes[id].periods.push(new SlotPeriod());
  }

  addToDeleteQueuePeriod(value) {
    if (this.deleteQueuePeriod.has(value)) {
      this.deleteQueuePeriod.delete(value);
    } else {
      this.deleteQueuePeriod.add(value);
    }
  }

  deletePeriod(id: number) {
    if ( ( this.slot.routes[id].periods.length - this.deleteQueuePeriod.size ) < 1 ) {
      this.errorMessage = 'Route must contain at least 1 period';
    } else {
      const deleteQueuePeriod = this.deleteQueuePeriod;
      this.slot.routes[id].periods  = this.slot.routes[id].periods.filter(function(el, i) {
        if (!deleteQueuePeriod.has(i)) {
          return el;
        }
      });
      this.deleteQueuePeriod = new Set();
    }
  }

  clearDeleteQueue() {
    this.deleteQueuePeriod = new Set();
    this.deleteQueueRoute = new Set();
  }

  loadMovements() {
    return this.restApi.getMovements().then((data: ReferanceMovement[]) => {
      this.referanceMovements = [];
      for (const item of data) {
        const movement = new ReferanceMovement();
        Object.assign(movement, item);
        this.referanceMovements.push(movement);
      }
    }, err => {
      if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    });
  }

  loadAirports() {
    return this.restApi.getAirports().then((data: ReferanceAirport[]) => {
      this.referanceAirport = [];
      for (const item of data) {
        const airport = new ReferanceAirport();
        Object.assign(airport, item);
        this.referanceAirport.push(airport);
      }
    }, err => {
      if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    });
  }

  loadAircrafts() {
    return this.restApi.getAircraftTypes().then((data: ReferanceAircraft[]) => {
      this.referanceAircraftTypes = [];
      for (const item of data) {
        const aircraft = new ReferanceAircraft();
        Object.assign(aircraft, item);
        this.referanceAircraftTypes.push(aircraft);
      }
    }, err => {
        if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    });
  }

  loadAirlines() {
    return this.restApi.getAirlines().then((data: ReferanceAirline[]) => {
      this.referanceAirline = data;
    }, err => {
      if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    });
  }

  loadGeoTypes() {
    return this.restApi.getGeoTypes().then((data: ReferanceGeoTypes[]) => {
      this.referanceGeoTypes = [];
      for (const item of data) {
        const geo = new ReferanceGeoTypes();
        Object.assign(geo, item);
        this.referanceGeoTypes.push(geo);
      }
    }, err => {
      if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    });
  }

  loadSeasons() {
    return this.restApi.getSeasons().then((data: ReferanceSeasons[]) => {
      this.referanceSeasons = [];
      for (const item of data) {
        const season = new ReferanceSeasons();
        Object.assign(season, item);
        this.referanceSeasons.push(season);
      }
    }, err => {
      if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    });
  }

  loadScheduleTypes() {
    return this.restApi.getScheduleTypes().then((data: ReferanceScheduleTypes[]) => {
      this.referanceScheduleTypes = [];
      for (const item of data) {
        const type = new ReferanceScheduleTypes();
        Object.assign(type, item);
        this.referanceScheduleTypes.push(type);
      }
    }, err => {
      if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    });
  }

  parseDate(dateString: string): Date {
    if (dateString) {
      return new Date(dateString);
    }
    return null;
  }

  clearErrorMess() {
    this.errorMessage = '';
  }

  showButton(item: string): boolean {
    return item === this.activeState;
  }

  replaceAt (str, index, replacement) {
    return str.substr(0, index) + replacement + str.substr(index + replacement.length);
  }

  FieldsChange(str: string, pos: number, char: string, values:any){
    if (values.currentTarget.checked) {
      return this.replaceAt(str, pos, char);
    } else {
      return this.replaceAt(str, pos, '.');
    }
  }

  // Функции прокрутки для Аэропортов
  onScrollReferanceAirports({ end }) {
    if (this.referanceAirportsLoading || this.referanceAirport.length <= this.referanceAirportsBuffer.length) {
        return;
    }

    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.referanceAirportsBuffer.length) {
        this.referanceAirportsFetchMore();
    }
  }

  onScrollReferanceAirportsToEnd() {
    this.referanceAirportsFetchMore();
  }

  // Функция загрузки списка для отображения в ng-select Аэропортов
  private referanceAirportsFetchMore() {
    const len = this.referanceAirportsBuffer.length;
    const more = this.referanceAirport.slice(len, this.referanceBufferSize + len);
    this.referanceAirportsLoading = true;
    this.referanceAirportsBuffer = this.referanceAirport.concat(more);
    this.referanceAirportsLoading = false;
  }

  // Функции прокрутки для авиалиний
  onScrollReferanceAirlines({ end }) {
    if (this.referanceAirlinesLoading || this.referanceAirline.length <= this.referanceAirlinesBuffer.length) {
        return;
    }

    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.referanceAirlinesBuffer.length) {
        this.referanceAirlinesFetchMore();
    }
  }

  onScrollReferanceAirlinesToEnd() {
    this.referanceAirlinesFetchMore();
  }

  // Функция загрузки списка для отображения в ng-select авиалиний
  private referanceAirlinesFetchMore() {
    const len = this.referanceAirlinesBuffer.length;
    const more = this.referanceAirline.slice(len, this.referanceBufferSize + len);
    this.referanceAirlinesLoading = true;
    this.referanceAirportsBuffer = this.referanceAirline.concat(more);
    this.referanceAirlinesLoading = false;
  }

  selectSlotFilterSeason(value: any) {
    console.log(value);
    this.paramSlotsSeason = value;
    this.loadSlots(this.paramSlotsSeason, this.paramSlotsSchedule)
  }

  selectSlotFilterSchedule(value: number) {
    this.paramSlotsSchedule = value;
    this.loadSlots(this.paramSlotsSeason, this.paramSlotsSchedule)
  }

	get language() {
		return this.settingsService.language;
	}
}
