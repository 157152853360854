import { Route } from './route';
import { ElectronicDocument } from '../../../weight-balance-data/weight-balance';

export class Flight {
	id: number;
	airlineId: number;
	airlineIata: string;
	flightNumber: string;
	aircraftTypeId: number;
	aircraftTypeIata: string;
	workspaceId: number;
	tailId: number;
	tail: string;
	edno: number;
	route: Route[];
	electronicDocument: ElectronicDocument | null;
}
