import { Component, OnInit, Input, ViewChild, SimpleChanges } from '@angular/core';
import { ReferanceAirport } from 'src/app/referance-module-data/referance-airport';
import { ReferanceAirline } from 'src/app/referance-module-data/referance-airline';
import { ReferanceAircraftType } from 'src/app/referance-module-data/referance-aircraft-types';
import { ReferanceTail } from 'src/app/referance-module-data/referance-tails';
import { Module } from "@shared/models/module-name";
import { FiltersService } from '../../services/filters.service';
import { GlobalI18n } from '@settings/global-i18n';
import { WorkspaceDistribution } from '../../types/reference-filter';
import { Workspace } from 'src/app/weight-balance-data/weight-balance';

@Component({
	selector: 'app-filters',
	templateUrl: './filters.component.html',
	styleUrls: ['./filters.component.less'],
})
export class FiltersComponent implements OnInit {
	// Справочник аэропоров
	@Input() airports: ReferanceAirport[];
	// Справочник авиакомпаний
	@Input() airlines: ReferanceAirline[];
	// Справочник типов воздушных судов
	@Input() aircraftTypes: ReferanceAircraftType[];
	// Справочник бортовых номеров
	@Input() tails: ReferanceTail[];
	// Справочник рабочих столов
	@Input() workspaces: Workspace[];
	// Кнопка закрытия диалогового окна
	@ViewChild('closeFilter') closeFilter: any;

	private _filters: Array<WorkspaceDistribution> = [];
	// Текущий выбранный фильтр
	filter: WorkspaceDistribution = new WorkspaceDistribution();
	// Идентификатор рабочего стола для быстрого поиска по списку фильтров
	searchDesktopId = null;

	message = {
		errorMessage: '',
		errorType: '',
		modalType: '',
	};

	view = {
		// Текущее действие пользователя
		userAction: '',
	};
	userAnswer = null;

	// Список рабочих столов для которых обнаружено дублирование фильтра
	duplicateFilterError = null;

	constructor(private restApi: FiltersService, public globalI18n: GlobalI18n) {}

	ngOnInit(): void {
		this.loadFilters();
	}

	/**
	 * Функция преобразования входящих справоников к необходимому виду
	 *
	 * @param changes Изменяемые данные
	 */
	ngOnChanges(changes: SimpleChanges) {
		if (changes.airports) {
			this.airports = this.airports.map(item => {
				const modifyItem = new ReferanceAirport();
				Object.assign(modifyItem, item);
				return modifyItem;
			});
		} else if (changes.airlines) {
			this.airlines = this.airlines.map(item => {
				const modifyItem = new ReferanceAirline();
				Object.assign(modifyItem, item);
				return modifyItem;
			});
		} else if (changes.aircraftTypes) {
			this.aircraftTypes = this.aircraftTypes.map(item => {
				const modifyItem = new ReferanceAircraftType();
				Object.assign(modifyItem, item);
				return modifyItem;
			});
		} else if (changes.tails) {
			this.tails = this.tails.map(item => {
				const modifyItem = new ReferanceTail();
				Object.assign(modifyItem, item);
				return modifyItem;
			});
		}
	}

	loadFilters() {
		this.restApi.getFilters().then((data: WorkspaceDistribution[]) => {
			this._filters = data;
		});
	}

	/**
	 * Функция вывода списка фильтров с сортировкой по рейтингу и идентификатору
	 */
	get filters(): Array<WorkspaceDistribution> {
		return this._filters
			.sort((a, b) => (a.rating < b.rating || a.id < b.id ? 1 : -1))
			.filter(item => {
				if (this.searchDesktopId) {
					return item.workspaceID === this.searchDesktopId;
				} else {
					return true;
				}
			});
	}

	loadFilter(id: number) {
		this.duplicateFilterError = null;
		this.view.userAction = 'edit-filter';
		this.restApi.getFilter(id).then((data: WorkspaceDistribution) => {
			// Object.assign(this.filter, data);
			this.filter = data;
		});
	}

	/**
	 * Функция проверяет наличие схожего фильтра по параметрам среди имеющихся
	 * @returns True если фильтр с схожим набором параметров существует, иначе False
	 */
	checkDuplicateFilter(): boolean {
		this.duplicateFilterError = null;
		let duplicateFilter = false;
		this.filters.forEach(item => {
			if (
				item.id !== this.filter.id &&
				item.filter.aircraftTypeID === this.filter.filter.aircraftTypeID &&
				item.filter.airlineID === this.filter.filter.airlineID &&
				item.filter.arrivalAirportID === this.filter.filter.arrivalAirportID &&
				item.filter.departureAirportID === this.filter.filter.departureAirportID &&
				item.filter.flight === this.filter.filter.flight &&
				item.filter.tail === this.filter.filter.tail
			) {
				this.duplicateFilterError = this.getById(this.workspaces, item.workspaceID).name + ' (ID: ' + item.id + ')';
				duplicateFilter = true;
			}
		});
		return duplicateFilter;
	}

	createFilter() {
		this.filter = new WorkspaceDistribution();
		this.duplicateFilterError = null;
		this.view.userAction = 'add-filter';
	}

	addFilter() {
		if (this.checkDuplicateFilter()) {
			return;
		}
		this.restApi.addFilter(this.filter).then(data => {
			this.closeFilter.nativeElement.click();
			this.loadFilters();
		});
	}

	saveFilter(id: number) {
		if (this.checkDuplicateFilter()) {
			return;
		}
		this.restApi.saveFilter(id, this.filter).then(data => {
			this.closeFilter.nativeElement.click();
			this.loadFilters();
		});
	}

	getById(array, id: number) {
		if (!array || array.length == 0) return null;
		const res = array.filter(el => el.id === id)[0];
		if (res) {
			return res;
		} else {
			return null;
		}
	}

	async deleteFilter(id: number) {
		this.message.errorMessage = this.globalI18n.getMessage(Module.WeightBalance, 'deleteFilter');
		this.message.errorType = 'warning';
		this.message.modalType = 'setAnswer';
		await this.waitAnswer().then(async res => {
			if (res) {
				// TODO заплатка для фильтра созданного в этот же день
				// необходимо на бэке делать метод DELETE
				this.filter.dtRange[0] = new Date(Date.now() - 3600 * 1000 * 48);
				this.filter.dtRange[1] = new Date(Date.now() - 3600 * 1000 * 24);
				this.restApi.saveFilter(id, this.filter).then(data => {
					this.closeFilter.nativeElement.click();
					this.loadFilters();
				});
				// this.restApi.deleteFilter(id).then(data => {
				//   this.closeFilter.nativeElement.click();
				//   this.loadFilters();
				// });
			}
		});
	}

	async waitAnswer() {
		const res = await this.checkAnswer();
		return res;
	}

	checkAnswer() {
		return new Promise(resolve => {
			const interval = setInterval(() => {
				if (this.userAnswer !== null) {
					const answer = this.userAnswer;
					this.clearErrorMess();
					clearInterval(interval);
					resolve(answer);
				}
			}, 300);
		});
	}

	clearErrorMess() {
		this.message.errorMessage = '';
		this.message.errorType = '';
		this.userAnswer = null;
	}
}
