<nz-modal
	[nzVisible]="componentNewModalIsVisible()"
	[nzTitle]="title"
	(nzOnCancel)="handleComponentNewCancel()"
	(nzOnOk)="handleComponentNewOk()">
	<ng-container *nzModalContent>
		<form
			nz-form
			[formGroup]="validateForm"
			(ngSubmit)="handleComponentNewOk()">
			<nz-form-item>
				<nz-form-label
					[nzSm]="8"
					[nzXs]="24"
					nzRequired
					nzFor="organization"
					i18n="@@control-service.modal.field.organization.label"
					>Organization</nz-form-label
				>
				<nz-form-control
					[nzSm]="14"
					[nzXs]="24"
					nzErrorTip="Please enter organization name"
					i18n-nzErrorTip="@@control-service.modal.field.organization.errorTip">
					<input
						nz-input
						formControlName="org"
						id="organization"
						type="text" />
				</nz-form-control>
			</nz-form-item>
			<nz-form-item>
				<nz-form-label
					[nzSm]="8"
					[nzXs]="24"
					nzFor="project"
					nzRequired
					i18n="@@control-service.modal.field.project.label"
					>Project</nz-form-label
				>
				<nz-form-control
					[nzSm]="14"
					[nzXs]="24"
					nzErrorTip="Please enter project name"
					i18n-nzErrorTip="@@control-service.modal.field.project.errorTip">
					<input
						nz-input
						type="text"
						id="project"
						formControlName="project" />
				</nz-form-control>
			</nz-form-item>
			<nz-form-item>
				<nz-form-label
					[nzSm]="8"
					[nzXs]="24"
					nzFor="component"
					nzRequired
					i18n="@@control-service.modal.field.component.label"
					>Component</nz-form-label
				>
				<nz-form-control
					[nzSm]="14"
					[nzXs]="24"
					nzErrorTip="Please select component"
					i18n-nzErrorTip="@@control-service.modal.field.component.errorTip">
					<nz-select
						id="component"
						nzShowSearch
						nzAllowClear
						formControlName="componentType">
						@for ( component of componentList | keyvalue; track componentList) {
						<nz-option
							nzLabel="{{ component.key }}"
							nzValue="{{ component.value }}"></nz-option>
						}
					</nz-select>
				</nz-form-control>
			</nz-form-item>
			<nz-form-item>
				<nz-form-label
					[nzSm]="8"
					[nzXs]="24"
					nzFor="url"
					nzRequired>
					<span i18n="@@control-service.modal.field.serviceUrl.label">Service URL</span>
				</nz-form-label>
				<nz-form-control
					[nzSm]="14"
					[nzXs]="24"
					nzErrorTip="Please enter service URL"
					i18n-nzErrorTip="@@control-service.modal.field.serviceUrl.errorTip">
					<input
						nz-input
						id="url"
						formControlName="url" />
				</nz-form-control>
			</nz-form-item>
			<nz-form-item>
				<nz-form-label
					[nzSm]="8"
					[nzXs]="24"
					nzFor="password"
					nzRequired
					i18n="@@control-service.modal.field.passwordForService.label"
					>Password for service</nz-form-label
				>
				<nz-form-control
					[nzSm]="14"
					[nzXs]="24"
					nzErrorTip="Please enter password for service"
					i18n-nzErrorTip="@@control-service.modal.field.passwordForService.errorTip">
					<nz-input-group [nzSuffix]="suffixTemplate">
						<input
							[type]="passwordNewModalIsVisible ? 'text' : 'password'"
							nz-input
							id="password"
							formControlName="password" />
					</nz-input-group>
					<ng-template #suffixTemplate>
						<span
							nz-icon
							nzTheme="outline"
							nzType="eye"
							(click)="passwordNewModalIsVisible = !passwordNewModalIsVisible"></span>
					</ng-template>
				</nz-form-control>
			</nz-form-item>
		</form>
	</ng-container>
</nz-modal>
