import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WeightBalanceMobileReferenceActions, WeightBalanceMobileActions } from './weight-balance-mobile.actions';
import { WeightBalanceMobileService } from '../services/weight-balance-mobile.service';
import { map, mergeMap, switchMap } from 'rxjs';

@Injectable()
export class WeightBalanceMobileEffects {
	getReferenceAircraftType$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(WeightBalanceMobileReferenceActions.getReferences),
			mergeMap(() =>
				this.weightBalanceMobileService
					.getReferenceAircraftType()
					.pipe(map(data => WeightBalanceMobileReferenceActions.getReferenceAircraftTypeSuccess({ data: data })))
			)
		);
	});

	getReferenceAirline$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(WeightBalanceMobileReferenceActions.getReferences),
			mergeMap(() =>
				this.weightBalanceMobileService
					.getReferenceAirline()
					.pipe(map(data => WeightBalanceMobileReferenceActions.getReferenceAirlineSuccess({ data: data })))
			)
		);
	});

	getReferenceAirport$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(WeightBalanceMobileReferenceActions.getReferences),
			mergeMap(() =>
				this.weightBalanceMobileService
					.getReferenceAirport()
					.pipe(map(data => WeightBalanceMobileReferenceActions.getReferenceAirportSuccess({ data: data })))
			)
		);
	});

	getReferenceTail$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(WeightBalanceMobileReferenceActions.getReferences),
			mergeMap(() =>
				this.weightBalanceMobileService
					.getReferenceTail()
					.pipe(map(data => WeightBalanceMobileReferenceActions.getReferenceTailSuccess({ data: data })))
			)
		);
	});

	getFlights$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(WeightBalanceMobileActions.getFlights),
			switchMap(data =>
				this.weightBalanceMobileService
					.getFlights(data.workspace)
					.pipe(map(data => WeightBalanceMobileActions.getFlightsSuccess({ data: data })))
			)
		);
	});

	getLoadSheet$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(WeightBalanceMobileActions.getLoadSheet),
			switchMap(data =>
				this.weightBalanceMobileService
					.getLoadSheet(data.flight, data.editions)
					.pipe(map(data => WeightBalanceMobileActions.getLoadSheetSuccess({ data: data })))
			)
		);
	});

	getLoadInstruction$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(WeightBalanceMobileActions.getLoadingInstruction),
			switchMap(data =>
				this.weightBalanceMobileService
					.getLoadInstruction(data.flight, data.editions)
					.pipe(map(data => WeightBalanceMobileActions.getLoadingInstructionSuccess({ data: data })))
			)
		);
	});

	getElectronicDocuments$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(WeightBalanceMobileActions.getElectronicDocuments),
			switchMap(data =>
				this.weightBalanceMobileService
					.getAllElectronicDocumentByFlightList(data.flightIds)
					.pipe(map(data => WeightBalanceMobileActions.getElectronicDocumentsSuccess({ data: data })))
			)
		);
	});

	getDesktops$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(WeightBalanceMobileActions.getDesktops),
			switchMap(() =>
				this.weightBalanceMobileService
					.getDesktops()
					.pipe(map(data => WeightBalanceMobileActions.getDesktopsSuccess({ data: data })))
			)
		);
	});

	updateDocumentsStatus$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(WeightBalanceMobileActions.updateDocumentsStatus),
			mergeMap(data =>
				this.weightBalanceMobileService
					.updateDocumentsStatus(data.document)
					.pipe(map(data => WeightBalanceMobileActions.updateDocumentsStatusSuccess(data)))
			)
		);
	});

	updateDocumentsStatusSuccess$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(WeightBalanceMobileActions.updateDocumentsStatusSuccess),
			map(data => WeightBalanceMobileActions.getElectronicDocuments({ flightIds: [data.flight_id] }))
		);
	});

	constructor(private actions$: Actions, private weightBalanceMobileService: WeightBalanceMobileService) {}
}
