/**
 * App supporter
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApiResponse { 
    type?: ApiResponse.TypeEnum;
    message?: string;
    detail?: string;
}
export namespace ApiResponse {
    export type TypeEnum = 'error' | 'warning' | 'ok';
    export const TypeEnum = {
        Error: 'error' as TypeEnum,
        Warning: 'warning' as TypeEnum,
        Ok: 'ok' as TypeEnum
    };
}


