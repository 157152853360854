import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LostFoundModuleRestApiService } from 'src/app/lost-found-module/services/lost-found-rest-api.service';
import { File } from '../../../../types/files';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalMessageComponent } from '@shared/components/modal-message/modal-message.component';
import { Module } from "@shared/models/module-name";
import { GlobalI18n } from '@settings/global-i18n';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.less']
})
export class PhotoComponent implements OnInit {
  @Input() baggageId: string;
  @ViewChild('fileInput') fileInput: HTMLInputElement;
  @ViewChild(ModalMessageComponent) modalError: ModalMessageComponent;
  @Output() photoAvailable = new EventEmitter<boolean>();

  loadingPhotoProcess = null;
  imageBlobUrl: string | ArrayBuffer | null = null;
  files: Array<File> = [];
  constructor(
    public restApi: LostFoundModuleRestApiService,
    private _sanitizer: DomSanitizer,
    private modalService: NgbModal,
    public globalI18n: GlobalI18n,
  ) {
  }

  ngOnInit(): void {
    this.loadPhotos();
  }

  loadPhotos() {
    if (this.baggageId) {
      this.restApi.getFiles(this.baggageId, 'image').then(data => {
        this.files = data;
        if (this.files.length > 0) {
          this.photoAvailable.emit(true);
        }
      });
    }
  }

  loadPhoto(id: string, content) {
    this.restApi.getFile(this.baggageId, 'image', id).subscribe(data => {
      this.createImageFromBlob(data);
    });
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      size: 'xl',
      windowClass: 'custom-ngb-modal-window',
      backdropClass: 'custom-ngb-modal-backdrop'
    });
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.imageBlobUrl = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  uploadPhoto(file) {
    const uploadData = new FormData();
    if (file.files[0].size > 10485760) {
      const errorMessage = this.globalI18n.getMessage(Module.LostFound, 'maxSize10MB');
      const errorType = 'error';
      this.modalError.showErrorMess(errorMessage, errorType);
      file.value = "";
      return false;
    }
    uploadData.append('file', file.files[0]);
    uploadData.append('name', file.files[0].name);
    this.restApi.uploadFiles(this.baggageId, 'image', uploadData).subscribe( {
      next: data => {
        if (data?.total && data?.loaded) {
          this.loadingPhotoProcess = Math.round(data.loaded * 100 / data.total);
        } else if (data.status === 201) {
          this.loadingPhotoProcess = null;
          this.loadPhotos();
        } else {
          this.loadingPhotoProcess = null;
        }

      },
      error: err => {
        const errorMessage = `${this.globalI18n.getMessage(Module.LostFound, 'attachPhotoError')} ${err.detail}`;
        const errorType = 'error';
        this.modalError.showErrorMess(errorMessage, errorType);
        file.value = "";
        this.loadingPhotoProcess = null;
        return false;
      }
    });

  }

  deletePhoto(id: string, event) {
    event.stopPropagation();
    this.restApi.deleteFile(this.baggageId, 'image', id).then(data => {
      this.loadPhotos();
    });
  }

  showPhoto(file: File) {
    if (file.preview != '') {
      return this._sanitizer.bypassSecurityTrustResourceUrl('data:image/' + file.extension.substring(1) + ';base64,' + file.preview);
    } else {
      return this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPAAAADwCAYAAAA+VemSAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAATASURBVHgB7d2xctt0HMDxn2kG0oXwBmJjzAO0hxnZyhO0jEx46V3tDilL4y15g4SRKR3ZyJC98ASEjTFM2RL+yqlQelEs2bKsf/P53PnsJqlzl/NX0k+yrAgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA+jUKljKZTHa2t7fH19fXO8EqzsvbfD4/D1oTcEsvXrwoRqPRUXo4DjqT/qbHV1dXPwq5nQdBYy9fvnya7t6k25dB13ZTxM8eP37819nZ2e9BI9bADc1ms920ufw2WLv0d/46rYlPg4U+CRpJL6qToBfViEIDAm5gOp0+SXdF0Jci7WsYBwsJuIG09h0HfXsSLLQVNHHXoaLTtMl3HrSWFoxF1O/N/yxYSMArSi/Cn/b394+D1tJm8rO08BsHS7MJDRkTMGRMwJAxAUPGBAwZEzBkTMCQMQFDxgQMGRMwZEzAkDEBQ8YEDBkTMGRMwJAxAUPGBAwZEzBkTMCQMQFDxgQMGRMwZEzAkDEBQ8YEzL9cjyg/AubGbDbbG41GJ+UFzINsCJh31z5+lR7ulBEH2RDwPVeucT+49vHudDo9CLIg4HsurXHLWIsPvjypronMwAk4A5PJZCfWoJx7o/46vEfm4eET8MCVl+Dc3t5+23XE5R7nau6tU87DR8GgCXjAyjVgiqhcSxYp4r3oSPW8TeIcm4eHTcADliL7Nf6bTyddHaet4i0a/vjE8eHhEvBAVWu+4v2vdXGctpp7x23+Txm8eXiYBDxA5dyb7ia3fGulubTB3FunMA8Pk4AHpppP75o7y7l0Ess97yoRLvV7WS8BD0i5p7maexftcd57/vz5brTQcu6tc2AeHhYBD0i1p7lo8KM7W1tbJ00PLS0z99YpFwTrOi5NewIeiBTZD3H73Fun0aGlFebeu36veXggBDwA1fuRD6O9Ow/xdDD31nliHh4GAW/Ye3PvUu46tFSdWVTEehy0ncPpnoA37OHDh7edTNDGrYeWqrl3rYG1mcNZDwFvUBlZ2nR+Fqv73yGe8kyijufeOkW1AGJDBLwh1dz7KrqzVz5ntTndW1TlAsg8vDkC3oBq59LSc2+Nm0/TWPPcW2fPWy03Q8AbUHMSfRd2Y81zb42bHXHm4f4JuGcLTqLPWaenPNLMVtC3cvP5OD5OO+Wm9Hw+Pw96IeCevX79+ruAjtiEhowJGDImYMiYgCFjAoaMCRgyJmDImIAhYwKGjAkYMuatlCsajUZPZ7PZV0Fr19fXRbASATdzccf3yk99DDr3d7CQTehm3gS9urq6Og0WGgWNTKfTP6L/T7q4r8739/e/CBayBm4obSY7DbAnab/Ct0EjD4JGzs7Ozh89evRnenGN0z8/DdbhIi0ov09r31+CRgTcQor4txTxz+nh5ylkH2rerdMU7zfz+fw0aMwMvKTqUxjf3VhSWhBeXF5enh4eHl4EAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAzeP4QPIF1EwVULAAAAAElFTkSuQmCC');
    }
  }

}
