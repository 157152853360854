<ul class="nav nav-tabs">
	<li class="nav-item">
		<a
			id="tab-filters"
			[ngClass]="{ active: showTab('tab-filters') }"
			class="nav-link active"
			(click)="changeTab($event)"
			i18n="adm|@@adm.comMod.tab.filters">
			Filters
		</a>
	</li>
	<li class="nav-item">
		<a
			id="tab-folders"
			[ngClass]="{ active: showTab('tab-folders') }"
			class="nav-link"
			(click)="changeTab($event)"
			i18n="adm|@@adm.comMod.tab.folders">
			Folders
		</a>
	</li>
	<!--<li class="nav-item">
    <a id="tab-templates" [ngClass]="{'active': showTab('tab-templates')}" class="nav-link" (click)="changeTab($event)"
       i18n="adm|@@adm.comMod.tab.templates">
      Templates
    </a>
  </li>-->
	<li class="nav-item">
		<a
			id="tab-contacts"
			[ngClass]="{ active: showTab('tab-contacts') }"
			class="nav-link"
			(click)="changeTab($event)"
			i18n="adm|@@adm.comMod.tab.contacts">
			Contacts
		</a>
	</li>
	<li class="nav-item">
		<a
			id="tab-addresses"
			[ngClass]="{ active: showTab('tab-addresses') }"
			class="nav-link"
			(click)="changeTab($event)"
			i18n="adm|@@adm.comMod.tab.internalAddresses">
			Internal addresses
		</a>
	</li>
	<li class="nav-item">
		<a
			id="tab-senders"
			[ngClass]="{ active: showTab('tab-senders') }"
			class="nav-link"
			(click)="changeTab($event)"
			i18n="adm|@@adm.comMod.tab.senders">
			Senders
		</a>
	</li>
	<li class="nav-item">
		<a
			id="tab-groups"
			[ngClass]="{ active: showTab('tab-groups') }"
			class="nav-link"
			(click)="changeTab($event)"
			i18n="adm|@@adm.comMod.tab.groups">
			Groups
		</a>
	</li>
	<li class="nav-item">
		<a
			id="tab-users"
			[ngClass]="{ active: showTab('tab-users') }"
			class="nav-link"
			(click)="changeTab($event)"
			i18n="adm|@@adm.comMod.tab.users">
			Users
		</a>
	</li>
</ul>

<!-- Tab filters -->
<div
	*ngIf="showTab('tab-filters')"
	class="bg-white p-3">
	<div class="col">
		<div class="row my-2">
			<div class="col-6 pl-0">
				<button
					type="button"
					class="btn btn-sm btn-light-gray mr-1"
					data-toggle="modal"
					data-target=".bd-example-modal-xl"
					(click)="createFilter()">
					<i class="material-icons">add</i>
					<span i18n="adm|@@adm.comMod.tab.filters.button.new">New</span>
				</button>
			</div>
		</div>
	</div>
	<table class="table table-hover w-100">
		<thead class="">
			<tr #trFilters>
				<th
					class="text-center text-uppercase"
					i18n="adm|@@adm.comMod.tab.filters.table.column.enabled">
					Enabled
				</th>
				<th
					class="text-center text-uppercase"
					field="name"
					data-method
					(click)="sortBy($event.target, trFilters)"
					i18n="adm|@@adm.comMod.tab.filters.table.column.name">
					Name
				</th>
				<th
					class="text-center text-uppercase"
					i18n="adm|@@adm.comMod.tab.filters.table.column.folder">
					Folder
				</th>
				<th
					class="text-uppercase"
					field="lastupdate"
					data-method
					(click)="sortBy($event.target, trFilters)"
					i18n="adm|@@adm.comMod.tab.filters.table.column.lastUpdate">
					Last update
				</th>
			</tr>
		</thead>
		<tbody>
			<tr
				*ngFor="let filter of filters"
				data-toggle="modal"
				data-target=".bd-example-modal-xl"
				(click)="loadFilter(filter.id)">
				<td class="text-center">
					<span
						*ngIf="filter.active"
						class="material-icons text-green"
						>check</span
					>
					<span
						*ngIf="!filter.active"
						class="material-icons text-dark-gray"
						>close</span
					>
				</td>
				<td>{{ filter.name }}</td>
				<td>
					<span *ngFor="let rule of filter.foldersRules; let j = index">
						{{ rule.folderName }}<span *ngIf="filter.foldersRules && filter.foldersRules.length > j + 1">, </span>
					</span>
				</td>
				<td>{{ filter.lastupdate | date : 'dd.MM.yyyy HH:mm' }}</td>
			</tr>
		</tbody>
	</table>
</div>

<!-- Tab folders -->
<div
	*ngIf="showTab('tab-folders')"
	class="bg-white p-3">
	<div class="row my-2">
		<div class="col-12">
			<button
				type="button"
				class="btn btn-sm btn-light-gray mr-1"
				data-toggle="modal"
				data-target=".bd-example-modal-xl"
				(click)="createFolder()">
				<i class="material-icons">add</i>
				<span i18n="adm|@@adm.comMod.tab.folders.button.new">New</span>
			</button>
			<button
				type="button"
				class="btn btn-sm btn-light-gray mr-1"
				(click)="downloadFile('folders', 'csv')">
				<i class="material-icons">arrow_downward</i>
				<span i18n="adm|@@adm.comMod.tab.folders.button.export">Export</span>
			</button>
		</div>
	</div>
	<div class="row my-2">
		<!-- Folder tree -->
		<div class="col-12 mb-4">
			<table class="table table-hover w-100">
				<thead class="text-center">
					<tr #trFolders>
						<th
							class="text-uppercase"
							field="id"
							data-method
							(click)="sortBy($event.target, trFolders)"
							i18n="adm|@@adm.comMod.tab.filters.table.column.Id">
							Identifier
						</th>
						<th
							class="text-center text-uppercase"
							field="name"
							data-method
							(click)="sortBy($event.target, trFolders)"
							i18n="adm|@@adm.comMod.tab.filters.table.column.name">
							Name
						</th>
						<th
							class="text-center text-uppercase"
							i18n="adm|@@adm.comMod.tab.filters.table.column.channel">
							Channel
						</th>
						<th
							class="text-center text-uppercase"
							i18n="adm|@@adm.comMod.tab.filters.table.column.type">
							Type
						</th>
						<th
							class="text-center text-uppercase"
							i18n="adm|@@adm.comMod.tab.filters.table.column.sender">
							Sender
						</th>
					</tr>
				</thead>
				<tbody>
					<tr
						*ngFor="let folder of letterFolders"
						data-toggle="modal"
						data-target=".bd-example-modal-xl"
						(click)="loadFolder(folder.id)">
						<td>{{ folder.id }}</td>
						<td>{{ folder.name }}</td>
						<td>{{ folder.channelName }}</td>
						<td>{{ folder.messageTypeName }}</td>
						<td>{{ folder.allSenders }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>

<!-- Tab templates -->
<!--<div *ngIf="showTab('tab-templates')" class="bg-white p-3">
  <div class="col">
    <div class="row my-2">
      <div class="col-6 pl-0">
        <button type="button" class="btn btn-sm btn-light-gray mr-1" data-toggle="modal" data-target=".bd-example-modal-xl"
          (click)="createTemplate()">
          <i class="material-icons">add</i>
          <span i18n="adm|@@adm.comMod.tab.templates.button.new">New</span>
        </button>
      </div>
    </div>
  </div>
  <table class="table table-hover w-100">
    <thead class="">
      <tr #trTemplates>
        <th class="text-center text-uppercase"
            field="name"
            data-method
            (click)="sortBy($event.target, trTemplates)"
            i18n="adm|@@adm.comMod.tab.templates.table.column.name">
          Name
        </th>
        <th class="text-uppercase"
            field="lastupdate"
            data-method
            (click)="sortBy($event.target, trTemplates)"
            i18n="adm|@@adm.comMod.tab.templates.table.column.lastUpdate">
          Last update
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let template of templates" data-toggle="modal" data-target=".bd-example-modal-xl"
        (click)="loadTemplate(template.id)">
        <td>{{ template.name }}</td>
        <td>{{ template.lastupdate | date: 'dd.MM.yyyy HH:mm' }}</td>
      </tr>
    </tbody>
  </table>
</div>-->

<!-- Tab contacts -->
<div
	*ngIf="showTab('tab-contacts')"
	class="bg-white p-3">
	<div class="col">
		<div class="row my-2">
			<div class="col-6 pl-0">
				<button
					type="button"
					class="btn btn-sm btn-light-gray mr-1"
					data-toggle="modal"
					data-target=".bd-example-modal-xl"
					(click)="createContact()">
					<i class="material-icons">add</i>
					<span i18n="adm|@@adm.comMod.tab.contacts.button.new">New</span>
				</button>
				<button
					type="button"
					class="btn btn-sm btn-light-gray mr-1"
					(click)="downloadFile('contacts', 'csv')">
					<i class="material-icons">arrow_downward</i>
					<span i18n="adm|@@adm.comMod.tab.contacts.button.export">Export</span>
				</button>
			</div>
		</div>
	</div>
	<table class="table table-hover w-100">
		<thead class="">
			<tr #trContacts>
				<th
					class="text-center text-uppercase"
					field="name"
					data-method
					(click)="sortBy($event.target, trContacts)"
					i18n="adm|@@adm.comMod.tab.contacts.table.column.name">
					Name
				</th>
				<th
					class="text-center text-uppercase"
					field="channel"
					data-method
					(click)="sortBy($event.target, trContacts)"
					i18n="adm|@@adm.comMod.tab.contacts.table.column.channel">
					Channel
				</th>
				<th
					class="text-center text-uppercase"
					field="address"
					data-method
					(click)="sortBy($event.target, trContacts)"
					i18n="adm|@@adm.comMod.tab.contacts.table.column.address">
					Address
				</th>
				<th
					class="text-uppercase"
					field="lastupdate"
					data-method
					(click)="sortBy($event.target, trContacts)"
					i18n="adm|@@adm.comMod.tab.contacts.table.column.lastUpdate">
					Last update
				</th>
			</tr>
		</thead>
		<tbody>
			<tr
				*ngFor="let contact of contacts"
				data-toggle="modal"
				data-target=".bd-example-modal-xl"
				(click)="loadContact(contact.id)">
				<td>{{ contact.name }}</td>
				<td>{{ contact.channel }}</td>
				<td>{{ contact.address }}</td>
				<td>{{ contact.lastupdate | date : 'dd.MM.yyyy HH:mm' }}</td>
			</tr>
		</tbody>
	</table>
</div>

<!-- Tab internal addresses -->
<div
	*ngIf="showTab('tab-addresses')"
	class="bg-white p-3">
	<div class="col">
		<div class="row my-2">
			<div class="col-6 pl-0">
				<button
					type="button"
					class="btn btn-sm btn-light-gray mr-1"
					data-toggle="modal"
					data-target=".bd-example-modal-xl"
					(click)="createAddress()">
					<i class="material-icons">add</i>
					<span i18n="adm|@@adm.comMod.tab.internalAddresses.button.new">New</span>
				</button>
			</div>
		</div>
	</div>
	<table class="table table-hover w-100">
		<thead class="">
			<tr #trAddresses>
				<th
					class="text-center text-uppercase"
					field="airport"
					data-method
					(click)="sortBy($event.target, trAddresses)"
					i18n="adm|@@adm.comMod.tab.internalAddresses.table.column.airport">
					Airport
				</th>
				<th
					class="text-center text-uppercase"
					field="channel"
					data-method
					(click)="sortBy($event.target, trAddresses)"
					i18n="adm|@@adm.comMod.tab.internalAddresses.table.column.channel">
					Channel
				</th>
				<th
					class="text-center text-uppercase"
					field="address"
					data-method
					(click)="sortBy($event.target, trAddresses)"
					i18n="adm|@@adm.comMod.tab.internalAddresses.table.column.address">
					Address
				</th>
				<th
					class="text-uppercase"
					field="lastupdate"
					data-method
					(click)="sortBy($event.target, trAddresses)"
					i18n="adm|@@adm.comMod.tab.internalAddresses.table.column.lastUpdate">
					Last update
				</th>
			</tr>
		</thead>
		<tbody>
			<tr
				*ngFor="let address of addresses"
				data-toggle="modal"
				data-target=".bd-example-modal-xl"
				(click)="loadAddress(address.id)">
				<td>{{ address.airport }}</td>
				<td>{{ address.channel }}</td>
				<td>{{ address.address }}</td>
				<td>{{ address.lastupdate | date : 'dd.MM.yyyy HH:mm' }}</td>
			</tr>
		</tbody>
	</table>
</div>

<!-- Tab senders -->
<div
	*ngIf="showTab('tab-senders')"
	class="bg-white p-3">
	<div class="col">
		<div class="row my-2">
			<div class="col-6 pl-0">
				<button
					type="button"
					class="btn btn-sm btn-light-gray mr-1"
					data-toggle="modal"
					data-target=".bd-example-modal-xl"
					(click)="createSender()">
					<i class="material-icons">add</i>
					<span i18n="adm|@@adm.comMod.tab.senders.button.new">New</span>
				</button>
				<button
					type="button"
					class="btn btn-sm btn-light-gray mr-1"
					(click)="downloadFile('senders', 'csv')">
					<i class="material-icons">arrow_downward</i>
					<span i18n="adm|@@adm.comMod.tab.senders.button.export">Export</span>
				</button>
			</div>
		</div>
	</div>
	<table class="table table-hover w-100">
		<thead class="">
			<tr #trSenders>
				<!--<th class="text-center text-uppercase"
            field="baseAp"
            data-method
            (click)="sortBy($event.target, trSenders)"
            i18n="adm|@@adm.comMod.tab.senders.table.column.airport">
          Airport
        </th>-->
				<th
					class="text-center text-uppercase"
					field="channelName"
					data-method
					(click)="sortBy($event.target, trSenders)"
					i18n="adm|@@adm.comMod.tab.senders.table.column.channel">
					Channel
				</th>
				<th
					class="text-center text-uppercase"
					field="folderName"
					data-method
					(click)="sortBy($event.target, trSenders)"
					i18n="adm|@@adm.comMod.tab.senders.table.column.folder">
					Folder
				</th>
				<th
					class="text-center text-uppercase"
					field="addressEnglish"
					data-method
					(click)="sortBy($event.target, trSenders)"
					i18n="adm|@@adm.comMod.tab.senders.table.column.address">
					Address
				</th>
				<th
					class="text-uppercase"
					field="lastupdate"
					data-method
					(click)="sortBy($event.target, trSenders)"
					i18n="adm|@@adm.comMod.tab.senders.table.column.lastUpdate">
					Last update
				</th>
			</tr>
		</thead>
		<tbody>
			<tr
				*ngFor="let sender of senders"
				data-toggle="modal"
				data-target=".bd-example-modal-xl"
				(click)="loadSender(sender.id)">
				<!--<td>{{ sender.baseAp }}</td>-->
				<td>{{ sender.channelName }}</td>
				<td>{{ sender.folderName }}</td>
				<td>{{ sender.addressEnglish }}</td>
				<td>{{ sender.lastupdate | date : 'dd.MM.yyyy HH:mm' }}</td>
			</tr>
		</tbody>
	</table>
</div>

<!-- Tab groups -->
<div
	*ngIf="showTab('tab-groups')"
	class="bg-white p-3">
	<div class="col">
		<div class="row my-2">
			<div class="col-6 pl-0">
				<button
					type="button"
					class="btn btn-sm btn-light-gray mr-1"
					data-toggle="modal"
					data-target=".bd-example-modal-xl"
					(click)="createGroup()">
					<i class="material-icons">add</i>
					<span i18n="adm|@@adm.comMod.tab.groups.button.new">New</span>
				</button>
			</div>
		</div>
	</div>
	<table class="table table-hover w-100">
		<thead class="">
			<tr #trGroups>
				<th
					class="text-center text-uppercase"
					field="name"
					data-method
					(click)="sortBy($event.target, trGroups)"
					i18n="adm|@@adm.comMod.tab.groups.table.column.name">
					Name
				</th>
			</tr>
		</thead>
		<tbody>
			<tr
				*ngFor="let group of groups"
				data-toggle="modal"
				data-target=".bd-example-modal-xl"
				(click)="loadGroup(group.id)">
				<td>{{ group.name }}</td>
			</tr>
		</tbody>
	</table>
</div>

<!-- Tab users -->
<div
	*ngIf="showTab('tab-users')"
	class="bg-white p-3">
	<div class="col">
		<div class="row my-2">
			<div class="col-6 pl-0">
				<button
					type="button"
					class="btn btn-sm btn-light-gray mr-1"
					data-toggle="modal"
					data-target=".bd-example-modal-xl"
					(click)="createUser()">
					<i class="material-icons">add</i>
					<span i18n="adm|@@adm.comMod.tab.users.button.new">New</span>
				</button>
			</div>
		</div>
	</div>
	<table class="table table-hover w-100">
		<thead class="">
			<tr #trUsers>
				<th
					class="text-center text-uppercase"
					i18n="adm|@@adm.comMod.tab.users.table.column.enabled">
					Enabled
				</th>
				<th
					class="text-center text-uppercase"
					field="username"
					data-method
					(click)="sortBy($event.target, trUsers)"
					i18n="adm|@@adm.comMod.tab.users.table.column.username">
					Username
				</th>
				<th
					class="text-center text-uppercase"
					field="firstName"
					data-method
					(click)="sortBy($event.target, trUsers)"
					i18n="adm|@@adm.comMod.tab.users.table.column.firstName">
					First name
				</th>
				<th
					class="text-center text-uppercase"
					field="lastName"
					data-method
					(click)="sortBy($event.target, trUsers)"
					i18n="adm|@@adm.comMod.tab.users.table.column.lastName">
					Last name
				</th>
				<th
					class="text-center text-uppercase"
					i18n="adm|@@adm.comMod.tab.users.table.column.groups">
					Groups
				</th>
			</tr>
		</thead>
		<tbody>
			<tr
				*ngFor="let user of users"
				data-toggle="modal"
				data-target=".bd-example-modal-xl"
				(click)="loadUser(user.id)">
				<td class="text-center">
					<span
						*ngIf="user.enabled"
						class="material-icons text-green"
						>check</span
					>
					<span
						*ngIf="!user.enabled"
						class="material-icons text-dark-gray"
						>close</span
					>
				</td>
				<td>{{ user.username }}</td>
				<td>{{ user.firstName }}</td>
				<td>{{ user.lastName }}</td>
				<td>
					<ng-container *ngFor="let userGroup of user.groups; let i = index">
						{{ userGroup.name }}{{ i + 1 < user.groups.length ? ',' : '' }}
					</ng-container>
				</td>
			</tr>
		</tbody>
	</table>
</div>

<!-- Modal Filters -->
<div *ngIf="showTab('tab-filters')">
	<form #filterForm="ngForm">
		<div
			class="modal fade bd-example-modal-xl"
			data-backdrop="static"
			tabindex="-1"
			role="dialog"
			aria-labelledby="myExtraLargeModalLabel"
			aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-xxl">
				<div class="modal-content bg-light-gray">
					<div
						class="loading"
						*ngIf="loading">
						<div class="cssload-spin-box"></div>
					</div>
					<div class="modal-header">
						<h5
							*ngIf="activeState === 'action-append'"
							class="modal-title"
							id="exampleModalScrollableTitle">
							<span i18n="adm|@@adm.comMod.modal.filters.headerAppend">Filter append</span>&nbsp;{{ filter.name }}
						</h5>
						<h5
							*ngIf="activeState === 'action-update'"
							class="modal-title"
							id="exampleModalScrollableTitle">
							<span i18n="adm|@@adm.comMod.modal.filters.headerEdit">Edit filter</span>&nbsp;{{ filter.name }}
						</h5>
						<button
							#closebutton
							type="button"
							class="close"
							data-dismiss="modal"
							aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="row mb-4">
							<div class="col-6">
								<div class="input-group">
									<div class="input-group-prepend">
										<span
											class="input-group-text"
											i18n="adm|@@adm.comMod.modal.filters.field.filterName"
											>Filter name</span
										>
									</div>
									<input
										type="text"
										id="filterName"
										class="form-control"
										name="filterName"
										#filterName="ngModel"
										[(ngModel)]="filter.name"
										value="{{ filter.name }}"
										required />
								</div>
								<div
									*ngIf="!filterName?.valid && (filterName?.dirty || filterName?.touched)"
									class="invalid-feedback d-block">
									<div
										*ngIf="filterName.errors.required"
										i18n="adm|@@adm.comMod.modal.filters.err.name">
										Name is required
									</div>
								</div>
							</div>
							<div class="col-6 pt-2">
								<div class="input-group-prepend float-right">
									<div
										class="custom-control custom-switch d-flex align-items-center ml-2"
										style="min-width: fit-content">
										<input
											type="checkbox"
											class="custom-control-input"
											id="filterActive"
											name="filterActive"
											[checked]="filter.active"
											(click)="checkFilter()" />
										<label
											class="custom-control-label"
											for="filterActive"
											i18n="adm|@@adm.comMod.modal.filters.field.filterActive">
											Filter active
										</label>
									</div>
								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-body">
								<p
									class="font-weight-bold text-blue"
									i18n="adm|@@adm.comMod.modal.filters.text.workSchedule">
									Work schedule
								</p>
								<table class="table table-hover w-100">
									<thead>
										<tr>
											<th
												class="text-center"
												i18n="adm|@@adm.comMod.modal.filters.table.shedule.column.folder">
												Folder
											</th>
											<th
												class="text-center"
												i18n="adm|@@adm.comMod.modal.filters.table.shedule.column.always">
												Always
											</th>
											<th
												class="text-center"
												i18n="adm|@@adm.comMod.modal.filters.table.shedule.column.period">
												Period
											</th>
											<th
												class="text-center"
												i18n="adm|@@adm.comMod.modal.filters.table.shedule.column.days">
												Days of the week
											</th>
											<th
												class="text-center"
												i18n="adm|@@adm.comMod.modal.filters.table.shedule.column.hour">
												By the hour
											</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let rule of filter.foldersRules; let i = index">
											<td>
												<select
													class="form-control form-control-sm"
													[ngModel]="rule.folderId"
													[value]="rule.folderId"
													name="ruleFolderId_{{ rule.id }}"
													id="ruleFolderId_{{ rule.id }}"
													(input)="setSheduleFolder(i, +$event.target.value)"
													required>
													<option
														*ngFor="let folder of foldersList"
														[selected]="folder.id == rule.folderId"
														value="{{ folder.id }}">
														{{ folder.name }}
													</option>
												</select>
											</td>
											<td>
												<div class="form-check pb-2">
													<input
														name="ruleAlways_{{ rule.id }}"
														type="checkbox"
														id="ruleAlways_{{ rule.id }}"
														class="form-check-input m-auto"
														[(ngModel)]="rule.always" />
												</div>
											</td>
											<td>
												<div class="input-group d-flex align-items-center">
													<span i18n="adm|@@adm.comMod.modal.filters.text.dateFrom">From</span>
													<input
														type="date"
														class="form-control mx-1"
														name="ruleFolderStart_{{ rule.id }}"
														[ngModel]="rule.dtRangeStart | date : 'yyyy-MM-dd'"
														(blur)="rule.dtRangeStart = parseDate($event.target.value)"
														[disabled]="rule.always"
														[required]="rule.dtRangeStart == null && rule.dtRangeFinish != null" />
													<span i18n="adm|@@adm.comMod.modal.filters.text.dateto">to</span>
													<input
														type="date"
														class="form-control ml-1"
														name="ruleFolderFinish_{{ rule.id }}"
														[ngModel]="rule.dtRangeFinish | date : 'yyyy-MM-dd'"
														(blur)="rule.dtRangeFinish = parseDate($event.target.value)"
														[disabled]="rule.always"
														[required]="rule.dtRangeFinish == null && rule.dtRangeStart != null" />
												</div>
											</td>
											<td>
												<ng-select
													[items]="daysOfWeek"
													bindLabel="nameEn"
													style="max-width: 430px"
													bindValue="id"
													name="ruleDays_{{ rule.id }}"
													[multiple]="true"
													[hideSelected]="true"
													[closeOnSelect]="false"
													[clearSearchOnAdd]="true"
													[selectableGroup]="true"
													[selectableGroupAsModel]="false"
													[(ngModel)]="rule.daysOfWeek"
													[disabled]="rule.always">
													<ng-template
														ng-option-tmp
														let-item="item">
														{{ item.nameEn }}
													</ng-template>
												</ng-select>
											</td>
											<td>
												<div class="input-group d-flex align-items-center">
													<span i18n="adm|@@adm.comMod.modal.filters.text.timeFrom">From</span>
													<input
														type="time"
														class="form-control mx-1"
														[(ngModel)]="rule.timeStart"
														name="timeStart_{{ rule.id }}"
														[disabled]="rule.always"
														[required]="rule.timeStart === '' && rule.timeFinish !== ''" />
													<span i18n="adm|@@adm.comMod.modal.filters.text.timeTo">to</span>
													<input
														type="time"
														class="form-control ml-1"
														[(ngModel)]="rule.timeFinish"
														name="timeFinish_{{ rule.id }}"
														[disabled]="rule.always"
														[required]="rule.timeFinish === '' && rule.timeStart !== ''" />
												</div>
											</td>
											<td>
												<button
													type="button"
													class="btn btn-outline-danger btn-xs"
													(click)="conditionDelete(rule.id, 'deleteFoldersRule')">
													<i class="material-icons m-0">clear</i>
												</button>
											</td>
										</tr>
									</tbody>
								</table>
								<div class="row">
									<div class="col-12 mt-2">
										<button
											type="button"
											class="btn btn-sm btn-blue float-right"
											(click)="addFilterRule()">
											<i class="material-icons m-0">add</i
											><span i18n="adm|@@adm.comMod.modal.filters.button.addSchedule">Add schedule</span>
										</button>
									</div>
								</div>
							</div>
						</div>
						<p class="mt-3 mb-0">
							<span i18n="adm|@@adm.comMod.modal.filters.text.operationLogicOr"
								>Operation logic: (Group conditions OR)</span
							>&nbsp;<strong
								><span i18n="adm|@@adm.comMod.modal.filters.text.operationLogicGeneralAnd">AND</span></strong
							>&nbsp;<span i18n="adm|@@adm.comMod.modal.filters.text.operationLogicAnd">(Group condition AND).</span>
						</p>
						<p
							class="mb-3"
							i18n="adm|@@adm.comMod.modal.filters.text.canEmpty">
							The left or right side of the condition can be empty.
						</p>
						<div class="row">
							<div class="col-6">
								<div class="card">
									<div class="card-body">
										<p
											class="font-weight-bold text-blue"
											i18n="adm|@@adm.comMod.modal.filters.text.groupOr">
											Group condition OR
										</p>
										<table class="table table-hover w-100">
											<thead>
												<tr>
													<th i18n="adm|@@adm.comMod.modal.filters.table.condition.column.field">Fileld</th>
													<th
														class="text-center text-uppercase"
														i18n="adm|@@adm.comMod.modal.filters.table.condition.column.operator">
														Operator
													</th>
													<th i18n="adm|@@adm.comMod.modal.filters.table.condition.column.value">Value</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let condition of filter.conditionsOr; let i = index">
													<td>
														<select
															id="conditionsOrFieldId_{{ i }}"
															class="form-control form-control-sm"
															name="conditionsOrFieldId_{{ i }}"
															[value]="condition.fieldId"
															(input)="selectConditionField(condition, +$event.target.value)">
															<option
																value="0"
																disabled
																[selected]="condition.fieldId == 0"
																i18n="adm|@@adm.comMod.modal.filters.field.rule.fieldSelect">
																select...
															</option>
															<option
																*ngFor="let field of filterFileds"
																[value]="field.id"
																[selected]="field.id == condition.fieldId">
																{{ field.name }}
															</option>
														</select>
													</td>
													<td>
														<select
															*ngIf="condition.fieldId"
															id="conditionsOrOperationId_{{ i }}"
															class="form-control form-control-sm"
															[value]="condition.operationId"
															(change)="condition.operationId = +$event.target.value">
															<option
																value="0"
																disabled
																[selected]="condition.operationId == 0"
																i18n="adm|@@adm.comMod.modal.filters.field.rule.operationSelect">
																select...
															</option>
															<option
																*ngFor="let operation of getById(filterFileds, condition.fieldId).operations"
																[value]="operation.id"
																[selected]="operation.id == condition.operationId">
																{{ operation.name }}
															</option>
														</select>
														<select
															*ngIf="!condition.fieldId"
															id="conditionsOrOperationId_{{ i }}"
															class="form-control form-control-sm"
															name="conditionsOrOperationId_{{ i }}"
															[value]="condition.operationId"
															(change)="condition.operationId = +$event.target.value">
															<option
																value="0"
																disabled
																[selected]="condition.operationId == 0"
																i18n="adm|@@adm.comMod.modal.filters.field.rule.valueSelect">
																select...
															</option>
															<option
																*ngFor="let operation of filterOperations"
																[value]="operation.id"
																[selected]="operation.id == condition.operationId">
																{{ operation.name }}
															</option>
														</select>
													</td>
													<td>
														<div *ngIf="condition.fieldId">
															<div class="input-group">
																<input
																	*ngIf="getById(filterFileds, condition.fieldId).fieldTypeId == 1"
																	id="conditionsOrValue_{{ i }}"
																	type="text"
																	class="form-control form-control-sm"
																	name="conditionsOrValue_{{ i }}"
																	[(ngModel)]="condition.value"
																	value="{{ condition.value }}"
																	required />
																<input
																	*ngIf="getById(filterFileds, condition.fieldId).fieldTypeId == 4"
																	id="conditionsOrValue_{{ i }}"
																	type="number"
																	class="form-control form-control-sm"
																	name="conditionsOrValue_{{ i }}"
																	[(ngModel)]="condition.value"
																	value="{{ condition.value }}"
																	required />
																<input
																	*ngIf="getById(filterFileds, condition.fieldId).fieldTypeId == 2"
																	id="conditionsOrValue_{{ i }}"
																	type="date"
																	class="form-control form-control-sm"
																	name="conditionsOrValue_{{ i }}"
																	[ngModel]="condition.value | date : 'yyyy-MM-dd' : 'UTC+0'"
																	(blur)="condition.value = parseDate($event.target.value)"
																	required />
																<select
																	*ngIf="getById(filterFileds, condition.fieldId).fieldTypeId == 3"
																	id="conditionsOrValue_{{ i }}"
																	class="form-control form-control-sm"
																	name="conditionsOrValue_{{ i }}"
																	[value]="condition.value"
																	(input)="condition.value = $event.target.value"
																	required>
																	<option
																		*ngFor="let item of getById(filterFileds, condition.fieldId).enums"
																		[value]="item.id"
																		[selected]="item.id == condition.value">
																		{{ item.name[0] }}
																	</option>
																</select>
															</div>
														</div>
													</td>
													<td class="text-center">
														<button
															type="button"
															[disabled]="condition.fieldId !== 2 && condition.fieldId !== 7"
															class="btn btn-light-gray btn-xs btn-ico mr-2"
															(click)="copyAndTranslateCondition(condition, 'conditionsOr')"
															ngbTooltip="Copy and transliterate a row"
															i18n-ngbTooltip="adm|@@adm.comMod.modal.filters.copyAndTransliterate">
															<i class="material-icons m-0">transform</i>
														</button>
														<button
															type="button"
															class="btn btn-outline-danger btn-xs"
															(click)="conditionDelete(i, 'deleteConditionOr')">
															<i class="material-icons m-0">clear</i>
														</button>
													</td>
												</tr>
											</tbody>
										</table>
										<div class="row">
											<div class="col-12 mt-2">
												<button
													type="button"
													class="btn btn-sm btn-blue float-right"
													(click)="addCondition('conditionsOr')">
													<i class="material-icons m-0">add</i
													><span i18n="adm|@@adm.comMod.modal.filters.button.addCondition">Add condition</span>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-6">
								<div class="card">
									<div class="card-body">
										<p
											class="font-weight-bold text-blue"
											i18n="adm|@@adm.comMod.modal.filters.text.groupAnd">
											Group condition AND
										</p>
										<table class="table table-hover w-100">
											<thead>
												<tr>
													<th i18n="adm|@@adm.comMod.modal.filters.table.condition.column.field">Fileld</th>
													<th i18n="adm|@@adm.comMod.modal.filters.table.condition.column.operator">Operator</th>
													<th i18n="adm|@@adm.comMod.modal.filters.table.condition.column.value">Value</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let condition of filter.conditionsAnd; let i = index">
													<td>
														<select
															id="conditionsAndFieldId_{{ i }}"
															class="form-control form-control-sm"
															name="conditionsAndFieldId_{{ i }}"
															[value]="condition.fieldId"
															(input)="condition.fieldId = +$event.target.value">
															<option
																value="0"
																disabled
																[selected]="condition.fieldId == 0"
																i18n="adm|@@adm.comMod.modal.filters.field.rule.fieldSelect">
																select...
															</option>
															<option
																*ngFor="let field of filterFileds"
																[value]="field.id"
																[selected]="field.id == condition.fieldId">
																{{ field.name }}
															</option>
														</select>
													</td>
													<td>
														<select
															*ngIf="condition.fieldId"
															id="conditionsAndOperationId_{{ i }}"
															class="form-control form-control-sm"
															[value]="condition.operationId"
															(change)="condition.operationId = +$event.target.value">
															<option
																value="0"
																disabled
																[selected]="condition.operationId == 0"
																i18n="adm|@@adm.comMod.modal.filters.field.rule.operationSelect">
																select...
															</option>
															<option
																*ngFor="let operation of getById(filterFileds, condition.fieldId).operations"
																[value]="operation.id"
																[selected]="operation.id == condition.operationId">
																{{ operation.name }}
															</option>
														</select>
														<select
															*ngIf="!condition.fieldId"
															id="conditionsAndOperationId_{{ i }}"
															class="form-control form-control-sm"
															name="conditionsAndOperationId_{{ i }}"
															[value]="condition.operationId"
															(change)="condition.operationId = +$event.target.value">
															<option
																value="0"
																disabled
																[selected]="condition.operationId == 0"
																i18n="adm|@@adm.comMod.modal.filters.field.rule.valueSelect">
																select...
															</option>
															<option
																*ngFor="let operation of filterOperations"
																[value]="operation.id"
																[selected]="operation.id == condition.operationId">
																{{ operation.name }}
															</option>
														</select>
													</td>
													<td>
														<div *ngIf="condition.fieldId">
															<div class="input-group">
																<input
																	*ngIf="getById(filterFileds, condition.fieldId).fieldTypeId == 1"
																	id="conditionsAndValue_{{ i }}"
																	type="text"
																	class="form-control form-control-sm"
																	name="conditionsAndValue_{{ i }}"
																	[(ngModel)]="condition.value"
																	value="{{ condition.value }}"
																	required />
																<input
																	*ngIf="getById(filterFileds, condition.fieldId).fieldTypeId == 4"
																	id="conditionsAndValue_{{ i }}"
																	type="number"
																	class="form-control form-control-sm"
																	name="conditionsAndValue_{{ i }}"
																	[(ngModel)]="condition.value"
																	value="{{ condition.value }}"
																	required />
																<input
																	*ngIf="getById(filterFileds, condition.fieldId).fieldTypeId == 2"
																	id="conditionsAndValue_{{ i }}"
																	type="date"
																	class="form-control form-control-sm"
																	name="conditionsAndValue_{{ i }}"
																	[ngModel]="condition.value | date : 'yyyy-MM-dd' : 'UTC+0'"
																	(blur)="condition.value = parseDate($event.target.value)"
																	required />
																<select
																	*ngIf="getById(filterFileds, condition.fieldId).fieldTypeId == 3"
																	id="conditionsAndValue_{{ i }}"
																	class="form-control form-control-sm"
																	name="conditionsAndValue_{{ i }}"
																	[value]="condition.value"
																	(input)="condition.value = $event.target.value"
																	required>
																	<option
																		*ngFor="let item of getById(filterFileds, condition.fieldId).enums"
																		[value]="item.id"
																		[selected]="item.id == condition.value">
																		{{ item.name[0] }}
																	</option>
																</select>
															</div>
														</div>
													</td>
													<td class="text-center">
														<button
															type="button"
															[disabled]="condition.fieldId !== 2 && condition.fieldId !== 7"
															class="btn btn-light-gray btn-xs btn-ico mr-2"
															(click)="copyAndTranslateCondition(condition, 'conditionsAnd')"
															ngbTooltip="Copy and transliterate a row"
															i18n-ngbTooltip="adm|@@adm.comMod.modal.filters.copyAndTransliterate">
															<i class="material-icons m-0">transform</i>
														</button>
														<button
															type="button"
															class="btn btn-outline-danger btn-xs"
															(click)="conditionDelete(i, 'deleteConditionAnd')">
															<i class="material-icons m-0">clear</i>
														</button>
													</td>
												</tr>
											</tbody>
										</table>
										<div class="row">
											<div class="col-12 mt-2">
												<button
													type="button"
													class="btn btn-sm btn-blue float-right"
													(click)="addCondition('conditionsAnd')">
													<i class="material-icons m-0">add</i
													><span i18n="adm|@@adm.comMod.modal.filters.button.addCondition">Add condition</span>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<div *ngIf="activeState === 'action-append'">
							<button
								type="button"
								class="btn btn-sm btn-blue"
								(click)="addFilter()"
								i18n="adm|@@adm.comMod.modal.filters.button.append">
								Append
							</button>
						</div>
						<div *ngIf="activeState === 'action-update'">
							<button
								type="button"
								class="btn btn-sm btn-red"
								(click)="confirmAction(deleteFilter)"
								i18n="adm|@@adm.comMod.modal.filters.button.delete">
								Delete
							</button>
						</div>
						<div *ngIf="activeState === 'action-update'">
							<button
								type="button"
								class="btn btn-sm btn-blue"
								(click)="updateFilter(filter.id)"
								i18n="adm|@@adm.comMod.modal.filters.button.save">
								Save
							</button>
						</div>
						<button
							type="button"
							class="btn btn-sm btn-gray-blue-dark"
							data-dismiss="modal"
							i18n="adm|@@adm.comMod.modal.filters.button.close">
							Close
						</button>
					</div>
					<div
						*ngIf="errorMessage !== ''"
						class="ms-modal-message">
						<div class="modal-content w-50">
							<div
								class="modal-header"
								[ngClass]="{
									'bg-info': errorType === 'info',
									'bg-warning': errorType === 'warning',
									'bg-danger': errorType === 'error'
								}">
								<h5
									*ngIf="errorType === 'info'"
									i18n="adm|@@adm.comMod.modal.header.information"
									class="modal-title">
									Information
								</h5>
								<h5
									*ngIf="errorType === 'warning'"
									i18n="adm|@@adm.comMod.modal.header.warning"
									class="modal-title">
									Warning
								</h5>
								<h5
									*ngIf="errorType === 'error'"
									i18n="adm|@@adm.comMod.modal.header.error"
									class="modal-title">
									Error
								</h5>
								<button
									type="button"
									class="close"
									(click)="clearErrorMess()"
									aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div class="modal-body">
								<span style="white-space: pre-line">{{ errorMessage }}</span>
							</div>
							<div class="modal-footer">
								<button
									type="button"
									*ngIf="userAction === 'deleteItem'"
									class="btn btn-sm btn-blue"
									(click)="executableFunction(filter.id)"
									i18n="adm|@@adm.comMod.modal.filters.button.yes">
									Yes
								</button>
								<button
									type="button"
									*ngIf="userAction === 'deleteConditionOr'"
									class="btn btn-sm btn-blue"
									(click)="deleteElement(filter.conditionsOr, indexDelete, true)"
									i18n="adm|@@adm.comMod.modal.filters.button.yes">
									Yes
								</button>
								<button
									type="button"
									*ngIf="userAction === 'deleteConditionAnd'"
									class="btn btn-sm btn-blue"
									(click)="deleteElement(filter.conditionsAnd, indexDelete, true)"
									i18n="adm|@@adm.comMod.modal.filters.button.yes">
									Yes
								</button>
								<button
									type="button"
									*ngIf="userAction === 'deleteFoldersRule'"
									class="btn btn-sm btn-blue"
									(click)="deleteFilterRule()"
									i18n="adm|@@adm.comMod.modal.filters.button.yes">
									Yes
								</button>
								<button
									type="button"
									*ngIf="userAction !== ''"
									class="btn btn-sm btn-gray-blue-dark"
									(click)="clearErrorMess()"
									i18n="adm|@@adm.comMod.modal.filters.button.no">
									No
								</button>
								<button
									type="button"
									*ngIf="errorType === 'error'"
									class="btn btn-sm btn-gray-blue-dark"
									(click)="clearErrorMess()"
									i18n="adm|@@adm.comMod.modal.filters.button.ok">
									OK
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>

<!-- Modal - add folder -->
<div *ngIf="showTab('tab-folders')">
	<form #folderForm="ngForm">
		<div
			class="modal fade bd-example-modal-xl new-folder"
			data-backdrop="static"
			tabindex="-1"
			role="dialog"
			aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-xl">
				<div class="modal-content bg-light-gray">
					<div
						class="loading"
						*ngIf="loading">
						<div class="cssload-spin-box"></div>
					</div>
					<div class="modal-header">
						<h5
							*ngIf="activeState === 'action-append'"
							class="modal-title"
							id="exampleModalScrollableTitle">
							<span i18n="adm|@@adm.comMod.modal.folders.header.add">Add new folder</span>&nbsp;{{ letterFolder.name }}
						</h5>
						<h5
							*ngIf="activeState === 'action-update'"
							class="modal-title"
							id="exampleModalScrollableTitle">
							<span i18n="adm|@@adm.comMod.modal.folders.header.edit">Edit folder</span>&nbsp;{{ letterFolder.name }}
						</h5>
						<button
							#closebutton
							type="button"
							class="close"
							data-dismiss="modal"
							aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="row mb-3">
							<div class="col-4">
								<label
									for="FolderName"
									i18n="adm|@@adm.comMod.modal.folders.field.name">
									Name
								</label>
								<input
									id="FolderName"
									type="text"
									class="form-control form-control-sm"
									name="folderName"
									[(ngModel)]="letterFolder.name"
									#folderName="ngModel"
									maxlength="20"
									required />
								<div
									*ngIf="!folderName?.valid && (folderName?.dirty || folderName?.touched)"
									class="invalid-feedback d-block">
									<div *ngIf="folderName.errors.maxlength">
										<span i18n="adm|@@adm.comMod.modal.folders.field.name.err.nameMaxLength"
											>Name maximum length is</span
										>&nbsp;{{ folderName.errors.maxlength?.requiredLength }}
									</div>
									<div
										*ngIf="folderName.errors.required"
										i18n="adm|@@adm.comMod.modal.filters.err.nameReq">
										Name is required
									</div>
								</div>
							</div>
							<div class="col-4">
								<label i18n="adm|@@adm.comMod.modal.folders.field.dtStart"> Date range start </label>
								<input
									type="date"
									class="form-control"
									name="folderStart"
									[ngModel]="letterFolder.dtRangeStart | date : 'yyyy-MM-dd' : 'UTC+0'"
									(blur)="letterFolder.dtRangeStart = parseDate($event.target.value)"
									#folderStart="ngModel" />
								<div
									*ngIf="!folderStart?.valid && (folderStart?.dirty || folderStart?.touched)"
									class="invalid-feedback d-block">
									<div
										*ngIf="folderStart.errors.required"
										i18n="adm|@@adm.comMod.modal.filters.err.dtRangeStart">
										Date range start is required
									</div>
								</div>
							</div>
							<div class="col-4">
								<label i18n="adm|@@adm.comMod.modal.folders.field.dtFinish"> Date range finish </label>
								<input
									type="date"
									class="form-control"
									name="folderFinish"
									[ngModel]="letterFolder.dtRangeFinish | date : 'yyyy-MM-dd' : 'UTC+0'"
									(blur)="letterFolder.dtRangeFinish = parseDate($event.target.value)"
									#folderFinish="ngModel" />
								<div
									*ngIf="!folderFinish?.valid && (folderFinish?.dirty || folderFinish?.touched)"
									class="invalid-feedback d-block">
									<div
										*ngIf="folderFinish.errors.required"
										i18n="adm|@@adm.comMod.modal.filters.err.dtRangeFinish">
										Date range finish is required
									</div>
								</div>
							</div>
						</div>
						<div class="row mb-4">
							<div class="col-6">
								<label
									for="folderTime"
									i18n="adm|@@adm.comMod.modal.folders.field.archivingIn">
									Archiving incoming/outgoing in (min)
								</label>
								<input
									id="folderTime"
									type="number"
									class="form-control form-control-sm"
									name="folderTime"
									[(ngModel)]="letterFolder.time"
									#folderTime="ngModel"
									required />
								<div
									*ngIf="!folderTime?.valid && (folderTime?.dirty || folderTime?.touched)"
									class="invalid-feedback d-block">
									<div
										*ngIf="folderTime.errors.required"
										i18n="adm|@@adm.comMod.modal.filters.err.timeReq">
										Time is required
									</div>
								</div>
							</div>
							<div class="col-6">
								<label>
									<input
										type="checkbox"
										class="mr-2"
										name="deleteMessagesFlag"
										id="deleteMessagesFlag"
										[(ngModel)]="deleteMessagesFlag" />
									<span i18n="adm|@@adm.comMod.modal.folders.field.deleteAfter"> Delete messages after (days) </span>
								</label>
								<input
									id="folderMaxAge"
									type="number"
									class="form-control form-control-sm"
									name="folderMaxAge"
									[(ngModel)]="letterFolder.maxAge"
									#folderMaxAge="ngModel"
									[disabled]="!deleteMessagesFlag"
									[required]="deleteMessagesFlag" />
								<div
									*ngIf="!folderMaxAge?.valid && (folderMaxAge?.dirty || folderMaxAge?.touched)"
									class="invalid-feedback d-block">
									<div
										*ngIf="deleteMessagesFlag && folderMaxAge.errors.required"
										i18n="adm|@@adm.comMod.modal.filters.err.timeReq">
										Field is required
									</div>
								</div>
							</div>
							<!--<div class="col-4 pt-2">
                <label class="mt-4">
                  <input type="checkbox" class="mr-2" name="autoReplySS" id="autoReplySS" [(ngModel)]="letterFolder.autoReplySS">
                  <span i18n="adm|@@adm.comMod.modal.folders.field.autoReplySS">
                    Automatic response to CC
                  </span>
                </label>
              </div>-->
						</div>
						<div class="card mb-3">
							<div class="card-body">
								<p
									class="font-weight-bold text-blue"
									i18n="adm|@@adm.comMod.modal.folders.field.defaultSettings">
									Default settings for new message
								</p>
								<div class="row mb-3">
									<div class="col-4">
										<label i18n="adm|@@adm.comMod.modal.folders.field.channel"> Channel </label>
										<select
											class="form-control form-control-sm"
											[ngModel]="letterFolder.channelId"
											(input)="letterFolder.channelId = +$event.target.value"
											name="channelId">
											<option value="null"></option>
											<option
												*ngFor="let channel of channels"
												[selected]="channel.id == letterFolder.channelId"
												value="{{ channel.id }}">
												{{ channel.name }}
											</option>
										</select>
									</div>
									<div class="col-4">
										<label i18n="adm|@@adm.comMod.modal.folders.field.language"> Language </label>
										<select
											class="form-control form-control-sm"
											[ngModel]="letterFolder.defaultAftnLang"
											(change)="letterFolder.defaultAftnLang = +$event.target.value"
											name="defaultAftnLang">
											<option
												*ngFor="let lang of defaultTelegramLanguageBindingsList"
												[selected]="lang.value == letterFolder.defaultAftnLang"
												value="{{ lang.value }}">
												{{ lang.label }}
											</option>
										</select>
									</div>
									<div class="col-4">
										<label i18n="adm|@@adm.comMod.modal.folders.field.urgencyAftn"> Urgency AFTN </label>
										<select
											id="defaultAftnUrgencyId"
											name="defaultAftnUrgencyId"
											class="form-control form-control-sm"
											[ngModel]="letterFolder.defaultAftnUrgencyId"
											(input)="letterFolder.defaultAftnUrgencyId = +$event.target.value">
											<option value=""></option>
											<option
												*ngFor="let urgency of urgenciesForChannel[1]"
												[value]="urgency.id"
												[selected]="urgency.id == letterFolder.defaultAftnUrgencyId">
												{{ urgency.nameLocal || urgency.nameEnglish }}
											</option>
										</select>
									</div>
								</div>
								<div class="row mb-3">
									<div class="col-4">
										<label i18n="adm|@@adm.comMod.modal.folders.field.senderAftn"> Sender AFTN </label>
										<select
											id="aftnSenderId"
											name="aftnSenderId"
											class="form-control form-control-sm"
											[ngModel]="letterFolder.aftnSenderId"
											(input)="letterFolder.aftnSenderId = +$event.target.value"
											name="aftnSenderId">
											<option value=""></option>
											<option
												*ngFor="let sender of sendersFiltered['aftn']"
												[value]="sender.id"
												[selected]="sender.id == letterFolder.aftnSenderId">
												{{ sender.addressEnglish }}
											</option>
										</select>
									</div>
									<div class="col-4">
										<label i18n="adm|@@adm.comMod.modal.folders.field.senderEmail"> Sender email </label>
										<select
											id="emailSenderId"
											name="emailSenderId"
											class="form-control form-control-sm"
											[ngModel]="letterFolder.emailSenderId"
											(input)="letterFolder.emailSenderId = +$event.target.value"
											name="emailSenderId">
											<option value=""></option>
											<option
												*ngFor="let sender of sendersFiltered['email']"
												[value]="sender.id"
												[selected]="sender.id == letterFolder.emailSenderId">
												{{ sender.addressEnglish }}
											</option>
										</select>
									</div>
									<div class="col-4">
										<label i18n="adm|@@adm.comMod.modal.folders.field.senderSitatex"> Sender SITATEX </label>
										<select
											id="sitatexSenderId"
											name="sitatexSenderId"
											class="form-control form-control-sm"
											[ngModel]="letterFolder.sitatexSenderId"
											(input)="letterFolder.sitatexSenderId = +$event.target.value"
											name="sitatexSenderId">
											<option value=""></option>
											<option
												*ngFor="let sender of sendersFiltered['sitatex']"
												[value]="sender.id"
												[selected]="sender.id == letterFolder.sitatexSenderId">
												{{ sender.addressEnglish }}
											</option>
										</select>
									</div>
								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-body">
								<p
									class="font-weight-bold text-blue"
									i18n="adm|@@adm.comMod.modal.folders.field.archivingSettings">
									Archiving settings for individual message types
								</p>
								<table class="table table-hover w-100">
									<thead class="text-center">
										<tr>
											<th width="10%">#</th>
											<th
												width="30%"
												i18n="adm|@@adm.comMod.modal.folders.table.type">
												Type
											</th>
											<th
												width="50%"
												i18n="adm|@@adm.comMod.modal.folders.table.time">
												Archiving incoming/outgoing in (min)
											</th>
											<th
												width="10%"
												class="text-center"
												i18n="adm|@@adm.comMod.modal.folders.table.delete">
												Delete
											</th>
										</tr>
									</thead>
									<tbody class="bg-white">
										<tr
											*ngFor="let rule of letterFolder.archiveRules; let i = index"
											id="archiveRules">
											<td class="text-center">{{ i + 1 }}</td>
											<td>
												<ng-select
													[items]="filteredMessageTypes"
													bindLabel="nameEnglish"
													bindValue="id"
													name="ruleType_{{ rule.id }}"
													id="ruleType_{{ rule.id }}"
													[multiple]="true"
													[hideSelected]="true"
													[closeOnSelect]="false"
													[clearSearchOnAdd]="true"
													[selectableGroup]="true"
													[selectableGroupAsModel]="false"
													[ngModel]="rule.messageTypeIds"
													(add)="onAddType($event, i)"
													(remove)="onRemoveType($event, i)"
													(open)="onOpenType()"
													required>
													<ng-template
														ng-option-tmp
														let-item="item">
														{{ item.nameEnglish }}
													</ng-template>
													<ng-template
														ng-tag-tmp
														let-search="searchTerm">
														<b>
															<span i18n="adm|@@adm.comMod.modal.folders.row.type">Type</span> </b
														>: {{ search }}
													</ng-template>
												</ng-select>
											</td>
											<td>
												<input
													id="ruleTime_{{ rule.id }}"
													type="number"
													class="form-control form-control-sm"
													name="ruleTime_{{ rule.id }}"
													[(ngModel)]="rule.time"
													required />
											</td>
											<td class="text-center">
												<button
													type="button"
													class="btn btn-outline-danger btn-sm"
													(click)="conditionDelete(rule.id)">
													<i class="material-icons m-0">clear</i>
												</button>
											</td>
										</tr>
									</tbody>
								</table>
								<div class="row">
									<div class="col-12 mt-2">
										<button
											type="button"
											class="btn btn-sm btn-blue float-right"
											(click)="addRule()">
											<i class="material-icons m-0">add</i
											><span i18n="adm|@@adm.comMod.modal.filters.button.addRule">Add condition</span>
										</button>
									</div>
								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-body">
								<p
									class="font-weight-bold text-blue"
									i18n="adm|@@adm.comMod.modal.folders.field.forwardingRules">
									Forwarding rules
								</p>
								<table class="table table-hover w-100">
									<thead class="text-center">
										<tr>
											<th width="10%">#</th>
											<th
												width="20%"
												i18n="adm|@@adm.comMod.modal.folders.table.channel">
												Channel
											</th>
											<th
												width="20%"
												i18n="adm|@@adm.comMod.modal.folders.table.sender">
												Sender
											</th>
											<th
												width="40%"
												i18n="adm|@@adm.comMod.modal.folders.table.recipient">
												Recipient
											</th>
											<th
												width="40%"
												i18n="adm@@adm.comMod.modal.folders.table.textOnly">
												Text only
											</th>
											<th
												width="10%"
												class="text-center"
												i18n="adm|@@adm.comMod.modal.folders.table.delete">
												Delete
											</th>
										</tr>
									</thead>
									<tbody class="bg-white">
										<tr *ngFor="let rule of letterFolder.forwardingRules; let i = index">
											<td class="text-center">{{ i + 1 }}</td>
											<td>
												<ng-select
													[items]="channels"
													bindLabel="name"
													bindValue="id"
													name="ruleChannelId_{{ i }}"
													id="ruleChannelId_{{ i }}"
													[hideSelected]="true"
													[closeOnSelect]="true"
													[clearSearchOnAdd]="true"
													[selectableGroup]="true"
													[selectableGroupAsModel]="false"
													[(ngModel)]="rule.channelId"
													required>
													<ng-template
														ng-option-tmp
														let-item="item">
														{{ item.name }}
													</ng-template>
												</ng-select>
											</td>
											<td>
												<div [ngSwitch]="rule.channelId.toString()">
													<ng-template ngSwitchCase="1">
														<ng-select
															[items]="sendersFiltered['aftn']"
															bindLabel="addressEnglish"
															bindValue="id"
															name="ruleSenderId_{{ i }}"
															id="ruleSenderId_{{ i }}"
															[(ngModel)]="rule.senderId"
															required>
														</ng-select>
													</ng-template>
													<ng-template ngSwitchCase="2">
														<ng-select
															[items]="sendersFiltered['sitatex']"
															bindLabel="addressEnglish"
															bindValue="id"
															name="ruleSenderId_{{ i }}"
															id="ruleSenderId_{{ i }}"
															[(ngModel)]="rule.senderId"
															required>
														</ng-select>
													</ng-template>
													<ng-template ngSwitchCase="3">
														<ng-select
															[items]="sendersFiltered['email']"
															bindLabel="addressEnglish"
															bindValue="id"
															name="ruleSenderId_{{ i }}"
															id="ruleSenderId_{{ rule.id }}"
															[(ngModel)]="rule.senderId"
															required>
														</ng-select>
													</ng-template>
													<ng-template ngSwitchDefault>{{ rule.channelId }}</ng-template>
												</div>
											</td>
											<td class="text-center">
												<ng-select
													class="custom-sm p-0"
													[items]="rule.recipientList"
													[multiple]="true"
													[addTag]="addTagFn"
													bindLabel="name"
													name="ruleRecipient_{{ i }}"
													id="ruleRecipient_{{ i }}"
													[(ngModel)]="rule.recipientList"
													[isOpen]="false">
													<ng-template
														ng-tag-tmp
														let-search="searchTerm">
														<b
															i18n="
																cmn|Main search filter - aftn number field
																add@@cmn.main.search.filter.field.aftnNumberAdd"
															>Add</b
														>: {{ search }}
													</ng-template>
												</ng-select>
												<div
													*ngIf="rule.error"
													class="invalid-feedback d-block float-left">
													{{ rule.error }}
												</div>
											</td>
                      <td class="text-center">
												<input id="textOnly_{{ i }}"
                          type="checkbox"
                          name="withHeaders_{{ i }}"
                          [checked]="getTextForwardingRulesForFolder(i)"
                          (change)="setTextForwardingRulesForFolder(i)">
											</td>
											<td class="text-center">
												<button
													type="button"
													class="btn btn-outline-danger btn-sm"
													(click)="conditionDelete(i, 'deleteForwardingRule')">
													<i class="material-icons m-0">clear</i>
												</button>
											</td>
										</tr>
									</tbody>
								</table>
								<div class="row">
									<div class="col-12 mt-2">
										<button
											type="button"
											class="btn btn-sm btn-blue float-right"
											(click)="addForwardingRule()">
											<i class="material-icons m-0">add</i
											><span i18n="adm|@@adm.comMod.modal.filters.button.addRule"> Add rule </span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<div *ngIf="activeState === 'action-append'">
							<button
								type="button"
								class="btn btn-sm btn-blue"
								(click)="addFolder()"
								i18n="adm|@@adm.comMod.modal.folders.button.create">
								Create
							</button>
						</div>
						<div *ngIf="activeState === 'action-update'">
							<button
								type="button"
								class="btn btn-sm btn-red"
								(click)="confirmAction(deleteFolder)"
								i18n="adm|@@adm.comMod.modal.folders.button.delete">
								Delete
							</button>
						</div>
						<div *ngIf="activeState === 'action-update'">
							<button
								type="button"
								class="btn btn-sm btn-blue"
								(click)="updateFolder(letterFolder.id)"
								i18n="adm|@@adm.comMod.modal.folders.button.save">
								Save
							</button>
						</div>
						<button
							type="button"
							class="btn btn-sm btn-gray-blue-dark"
							data-dismiss="modal"
							i18n="adm|@@adm.comMod.modal.folders.button.close">
							Close
						</button>
					</div>
					<div
						*ngIf="errorMessage !== ''"
						class="ms-modal-message">
						<div class="modal-content w-50">
							<div
								class="modal-header"
								[ngClass]="{
									'bg-info': errorType === 'info',
									'bg-warning': errorType === 'warning',
									'bg-danger': errorType === 'error'
								}">
								<h5
									*ngIf="errorType === 'info'"
									i18n="adm|@@adm.comMod.modal.header.information"
									class="modal-title">
									Information
								</h5>
								<h5
									*ngIf="errorType === 'warning'"
									i18n="adm|@@adm.comMod.modal.header.warning"
									class="modal-title">
									Warning
								</h5>
								<h5
									*ngIf="errorType === 'error'"
									i18n="adm|@@adm.comMod.modal.header.error"
									class="modal-title">
									Error
								</h5>
								<button
									type="button"
									class="close"
									(click)="clearErrorMess()"
									aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div class="modal-body">
								<span style="white-space: pre-line">{{ errorMessage }}</span>
							</div>
							<div class="modal-footer">
								<button
									type="button"
									*ngIf="userAction === 'repeating_rule'"
									class="btn btn-sm btn-blue"
									(click)="clearErrorMess()"
									i18n="adm|@@adm.comMod.modal.filters.button.edit">
									Edit
								</button>
								<button
									type="button"
									class="btn btn-sm btn-gray-blue-dark"
									*ngIf="userAction === 'repeating_rule'"
									(click)="closeWithoutSaving()"
									i18n="ref|Modal terminal error - button close@@ref.main.modal.terminal.errMes.button.close">
									Close without saving
								</button>
								<button
									type="button"
									*ngIf="userAction === 'deleteItem'"
									class="btn btn-sm btn-blue"
									(click)="executableFunction(letterFolder.id)"
									i18n="adm|@@adm.comMod.modal.folders.button.yes">
									Yes
								</button>
								<button
									type="button"
									*ngIf="userAction === 'delete'"
									class="btn btn-sm btn-blue"
									(click)="deleteFolderRule()"
									i18n="adm|@@adm.comMod.modal.filters.button.yes">
									Yes
								</button>
								<button
									type="button"
									*ngIf="userAction === 'deleteForwardingRule'"
									class="btn btn-sm btn-blue"
									(click)="deleteForwardingRule()"
									i18n="adm|@@adm.comMod.modal.filters.button.yes">
									Yes
								</button>
								<button
									type="button"
									*ngIf="
										userAction === 'delete' ||
										userAction === 'deleteFilter' ||
										userAction === 'deleteItem' ||
										userAction === 'deleteForwardingRule'
									"
									class="btn btn-sm btn-gray-blue-dark"
									(click)="clearErrorMess()"
									i18n="adm|@@adm.comMod.modal.filters.button.no">
									No
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>

<!-- Modal Templates -->
<!--<div *ngIf="showTab('tab-templates')">
  <form #templatesForm="ngForm">
    <div class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
      aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content bg-light-gray">
          <div class="loading" *ngIf="loading">
            <div class="cssload-spin-box"></div>
          </div>
          <div class="modal-header">
            <h5 *ngIf="activeState=='action-append'"
                class="modal-title"
                i18n="adm|@@adm.comMod.modal.templates.header.add">
              Template append {{ template.name }}
            </h5>
            <h5 *ngIf="activeState=='action-update'"
                class="modal-title"
                i18n="adm|@@adm.comMod.modal.templates.header.edit">
              Edit template {{ template.name }}
            </h5>
            <button #closebutton type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mb-4">
              <div class="col-4">
                <div class="mb-3">
                  <label i18n="adm|@@adm.comMod.modal.templates.field.name">
                    Template name
                  </label>
                  <input type="text"
                        id="templateName"
                        #templateName="ngModel"
                        class="form-control form-control-sm"
                        name="templateName"
                        [(ngModel)]="template.name"
                        value="{{ template.name }}"
                        required>
                    <div *ngIf="!templateName?.valid && (templateName?.dirty || templateName?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="templateName.errors.required"
                           i18n="adm|@@adm.comMod.modal.templates.err.nameReq">
                        Template name is required
                      </div>
                    </div>
                </div>
                <div class="mb-3">
                  <label i18n="adm|@@adm.comMod.modal.templates.field.dtRangeStart">
                    Date range start
                  </label>
                  <input type="date"
                         class="form-control"
                         name="templateStart"
                         #templateStart="ngModel"
                         [ngModel]="template.dtRangeStart | date: 'yyyy-MM-dd' : 'UTC+0'"
                         (blur)="template.dtRangeStart = parseDate($event.target.value)"
                         required>
                  <div *ngIf="!templateStart?.valid && (templateStart?.dirty || templateStart?.touched)"
                      class="invalid-feedback d-block">
                    <div *ngIf="templateStart.errors.required"
                          i18n="adm|@@adm.comMod.modal.filters.err.dtRangeStartReq">
                      Date range start is required
                    </div>
                  </div>
                </div>
                <div>
                  <label i18n="adm|@@adm.comMod.modal.templates.field.dtRangeFinish">
                    Date range finish
                  </label>
                  <input type="date"
                        class="form-control"
                        name="templateFinish"
                        #templateFinish="ngModel"
                        [ngModel]="template.dtRangeFinish | date: 'yyyy-MM-dd' : 'UTC+0'"
                        (blur)="template.dtRangeFinish = parseDate($event.target.value)"
                        required>
                  <div *ngIf="!templateFinish?.valid && (templateFinish?.dirty || templateFinish?.touched)"
                      class="invalid-feedback d-block">
                    <div *ngIf="templateFinish.errors.required"
                          i18n="adm|@@adm.comMod.modal.filters.err.dtRangeFinishReq">
                      Date range finish is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-8">
                <label i18n="adm|@@adm.comMod.modal.templates.field.templateDescription">
                  Template description
                </label>
                <textarea id="templateDescription" type="text" class="form-control" name="templateDescription"
                  [(ngModel)]="template.description" cols="30" rows="9" style="height: 201px;">
                        </textarea>
              </div>
            </div>
            <hr>
            <p class="font-weight-bold font-blue"
               i18n="adm|@@adm.comMod.modal.templates.field.parts">
              Parts
            </p>
            <div class="message-template-wrap">
              <div class="row">
                <div class="col-8 message-template">
                  <div class="input-group" *ngIf="template.parts.length>0">
                    <div *ngFor="let part of template.parts let i = index">
                      <span *ngIf="part.type=='static' || part.type==''" id="part_{{i}}" class="input-template disabled"
                        [ngClass]="{'active': selectedTemplateInput == i}"
                        (click)="selectTemplateInput(i)">{{part.value}}</span>
                      <span *ngIf="part.type=='dynamic' && part.format=='date'" id="part_{{i}}" class="input-template"
                        [ngClass]="{'active': selectedTemplateInput == i}" [style.width.px]="part.dateFormat.length*15"
                        (click)="selectTemplateInput(i)"></span>
                      <span *ngIf="part.type=='dynamic' && part.format!='date'" id="part_{{i}}" class="input-template"
                        [ngClass]="{'active': selectedTemplateInput == i}" [style.width.px]="part.maxLength*15"
                        (click)="selectTemplateInput(i)"></span>
                    </div>
                  </div>
                </div>
                <div class="col-4 border-left">
                  <div style="min-height: 195px;" *ngIf="selectedTemplateInput!=null">
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <span class="input-group-text font-small"
                              i18n="adm|@@adm.comMod.modal.templates.field.type">
                          Type
                        </span>
                      </div>
                      <select id="partType" class="form-control form-control-sm" name="partType"
                        [(ngModel)]="template.parts[selectedTemplateInput].type">
                        <option value=""
                                [selected]="template.parts[selectedTemplateInput].type == ''" disabled
                                i18n="adm|@@adm.comMod.modal.templates.field.typeSelect">
                          Select type
                        </option>
                        <option value="static"
                                [selected]="template.parts[selectedTemplateInput].type == 'static'"
                                i18n="adm|@@adm.comMod.modal.templates.field.typeStatic">
                          Static</option>
                        <option value="dynamic"
                                [selected]="template.parts[selectedTemplateInput].type == 'dynamic'"
                                i18n="adm|@@adm.comMod.modal.templates.field.typeDynamic">
                          Dynamic</option>
                      </select>
                    </div>
                    <div class="input-group mb-2" *ngIf="template.parts[selectedTemplateInput].type == 'static' ||
                                template.parts[selectedTemplateInput].type == ''; else dynamic">
                      <div class="input-group-prepend">
                        <span class="input-group-text font-small"
                              i18n="adm|@@adm.comMod.modal.templates.field.value">
                          Value
                        </span>
                      </div>
                      <input id="partValue" type="text" class="form-control form-control-sm" name="partValue"
                        [(ngModel)]="template.parts[selectedTemplateInput].value"
                        value="{{ template.parts[selectedTemplateInput].value }}" placeholder="Value"
                        [disabled]="template.parts[selectedTemplateInput].type == ''"
                        i18n-placeholder="adm|@@adm.comMod.modal.templates.field.valuePlaceholder">
                    </div>
                    <ng-template #dynamic>
                      <div class="input-group mb-2">
                        <div class="input-group-prepend">
                          <span class="input-group-text font-small"
                                i18n="adm|@@adm.comMod.modal.templates.field.format">
                            Format
                          </span>
                        </div>
                        <select id="partFormat" class="form-control form-control-sm" name="partFormat"
                          [(ngModel)]="template.parts[selectedTemplateInput].format">
                          <option value=""
                                  [selected]="template.parts[selectedTemplateInput].format == ''" disabled
                                  i18n="adm|@@adm.comMod.modal.templates.field.selectFormat">
                            Select format
                          </option>
                          <option value="string"
                                  [selected]="template.parts[selectedTemplateInput].format == 'string'"
                                  i18n="adm|@@adm.comMod.modal.templates.field.formatString">
                            String
                          </option>
                          <option value="number"
                                  [selected]="template.parts[selectedTemplateInput].format == 'number'"
                                  i18n="adm|@@adm.comMod.modal.templates.field.formatNumber">
                            Number
                          </option>
                          <option value="date"
                                  [selected]="template.parts[selectedTemplateInput].format == 'date'"
                                  i18n="adm|@@adm.comMod.modal.templates.field.formatDater">
                            Date
                          </option>
                        </select>
                      </div>
                      <div class="input-group mb-2" *ngIf="template.parts[selectedTemplateInput].format != 'date'">
                        <div class="input-group-prepend">
                          <span class="input-group-text font-small"
                                i18n="adm|@@adm.comMod.modal.templates.field.minLength">
                            Min Length
                          </span>
                        </div>
                        <input type="number" class="form-control" name="minLength"
                          [(ngModel)]="template.parts[selectedTemplateInput].minLength">
                        <div class="input-group-prepend ml-2">
                          <span class="input-group-text font-small"
                                i18n="adm|@@adm.comMod.modal.templates.field.maxLength">
                            Max Length
                          </span>
                        </div>
                        <input type="number" class="form-control" name="maxLength"
                          [(ngModel)]="template.parts[selectedTemplateInput].maxLength">
                      </div>
                      <div class="input-group mb-2" *ngIf="template.parts[selectedTemplateInput].format == 'date'">
                        <div class="input-group-prepend">
                          <span class="input-group-text font-small"
                                i18n="adm|@@adm.comMod.modal.templates.field.dateFormat">
                            Date format
                          </span>
                        </div>
                        <input type="text" class="form-control" value="{{template.parts[selectedTemplateInput].dateFormat}}"
                          name="dateFormat" id="dateFormat" [(ngModel)]="template.parts[selectedTemplateInput].dateFormat">
                        <span class="prompt"
                              placement="left"
                              ngbTooltip="HH - hours 24 format, hh - hours 12 format, mm - minutes, dd - day. Eg: HHmm"
                              i18n-ngbTooltip="adm|@@adm.comMod.modal.templates.field.dateFormatHelp">
                          ?
                        </span>
                      </div>
                      <div class="input-group mb-2">
                        <div class="input-group-prepend">
                          <span class="input-group-text font-small"
                                i18n="adm|@@adm.comMod.modal.templates.field.hint">
                            Hint
                          </span>
                        </div>
                        <input type="text" class="form-control" name="hint" [(ngModel)]="template.parts[selectedTemplateInput].hint">
                      </div>
                      <div class="input-group mb-2 align-items-center">
                        <input type="checkbox" name="partRequired" id="partRequired"
                          [(ngModel)]="template.parts[selectedTemplateInput].required"
                          [checked]="template.parts[selectedTemplateInput].required">
                        <label for="partRequired"
                               class="mb-0 ml-2"
                               i18n="adm|@@adm.comMod.modal.templates.field.required">
                          Required
                        </label>
                      </div>
                    </ng-template>
                  </div>
                  <div>
                    <button type="button"
                            class="btn btn-sm btn-blue float-right"
                            (click)="addPart()"
                            i18n="adm|@@adm.comMod.modal.templates.button.addPart">
                      + Add part
                    </button>
                    <button *ngIf="selectedTemplateInput!=null"
                            class="btn btn-sm btn-red float-right mr-2"
                            (click)="attemptDelete(selectedTemplateInput)"
                            i18n="adm|@@adm.comMod.modal.templates.button.deletePart">
                      Delete part
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button"
                    *ngIf="activeState=='action-append'"
                    class="btn btn-sm btn-blue"
                    (click)="addTemplate()"
                    i18n="adm|@@adm.comMod.modal.templates.button.append">
              Append
            </button>
            <button type="button"
                    *ngIf="activeState=='action-update'"
                    class="btn btn-sm btn-red"
                    (click)="confirmAction(deleteTemplate)"
                    i18n="adm|@@adm.comMod.modal.templates.button.delete">
              Delete
            </button>
            <button type="button"
                    *ngIf="activeState=='action-update'"
                    class="btn btn-sm btn-blue"
                    (click)="updateTemplate(template.id)"
                    i18n="adm|@@adm.comMod.modal.templates.button.save">
              Save
            </button>
            <button type="button"
                    class="btn btn-sm btn-gray-blue-dark"
                    data-dismiss="modal"
                    i18n="adm|@@adm.comMod.modal.templates.button.close">
              Close
            </button>
          </div>
          <div *ngIf="errorMessage !== ''" class="ms-modal-message">
            <div class="modal-content w-50">
              <div class="modal-header" [ngClass]="{'bg-info': errorType == 'info',
                                                    'bg-warning': errorType == 'warning',
                                                    'bg-danger': errorType == 'error'}">
                <h5 *ngIf="errorType == 'info'" class="modal-title">Information</h5>
                <h5 *ngIf="errorType == 'warning'" class="modal-title">Warning</h5>
                <h5 *ngIf="errorType == 'error'" class="modal-title">Error</h5>
                <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                {{ errorMessage }}
              </div>
              <div class="modal-footer">
                <button type="button"
                        *ngIf="userAction == 'delete'"
                        class="btn btn-sm btn-blue"
                        (click)="deleteElement(template.parts, indexDelete)"
                        i18n="adm|@@adm.comMod.modal.templates.button.deleteYes">
                  Yes
                </button>
                <button type="button"
                        *ngIf="userAction == 'deleteItem'"
                        class="btn btn-sm btn-blue"
                        (click)="executableFunction(template.id)"
                        i18n="adm|@@adm.comMod.modal.templates.button.yes">
                  Yes
                </button>
                <button type="button"
                        *ngIf="userAction == 'delete' || userAction == 'deleteItem'"
                        class="btn btn-sm btn-gray-blue-dark"
                        (click)="clearErrorMess()"
                        i18n="adm|@@adm.comMod.modal.templates.button.deleteNo">
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>-->

<!-- Modal Contacts -->
<div *ngIf="showTab('tab-contacts')">
	<form #contactsForm="ngForm">
		<div
			class="modal fade bd-example-modal-xl"
			data-backdrop="static"
			tabindex="-1"
			role="dialog"
			aria-labelledby="myExtraLargeModalLabel"
			aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-xl">
				<div class="modal-content bg-light-gray">
					<div
						class="loading"
						*ngIf="loading">
						<div class="cssload-spin-box"></div>
					</div>
					<div class="modal-header">
						<h5
							*ngIf="activeState === 'action-append'"
							class="modal-title">
							<span i18n="adm|@@adm.comMod.modal.contacts.header.add">Contact append</span>&nbsp;{{ contact.name }}
						</h5>
						<h5
							*ngIf="activeState === 'action-update'"
							class="modal-title">
							<span i18n="adm|@@adm.comMod.modal.contacts.header.edit">Edit contact</span>&nbsp;{{ contact.name }}
						</h5>
						<button
							#closebutton
							type="button"
							class="close"
							data-dismiss="modal"
							aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="row mb-4">
							<div class="col-3">
								<label i18n="adm|@@adm.comMod.modal.contacts.field.name"> Contact name </label>
								<input
									id="contactName"
									type="text"
									class="form-control form-control-sm"
									name="contactName"
									#contactName="ngModel"
									[(ngModel)]="contact.name"
									value="{{ contact.name }}"
									required />
								<div
									*ngIf="!contactName?.valid && (contactName?.dirty || contactName?.touched)"
									class="invalid-feedback d-block">
									<div
										*ngIf="contactName.errors.required"
										i18n="adm|@@adm.comMod.modal.filters.err.nameReq">
										Name is required
									</div>
								</div>
							</div>
							<div class="col-3">
								<label i18n="adm|@@adm.comMod.modal.contacts.field.channel"> Channel </label>
								<ng-select
									class="form-control custom p-0"
									[items]="channels"
									bindLabel="name"
									bindValue="id"
									[(ngModel)]="contact.channelId"
									name="channelId"
									#channelId="ngModel"
									required>
								</ng-select>
								<div
									*ngIf="!channelId?.valid && (channelId?.dirty || channelId?.touched)"
									class="invalid-feedback d-block">
									<div
										*ngIf="channelId.errors.required"
										i18n="adm|@@adm.comMod.modal.filters.err.channelReq">
										Channel is required
									</div>
								</div>
							</div>
							<div class="col-3">
								<label
									for="contactStart"
									i18n="adm|@@adm.comMod.modal.contacts.field.dtRangeStart">
									Date range start
								</label>
								<input
									type="date"
									class="form-control"
									name="contactStart"
									id="contactStart"
									#contactStart="ngModel"
									[ngModel]="contact.dtRangeStart | date : 'yyyy-MM-dd' : 'UTC+0'"
									(blur)="contact.dtRangeStart = parseDate($event.target.value)"
									required />
								<div
									*ngIf="!contactStart?.valid && (contactStart?.dirty || contactStart?.touched)"
									class="invalid-feedback d-block">
									<div
										*ngIf="contactStart.errors.required"
										i18n="adm|@@adm.comMod.modal.filters.err.contactStartReq">
										Date range start is required
									</div>
								</div>
							</div>
							<div class="col-3">
								<label
									for="contactFinish"
									i18n="adm|@@adm.comMod.modal.contacts.field.dtRangeFinish">
									Date range finish
								</label>
								<input
									type="date"
									class="form-control"
									name="contactFinish"
									id="contactFinish"
									#contactFinish="ngModel"
									[ngModel]="contact.dtRangeFinish | date : 'yyyy-MM-dd' : 'UTC+0'"
									(blur)="contact.dtRangeFinish = parseDate($event.target.value)"
									required />
								<div
									*ngIf="!contactFinish?.valid && (contactFinish?.dirty || contactFinish?.touched)"
									class="invalid-feedback d-block">
									<div
										*ngIf="contactFinish.errors.required"
										i18n="adm|@@adm.comMod.modal.filters.err.contactFinishReq">
										Date range finish is required
									</div>
								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-body">
								<p
									class="font-weight-bold text-blue"
									i18n="adm|@@adm.comMod.modal.contacts.field.addressed">
									Addresses
								</p>
								<div class="row">
									<div
										class="col-4"
										*ngFor="let address of contact.addresses; let i = index; trackBy: trackByFn">
										<div class="input-group">
											<ng-container *ngIf="contact.channelId == 1; else notAFTN">
												<div class="input-group mb-2">
													<input
														id="address_{{ i }}"
														type="text"
														class="form-control form-control-sm"
														name="address_{{ i }}"
														[ngModel]="contact.addresses[i]"
														(input)="contact.addresses[i] = $event.target.value.toUpperCase()"
														pattern="^[a-zA-Z\s]+$"
														maxlength="8"
														minlength="8"
														#addressAddress="ngModel"
														required />
													<div
														*ngIf="contact.addresses.length > 1"
														class="input-group-append">
														<button
															type="button"
															class="btn btn-outline-danger btn-sm"
															(click)="attemptDelete(i)">
															<i class="material-icons m-0">clear</i>
														</button>
													</div>
												</div>
												<div
													*ngIf="!addressAddress?.valid && (addressAddress?.dirty || addressAddress?.touched)"
													class="invalid-feedback d-block">
													<div
														*ngIf="addressAddress.errors.required"
														i18n="adm|@@adm.comMod.modal.addresses.err.addressReq">
														Address is required
													</div>
													<div *ngIf="addressAddress.errors.minlength">
														<span i18n="adm|@@adm.comMod.modal.addresses.err.addressminLength"
															>Address minimum length is</span
														>&nbsp;{{ addressAddress.errors.minlength?.requiredLength }}
													</div>
													<div
														*ngIf="addressAddress.errors.pattern"
														i18n="adm|@@adm.comMod.modal.movement.err.addressCodeEnOnly">
														Address is English only
													</div>
												</div>
											</ng-container>
											<ng-template #notAFTN>
												<ng-container *ngIf="contact.channelId == 2; else notSITATEX">
													<div class="input-group mb-2">
														<input
															type="text"
															id="address_{{ i }}"
															class="form-control form-control-sm"
															name="address_{{ i }}"
															[ngModel]="contact.addresses[i]"
															(input)="contact.addresses[i] = $event.target.value.toUpperCase()"
															pattern="^[a-zA-Z0-9\s]+$"
															maxlength="7"
															minlength="7"
															#addressAddress="ngModel"
															required />
														<div class="input-group-append">
															<button
																type="button"
																*ngIf="contact.addresses.length > 1"
																class="btn btn-outline-danger btn-sm"
																(click)="attemptDelete(i)">
																<i class="material-icons m-0">clear</i>
															</button>
														</div>
													</div>
													<div
														*ngIf="!addressAddress?.valid && (addressAddress?.dirty || addressAddress?.touched)"
														class="invalid-feedback d-block">
														<div
															*ngIf="addressAddress.errors.required"
															i18n="adm|@@adm.comMod.modal.addresses.err.addressReq">
															Address is required
														</div>
														<div *ngIf="addressAddress.errors.minlength">
															<span i18n="adm|@@adm.comMod.modal.addresses.err.addressminLength"
																>Address minimum length is</span
															>&nbsp;{{ addressAddress.errors.minlength?.requiredLength }}
														</div>
														<div
															*ngIf="addressAddress.errors.pattern"
															i18n="adm|@@adm.comMod.modal.movement.err.addressCodeEnNumOnly">
															Address can only contain numbers and English letters
														</div>
													</div>
												</ng-container>
												<ng-template #notSITATEX>
													<div class="input-group mb-2">
														<input
															type="email"
															id="address_{{ i }}"
															class="form-control form-control-sm"
															name="address_{{ i }}"
															[(ngModel)]="contact.addresses[i]"
															pattern="[^@]+@[^@]+\.[a-zA-Z]{2,6}"
															#addressAddress="ngModel"
															required />
														<div class="input-group-append">
															<button
																type="button"
																*ngIf="contact.addresses.length > 1"
																class="btn btn-outline-danger btn-sm"
																(click)="attemptDelete(i)">
																<i class="material-icons m-0">clear</i>
															</button>
														</div>
													</div>
													<div
														*ngIf="!addressAddress?.valid && (addressAddress?.dirty || addressAddress?.touched)"
														class="invalid-feedback d-block">
														<div
															*ngIf="addressAddress.errors.required"
															i18n="adm|@@adm.comMod.modal.addresses.err.addressReq">
															Address is required
														</div>
														<div
															*ngIf="addressAddress.errors.pattern"
															i18n="adm|@@adm.comMod.modal.movement.err.addressIncorrect">
															The address is incorrect
														</div>
													</div>
												</ng-template>
											</ng-template>
										</div>
									</div>
								</div>
								<div class="row mt-3">
									<div class="col-12">
										<button
											type="button"
											class="btn btn-sm btn-blue float-right"
											(click)="addContactAdress()">
											<i class="material-icons m-0">add</i
											><span i18n="adm|@@adm.comMod.modal.contacts.button.addAddress">Add address</span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							type="button"
							*ngIf="activeState === 'action-append'"
							class="btn btn-sm btn-blue"
							(click)="addContact()"
							i18n="adm|@@adm.comMod.modal.contacts.button.append">
							Append
						</button>
						<button
							type="button"
							*ngIf="activeState === 'action-update'"
							class="btn btn-sm btn-red"
							(click)="confirmAction(deleteContact)"
							i18n="adm|@@adm.comMod.modal.contacts.button.delete">
							Delete
						</button>
						<button
							type="button"
							*ngIf="activeState === 'action-update'"
							class="btn btn-sm btn-blue"
							(click)="updateContact(contact.id)"
							i18n="adm|@@adm.comMod.modal.contacts.button.save">
							Save
						</button>
						<button
							type="button"
							class="btn btn-sm btn-gray-blue-dark"
							data-dismiss="modal"
							i18n="adm|@@adm.comMod.modal.contacts.button.close">
							Close
						</button>
					</div>
					<div
						*ngIf="errorMessage !== ''"
						class="ms-modal-message">
						<div class="modal-content w-50">
							<div
								class="modal-header"
								[ngClass]="{
									'bg-info': errorType === 'info',
									'bg-warning': errorType === 'warning',
									'bg-danger': errorType === 'error'
								}">
								<h5
									*ngIf="errorType === 'info'"
									i18n="adm|@@adm.comMod.modal.header.information"
									class="modal-title">
									Information
								</h5>
								<h5
									*ngIf="errorType === 'warning'"
									i18n="adm|@@adm.comMod.modal.header.warning"
									class="modal-title">
									Warning
								</h5>
								<h5
									*ngIf="errorType === 'error'"
									i18n="adm|@@adm.comMod.modal.header.error"
									class="modal-title">
									Error
								</h5>
								<button
									type="button"
									class="close"
									(click)="clearErrorMess()"
									aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div class="modal-body">
								{{ errorMessage }}
							</div>
							<div class="modal-footer">
								<button
									type="button"
									*ngIf="userAction === 'delete'"
									class="btn btn-sm btn-blue"
									(click)="deleteElement(contact.addresses, indexDelete)"
									i18n="adm|@@adm.comMod.modal.contacts.button.yes">
									Yes
								</button>
								<button
									type="button"
									*ngIf="userAction === 'deleteItem'"
									class="btn btn-sm btn-blue"
									(click)="executableFunction(contact.id)"
									i18n="adm|@@adm.comMod.modal.contacts.button.yes">
									Yes
								</button>
								<button
									type="button"
									*ngIf="userAction === 'delete' || userAction === 'deleteItem'"
									class="btn btn-sm btn-gray-blue-dark"
									(click)="clearErrorMess()"
									i18n="adm|@@adm.comMod.modal.contacts.button.no">
									No
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>

<!-- Modal Internal Addresses -->
<div *ngIf="showTab('tab-addresses')">
	<form #addressForm="ngForm">
		<div
			class="modal fade bd-example-modal-xl"
			data-backdrop="static"
			tabindex="-1"
			role="dialog"
			aria-labelledby="myExtraLargeModalLabel"
			aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-xl">
				<div class="modal-content bg-light-gray">
					<div
						class="loading"
						*ngIf="loading">
						<div class="cssload-spin-box"></div>
					</div>
					<div class="modal-header">
						<h5
							*ngIf="activeState === 'action-append'"
							class="modal-title"
							i18n="adm|@@adm.comMod.modal.addresses.header.add">
							Append address
						</h5>
						<h5
							*ngIf="activeState === 'action-update'"
							class="modal-title"
							i18n="adm|@@adm.comMod.modal.addresses.header.edit">
							Edit address
						</h5>
						<button
							#closebutton
							type="button"
							class="close"
							data-dismiss="modal"
							aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="row mb-4">
							<div class="col-3">
								<label i18n="adm|@@adm.comMod.modal.addresses.field.airport"> Airport </label>
								<ng-select
									class="form-control custom-sm p-0"
									[items]="airports"
									bindLabel="iata"
									bindValue="id"
									[(ngModel)]="address.airportId"
									#internalAddressesAirportId="ngModel"
									appendTo="body"
									name="internalAddressesAirportId"
									[virtualScroll]="true"
									[loading]="airportsLoading"
									(scroll)="onScrollAirports($event)"
									(scrollToEnd)="onScrollAirportsToEnd()"
									[searchFn]="customSelectSearchAirport"
									required>
									<ng-template
										ng-option-tmp
										let-item="item">
										IATA: <span class="font-weight-bold">{{ item.iata }}</span
										>, ICAO: {{ item.icao }} <br />
										<small>{{ item.names }}</small>
									</ng-template>
								</ng-select>
								<div
									*ngIf="
										!internalAddressesAirportId?.valid &&
										(internalAddressesAirportId?.dirty || internalAddressesAirportId?.touched)
									"
									class="invalid-feedback d-block">
									<div
										*ngIf="internalAddressesAirportId.errors.required"
										i18n="adm|@@adm.comMod.modal.filters.err.airportReq">
										Airport is required
									</div>
								</div>
							</div>
							<div class="col-3">
								<label i18n="adm|@@adm.comMod.modal.addresses.field.channel"> Channel </label>
								<ng-select
									class="form-control custom p-0"
									[items]="channels"
									bindLabel="name"
									bindValue="id"
									[(ngModel)]="address.channelId"
									name="channelId"
									#channelId="ngModel"
									required>
								</ng-select>
								<div
									*ngIf="!channelId?.valid && (channelId?.dirty || channelId?.touched)"
									class="invalid-feedback d-block">
									<div
										*ngIf="channelId.errors.required"
										i18n="adm|@@adm.comMod.modal.filters.err.channelReq">
										Channel is required
									</div>
								</div>
							</div>
							<div class="col-3">
								<label i18n="adm|@@adm.comMod.modal.addresses.field.dtRangeStart"> Date range start </label>
								<input
									type="date"
									class="form-control"
									name="addressStart"
									#addressStart="ngModel"
									[ngModel]="address.dtRangeStart | date : 'yyyy-MM-dd' : 'UTC+0'"
									(blur)="address.dtRangeStart = parseDate($event.target.value)"
									required />
								<div
									*ngIf="!addressStart?.valid && (addressStart?.dirty || addressStart?.touched)"
									class="invalid-feedback d-block">
									<div
										*ngIf="addressStart.errors.required"
										i18n="adm|@@adm.comMod.modal.filters.err.addressStartReq">
										Date range start is required
									</div>
								</div>
							</div>
							<div class="col-3">
								<label i18n="adm|@@adm.comMod.modal.addresses.field.dtRangeFinish"> Date range finish </label>
								<input
									type="date"
									class="form-control"
									name="addressFinish"
									#addressFinish="ngModel"
									[ngModel]="address.dtRangeFinish | date : 'yyyy-MM-dd' : 'UTC+0'"
									(blur)="address.dtRangeFinish = parseDate($event.target.value)"
									required />
								<div
									*ngIf="!addressFinish?.valid && (addressFinish?.dirty || addressFinish?.touched)"
									class="invalid-feedback d-block">
									<div
										*ngIf="addressFinish.errors.required"
										i18n="adm|@@adm.comMod.modal.filters.err.addressFinishReq">
										Date range finish is required
									</div>
								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-body">
								<p
									class="font-weight-bold text-blue"
									i18n="adm|@@adm.comMod.modal.addresses.field.addresses">
									Addresses
								</p>
								<div class="row">
									<div
										class="col-4"
										*ngFor="let addr of address.addresses; let i = index; trackBy: trackByFn">
										<div class="input-group mb-2">
											<ng-container *ngIf="address.channelId == 1; else notAFTN">
												<input
													id="address_{{ i }}"
													type="text"
													class="form-control form-control-sm"
													name="address_{{ i }}"
													[ngModel]="address.addresses[i]"
													(input)="address.addresses[i] = $event.target.value.toUpperCase()"
													pattern="^[a-zA-Z\s]+$"
													maxlength="8"
													minlength="8"
													#addressAddress="ngModel"
													required />
												<div class="input-group-append">
													<button
														type="button"
														class="btn btn-outline-danger btn-sm"
														(click)="attemptDelete(i)">
														<i class="material-icons m-0">clear</i>
													</button>
												</div>
												<div
													*ngIf="!addressAddress?.valid && (addressAddress?.dirty || addressAddress?.touched)"
													class="invalid-feedback d-block">
													<div
														*ngIf="addressAddress.errors.required"
														i18n="adm|@@adm.comMod.modal.addresses.err.addressReq">
														Address is required
													</div>
													<div *ngIf="addressAddress.errors.minlength">
														<span i18n="adm|@@adm.comMod.modal.addresses.err.addressminLength"
															>Address minimum length is</span
														>&nbsp;{{ addressAddress.errors.minlength?.requiredLength }}
													</div>
													<div
														*ngIf="addressAddress.errors.pattern"
														i18n="adm|@@adm.comMod.modal.movement.err.addressCodeEnOnly">
														Address is English only
													</div>
												</div>
											</ng-container>
											<ng-template #notAFTN>
												<ng-container *ngIf="address.channelId == 2; else notSITATEX">
													<input
														type="text"
														id="address_{{ i }}"
														class="form-control form-control-sm"
														name="address_{{ i }}"
														[ngModel]="address.addresses[i]"
														(input)="address.addresses[i] = $event.target.value.toUpperCase()"
														pattern="^[a-zA-Z0-9\s]+$"
														maxlength="7"
														minlength="7"
														#addressAddress="ngModel"
														required />
													<div class="input-group-append">
														<button
															type="button"
															class="btn btn-outline-danger btn-sm"
															(click)="attemptDelete(i)">
															<i class="material-icons m-0">clear</i>
														</button>
													</div>
													<div
														*ngIf="!addressAddress?.valid && (addressAddress?.dirty || addressAddress?.touched)"
														class="invalid-feedback d-block">
														<div
															*ngIf="addressAddress.errors.required"
															i18n="adm|@@adm.comMod.modal.addresses.err.addressReq">
															Address is required
														</div>
														<div *ngIf="addressAddress.errors.minlength">
															<span i18n="adm|@@adm.comMod.modal.addresses.err.addressminLength"
																>Address minimum length is</span
															>&nbsp;{{ addressAddress.errors.minlength?.requiredLength }}
														</div>
														<div
															*ngIf="addressAddress.errors.pattern"
															i18n="adm|@@adm.comMod.modal.movement.err.addressCodeEnNumOnly">
															Address can only contain numbers and English letters
														</div>
													</div>
												</ng-container>
												<ng-template #notSITATEX>
													<input
														type="email"
														id="address_{{ i }}"
														class="form-control form-control-sm"
														name="address_{{ i }}"
														[(ngModel)]="address.addresses[i]"
														pattern="[^@]+@[^@]+\.[a-zA-Z]{2,6}"
														#addressAddress="ngModel"
														required />
													<div class="input-group-append">
														<button
															type="button"
															class="btn btn-outline-danger btn-sm"
															(click)="attemptDelete(i)">
															<i class="material-icons m-0">clear</i>
														</button>
													</div>
													<div
														*ngIf="!addressAddress?.valid && (addressAddress?.dirty || addressAddress?.touched)"
														class="invalid-feedback d-block">
														<div
															*ngIf="addressAddress.errors.required"
															i18n="adm|@@adm.comMod.modal.addresses.err.addressReq">
															Address is required
														</div>
														<div
															*ngIf="addressAddress.errors.pattern"
															i18n="adm|@@adm.comMod.modal.movement.err.addressIncorrect">
															The address is incorrect
														</div>
													</div>
												</ng-template>
											</ng-template>
										</div>
									</div>
								</div>
								<div class="row mt-3">
									<div class="col-12 mt-2">
										<button
											type="button"
											class="btn btn-sm btn-blue float-right"
											(click)="addAddressesAdress()">
											<i class="material-icons m-0">add</i
											><span i18n="adm|@@adm.comMod.modal.addresses.button.addAddress">Add address</span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							type="button"
							*ngIf="activeState === 'action-append'"
							class="btn btn-sm btn-blue"
							(click)="addAddress()"
							i18n="adm|@@adm.comMod.modal.addresses.button.append">
							Append
						</button>
						<button
							type="button"
							*ngIf="activeState === 'action-update'"
							class="btn btn-sm btn-red"
							(click)="confirmAction(deleteAddress)"
							i18n="adm|@@adm.comMod.modal.addresses.button.delete">
							Delete
						</button>
						<button
							type="button"
							*ngIf="activeState === 'action-update'"
							class="btn btn-sm btn-blue"
							(click)="updateAddress(address.id)"
							i18n="adm|@@adm.comMod.modal.addresses.button.save">
							Save
						</button>
						<button
							type="button"
							class="btn btn-sm btn-gray-blue-dark"
							data-dismiss="modal"
							i18n="adm|@@adm.comMod.modal.addresses.button.close">
							Close
						</button>
					</div>
					<div
						*ngIf="errorMessage !== ''"
						class="ms-modal-message">
						<div class="modal-content w-50">
							<div
								class="modal-header"
								[ngClass]="{
									'bg-info': errorType === 'info',
									'bg-warning': errorType === 'warning',
									'bg-danger': errorType === 'error'
								}">
								<h5
									*ngIf="errorType === 'info'"
									i18n="adm|@@adm.comMod.modal.header.information"
									class="modal-title">
									Information
								</h5>
								<h5
									*ngIf="errorType === 'warning'"
									i18n="adm|@@adm.comMod.modal.header.warning"
									class="modal-title">
									Warning
								</h5>
								<h5
									*ngIf="errorType === 'error'"
									i18n="adm|@@adm.comMod.modal.header.error"
									class="modal-title">
									Error
								</h5>
								<button
									type="button"
									class="close"
									(click)="clearErrorMess()"
									aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div class="modal-body">
								{{ errorMessage }}
							</div>
							<div class="modal-footer">
								<button
									type="button"
									*ngIf="userAction === 'delete'"
									class="btn btn-sm btn-blue"
									(click)="deleteElement(address.addresses, indexDelete)"
									i18n="adm|@@adm.comMod.modal.addresses.button.deleteYes">
									Yes
								</button>
								<button
									type="button"
									*ngIf="userAction === 'deleteItem'"
									class="btn btn-sm btn-blue"
									(click)="executableFunction(address.id)"
									i18n="adm|@@adm.comMod.modal.addresses.button.deleteYes">
									Yes
								</button>
								<button
									type="button"
									*ngIf="userAction === 'delete' || userAction === 'deleteItem'"
									class="btn btn-sm btn-gray-blue-dark"
									(click)="clearErrorMess()"
									i18n="adm|@@adm.comMod.modal.addresses.button.deleteNo">
									No
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>

<!-- Modal senders -->
<div *ngIf="showTab('tab-senders')">
	<form #senderForm="ngForm">
		<div
			class="modal fade bd-example-modal-xl"
			data-backdrop="static"
			tabindex="-1"
			role="dialog"
			aria-labelledby="myExtraLargeModalLabel"
			aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-xl">
				<div class="modal-content bg-light-gray">
					<div
						class="loading"
						*ngIf="loading">
						<div class="cssload-spin-box"></div>
					</div>
					<div class="modal-header">
						<h5
							*ngIf="activeState === 'action-append'"
							class="modal-title"
							i18n="adm|@@adm.comMod.modal.senders.header.add">
							Append sender
						</h5>
						<h5
							*ngIf="activeState === 'action-update'"
							class="modal-title"
							i18n="adm|@@adm.comMod.modal.senders.header.edit">
							Edit sender
						</h5>
						<button
							#closebutton
							type="button"
							class="close"
							data-dismiss="modal"
							aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="row mb-4">
							<div class="col-6">
								<label i18n="adm|@@adm.comMod.modal.senders.field.channel"> Channel </label>
								<ng-select
									class="form-control custom p-0"
									[items]="channels"
									bindLabel="name"
									bindValue="id"
									[(ngModel)]="sender.channelId"
									name="channelId"
									#channelId="ngModel"
									required>
								</ng-select>
								<div
									*ngIf="!channelId?.valid && (channelId?.dirty || channelId?.touched)"
									class="invalid-feedback d-block">
									<div
										*ngIf="channelId.errors.required"
										i18n="adm|@@adm.comMod.modal.senders.err.channelIdReq">
										Channel is required
									</div>
								</div>
							</div>
							<div class="col-6">
								<label i18n="adm|@@adm.comMod.modal.senders.field.address"> Address </label>
								<div class="input-group">
									<ng-container *ngIf="sender.channelId == 1; else notAFTN">
										<input
											id="senderAddress"
											type="text"
											class="form-control form-control-sm"
											name="senderAddress"
											#senderAddress="ngModel"
											[ngModel]="sender.addressEnglish"
											(input)="sender.addressEnglish = $event.target.value.toUpperCase()"
											pattern="^[a-zA-Z\s]+$"
											maxlength="8"
											minlength="8"
											required />
										<div
											*ngIf="!senderAddress?.valid && (senderAddress?.dirty || senderAddress?.touched)"
											class="invalid-feedback d-block">
											<div
												*ngIf="senderAddress.errors.required"
												i18n="adm|@@adm.comMod.modal.filters.err.addressReq">
												Address is required
											</div>
											<div
												*ngIf="!senderAddress?.valid"
												i18n="adm|@@adm.comMod.modal.filters.err.addressIncorrectly">
												The address is incorrectly
											</div>
										</div>
									</ng-container>
									<ng-template #notAFTN>
										<ng-container *ngIf="sender.channelId == 2; else notSITATEX">
											<input
												type="text"
												id="senderAddress"
												class="form-control form-control-sm"
												name="senderAddress"
												#senderAddress="ngModel"
												[ngModel]="sender.addressEnglish"
												(input)="sender.addressEnglish = $event.target.value.toUpperCase()"
												pattern="^[a-zA-Z0-9\s]+$"
												maxlength="7"
												minlength="7"
												required />
											<div
												*ngIf="!senderAddress?.valid && (senderAddress?.dirty || senderAddress?.touched)"
												class="invalid-feedback d-block">
												<div
													*ngIf="senderAddress.errors.required"
													i18n="adm|@@adm.comMod.modal.filters.err.addressReq">
													Address is required
												</div>
												<div
													*ngIf="!senderAddress?.valid"
													i18n="adm|@@adm.comMod.modal.filters.err.addressIncorrectly">
													The address is incorrectly
												</div>
											</div>
										</ng-container>
										<ng-template #notSITATEX>
											<input
												type="email"
												id="senderAddress"
												class="form-control form-control-sm"
												name="senderAddress"
												#senderAddress="ngModel"
												[(ngModel)]="sender.addressEnglish"
												pattern="[^@]+@[^@]+\.[a-zA-Z]{2,6}"
												required />
											<div
												*ngIf="!senderAddress?.valid && (senderAddress?.dirty || senderAddress?.touched)"
												class="invalid-feedback d-block">
												<div
													*ngIf="senderAddress.errors.required"
													i18n="adm|@@adm.comMod.modal.filters.err.addressReq">
													Address is required
												</div>
												<div
													*ngIf="!senderAddress?.valid"
													i18n="adm|@@adm.comMod.modal.filters.err.addressIncorrectly">
													The address is incorrectly
												</div>
											</div>
										</ng-template>
									</ng-template>
								</div>
							</div>
						</div>
						<div class="row mb-4">
							<!--<div class="col-3">
                <label
                       i18n="adm|@@adm.comMod.modal.senders.field.airport">
                  Airport
                </label>
                <ng-select class="form-control custom-sm p-0"
                           [items]="airports"
                           bindLabel="iata"
                           bindValue="id"
                           [(ngModel)]="sender.baseApId"
                           #internalAddressesAirportId="ngModel"
                           appendTo="body"
                           name="internalAddressesAirportId"
                           [virtualScroll]="true"
                           [loading]="airportsLoading"
                           (scroll)="onScrollAirports($event)"
                           (scrollToEnd)="onScrollAirportsToEnd()"
                           [searchFn]="customSelectSearchAirport"
                           required>
                  <ng-template ng-option-tmp let-item="item">
                    IATA: <span class="font-weight-bold">{{item.iata}}</span>, ICAO: {{item.icao}} <br />
                    <small>{{item.names}}</small>
                  </ng-template>
                </ng-select>
                <div *ngIf="!internalAddressesAirportId?.valid && (internalAddressesAirportId?.dirty || internalAddressesAirportId?.touched)"
                     class="invalid-feedback d-block">
                  <div *ngIf="internalAddressesAirportId.errors.required"
                        i18n="adm|@@adm.comMod.modal.senders.err.airportReq">
                    Airport is required
                  </div>
                </div>
              </div>-->
							<div class="col-6">
								<label i18n="adm|@@adm.comMod.modal.senders.field.folder"> Folder </label>
								<ng-select
									class="form-control custom-sm p-0"
									[items]="letterFolders"
									bindLabel="name"
									bindValue="id"
									[(ngModel)]="sender.folderId"
									#folderId="ngModel"
									appendTo="body"
									name="folderId"
									[virtualScroll]="true"
									required>
									<ng-template
										ng-option-tmp
										let-item="item">
										{{ item.name }}
									</ng-template>
								</ng-select>
								<div
									*ngIf="!folderId?.valid && (folderId?.dirty || folderId?.touched)"
									class="invalid-feedback d-block">
									<div
										*ngIf="folderId.errors.required"
										i18n="adm|@@adm.comMod.modal.senders.err.folderReq">
										Folder is required
									</div>
								</div>
							</div>
							<div class="col-3">
								<label i18n="adm|@@adm.comMod.modal.senders.field.dtRangeStart"> Date range start </label>
								<input
									type="date"
									class="form-control"
									name="addressStart"
									#addressStart="ngModel"
									[ngModel]="sender.dtRangeStart | date : 'yyyy-MM-dd' : 'UTC+0'"
									(blur)="sender.dtRangeStart = parseDate($event.target.value)"
									required />
								<div
									*ngIf="!addressStart?.valid && (addressStart?.dirty || addressStart?.touched)"
									class="invalid-feedback d-block">
									<div
										*ngIf="addressStart.errors.required"
										i18n="adm|@@adm.comMod.modal.filters.err.addressStartReq">
										Date range start is required
									</div>
								</div>
							</div>
							<div class="col-3">
								<label i18n="adm|@@adm.comMod.modal.senders.field.dtRangeFinish"> Date range finish </label>
								<input
									type="date"
									class="form-control"
									name="addressFinish"
									#addressFinish="ngModel"
									[ngModel]="sender.dtRangeFinish | date : 'yyyy-MM-dd' : 'UTC+0'"
									(blur)="sender.dtRangeFinish = parseDate($event.target.value)"
									required />
								<div
									*ngIf="!addressFinish?.valid && (addressFinish?.dirty || addressFinish?.touched)"
									class="invalid-feedback d-block">
									<div
										*ngIf="addressFinish.errors.required"
										i18n="adm|@@adm.comMod.modal.senders.err.addressStartReq">
										Date range start is required
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							type="button"
							*ngIf="activeState === 'action-append'"
							class="btn btn-sm btn-blue"
							(click)="addSender()"
							i18n="adm|@@adm.comMod.modal.senders.button.append">
							Append
						</button>
						<button
							type="button"
							*ngIf="activeState === 'action-update'"
							class="btn btn-sm btn-red"
							(click)="confirmAction(deleteSender)"
							i18n="adm|@@adm.comMod.modal.senders.button.delete">
							Delete
						</button>
						<button
							type="button"
							*ngIf="activeState === 'action-update'"
							class="btn btn-sm btn-blue"
							(click)="updateSender(sender.id)"
							i18n="adm|@@adm.comMod.modal.senders.button.save">
							Save
						</button>
						<button
							type="button"
							class="btn btn-sm btn-gray-blue-dark"
							data-dismiss="modal"
							i18n="adm|@@adm.comMod.modal.senders.button.close">
							Close
						</button>
					</div>
					<div
						*ngIf="errorMessage !== ''"
						class="ms-modal-message">
						<div class="modal-content w-50">
							<div
								class="modal-header"
								[ngClass]="{
									'bg-info': errorType === 'info',
									'bg-warning': errorType === 'warning',
									'bg-danger': errorType === 'error'
								}">
								<h5
									*ngIf="errorType === 'info'"
									i18n="adm|@@adm.comMod.modal.header.information"
									class="modal-title">
									Information
								</h5>
								<h5
									*ngIf="errorType === 'warning'"
									i18n="adm|@@adm.comMod.modal.header.warning"
									class="modal-title">
									Warning
								</h5>
								<h5
									*ngIf="errorType === 'error'"
									i18n="adm|@@adm.comMod.modal.header.error"
									class="modal-title">
									Error
								</h5>
								<button
									type="button"
									class="close"
									(click)="clearErrorMess()"
									aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div class="modal-body">
								{{ errorMessage }}
							</div>
							<div class="modal-footer">
								<button
									type="button"
									*ngIf="userAction === 'update_sender'"
									class="btn btn-sm btn-blue"
									(click)="updateSender(this.sender.id, true)"
									i18n="adm|@@adm.comMod.modal.filters.button.yes">
									Yes
								</button>
								<button
									type="button"
									class="btn btn-sm btn-gray-blue-dark"
									*ngIf="userAction === 'update_sender'"
									(click)="clearErrorMess()"
									i18n="ref|Modal terminal error - button close@@ref.main.modal.terminal.errMes.button.close">
									Cancel
								</button>
								<button
									type="button"
									*ngIf="userAction === 'deleteItem'"
									class="btn btn-sm btn-blue"
									(click)="executableFunction(sender.id)"
									i18n="adm|@@adm.comMod.modal.senders.button.deleteYes">
									Yes
								</button>
								<button
									type="button"
									*ngIf="userAction === 'deleteItem'"
									class="btn btn-sm btn-gray-blue-dark"
									(click)="clearErrorMess()"
									i18n="adm|@@adm.comMod.modal.senders.button.deleteNo">
									No
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>

<!-- Modal groups -->
<div *ngIf="showTab('tab-groups')">
	<form #groupForm="ngForm">
		<div
			class="modal fade bd-example-modal-xl"
			data-backdrop="static"
			tabindex="-1"
			role="dialog"
			aria-labelledby="myExtraLargeModalLabel"
			aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-xl">
				<div class="modal-content bg-light-gray">
					<div
						class="loading"
						*ngIf="loading">
						<div class="cssload-spin-box"></div>
					</div>
					<div class="modal-header">
						<h5
							*ngIf="activeState === 'action-append'"
							class="modal-title"
							i18n="adm|@@adm.comMod.modal.groups.header.add">
							Append group
						</h5>
						<h5
							*ngIf="activeState === 'action-update'"
							class="modal-title"
							i18n="adm|@@adm.comMod.modal.groups.header.edit">
							Edit group
						</h5>
						<button
							#closebutton
							type="button"
							class="close"
							data-dismiss="modal"
							aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="row mb-3">
							<div class="col-12">
								<label i18n="adm|@@adm.comMod.modal.groups.field.name"> Name </label>
								<div class="input-group">
									<input
										id="groupName"
										type="text"
										class="form-control form-control-sm"
										name="groupName"
										#groupName="ngModel"
										[(ngModel)]="group.name"
										required />
									<div
										*ngIf="!groupName?.valid && (groupName?.dirty || groupName?.touched)"
										class="invalid-feedback d-block">
										<div
											*ngIf="groupName.errors.required"
											i18n="adm|@@adm.comMod.modal.groups.err.nameReq">
											Name is required
										</div>
									</div>
								</div>
							</div>
							<!--<div class="col-6">
                <label
                       i18n="adm|@@adm.comMod.modal.groups.field.baseAirport">
                  Base airports
                </label>
                <ng-select class="form-control custom-sm p-0"
                           [items]="baseAirports"
                           bindLabel="iata"
                           bindValue="id"
                           [(ngModel)]="group.baseAps"
                           #groupbaseAps="ngModel"
                           appendTo="body"
                           [multiple]="true"
                           name="groupbaseAps"
                           [virtualScroll]="true"
                           required>
                  <ng-template ng-option-tmp let-item="item">{{item.names}}</ng-template>
                </ng-select>
                <div *ngIf="!groupbaseAps?.valid && (groupbaseAps?.dirty || groupbaseAps?.touched)"
                     class="invalid-feedback d-block">
                  <div *ngIf="groupbaseAps.errors.required"
                        i18n="adm|@@adm.comMod.modal.groups.err.baseAirportReq">
                    Base airports is required
                  </div>
                </div>
              </div>-->
						</div>
						<div class="card">
							<div class="card-body">
								<p
									class="font-weight-bold text-blue"
									i18n="adm|@@adm.comMod.modal.groups.foldersSettings">
									Folder settings
								</p>
								<div class="row">
									<div class="col-6">
										<label i18n="adm|@@adm.comMod.modal.groups.field.folder"> Folders </label>
									</div>
									<div class="col-6">
										<label i18n="adm|@@adm.comMod.modal.groups.field.Mode"> Mode </label>
									</div>
								</div>
								<div
									class="row mb-2"
									*ngFor="let folder of group.folders; let i = index">
									<div class="col-6">
										<ng-select
											class="form-control custom-sm p-0"
											[items]="filteredLetterFolder"
											bindLabel="name"
											bindValue="id"
											[(ngModel)]="folder.id"
											#folderId="ngModel"
											appendTo="body"
											name="folderId_{{ folder.hideid }}"
											[virtualScroll]="true"
											(clear)="filterFolderForGroup()"
											(open)="onOpenFolder()"
											required>
										</ng-select>
										<div
											*ngIf="!folderId?.valid && (folderId?.dirty || folderId?.touched)"
											class="invalid-feedback d-block">
											<div
												*ngIf="folderId.errors.required"
												i18n="adm|@@adm.comMod.modal.groups.err.folderReq">
												Folder is required
											</div>
										</div>
									</div>
									<div class="col-6">
										<div class="input-group">
											<ng-select
												class="form-control custom-sm p-0"
												[items]="modes"
												bindLabel="name"
												bindValue="name"
												[(ngModel)]="folder.mode"
												#folderMode="ngModel"
												appendTo="body"
												name="folderMode_{{ folder.hideid }}"
												[virtualScroll]="true"
												required>
												<ng-option></ng-option>
											</ng-select>
											<button
												type="button"
												class="btn btn-outline-danger btn-sm ml-2"
												(click)="conditionDelete(folder.hideid)">
												<i class="material-icons m-0">clear</i>&nbsp;
											</button>
										</div>
										<div
											*ngIf="!folderMode?.valid && (folderMode?.dirty || folderMode?.touched)"
											class="invalid-feedback d-block">
											<div
												*ngIf="folderMode.errors.required"
												i18n="adm|@@adm.comMod.modal.groups.err.folderModeReq">
												Mode is required
											</div>
										</div>
									</div>
								</div>
								<div class="row mt-3">
									<div class="col-12">
										<button
											type="button"
											class="btn btn-sm btn-blue float-right"
											(click)="addGroupFolder()">
											<i class="material-icons m-0">add</i
											><span i18n="adm|@@adm.comMod.modal.groups.button.addFolder">Add folder</span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							type="button"
							*ngIf="activeState === 'action-append'"
							class="btn btn-sm btn-blue"
							(click)="addGroup()"
							i18n="adm|@@adm.comMod.modal.groups.button.append">
							Append
						</button>
						<button
							type="button"
							*ngIf="activeState === 'action-update'"
							class="btn btn-sm btn-blue"
							(click)="updateGroup(group.id)"
							i18n="adm|@@adm.comMod.modal.groups.button.save">
							Save
						</button>
						<button
							type="button"
							class="btn btn-sm btn-gray-blue-dark"
							data-dismiss="modal"
							i18n="adm|@@adm.comMod.modal.groups.button.close">
							Close
						</button>
					</div>
					<div
						*ngIf="errorMessage !== ''"
						class="ms-modal-message">
						<div class="modal-content w-50">
							<div
								class="modal-header"
								[ngClass]="{
									'bg-info': errorType === 'info',
									'bg-warning': errorType === 'warning',
									'bg-danger': errorType === 'error'
								}">
								<h5
									*ngIf="errorType === 'info'"
									i18n="adm|@@adm.comMod.modal.header.information"
									class="modal-title">
									Information
								</h5>
								<h5
									*ngIf="errorType === 'warning'"
									i18n="adm|@@adm.comMod.modal.header.warning"
									class="modal-title">
									Warning
								</h5>
								<h5
									*ngIf="errorType === 'error'"
									i18n="adm|@@adm.comMod.modal.header.error"
									class="modal-title">
									Error
								</h5>
								<button
									type="button"
									class="close"
									(click)="clearErrorMess()"
									aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div class="modal-body">
								{{ errorMessage }}
							</div>
							<div class="modal-footer">
								<button
									type="button"
									*ngIf="userAction === 'delete'"
									class="btn btn-sm btn-blue"
									(click)="deleteGroupFolder()"
									i18n="adm|@@adm.comMod.modal.senders.button.deleteYes">
									Yes
								</button>
								<button
									type="button"
									*ngIf="userAction === 'delete'"
									class="btn btn-sm btn-gray-blue-dark"
									(click)="clearErrorMess()"
									i18n="adm|@@adm.comMod.modal.senders.button.deleteNo">
									No
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>

<!-- Modal users -->
<div *ngIf="showTab('tab-users')">
	<form #userForm="ngForm">
		<div
			class="modal fade bd-example-modal-xl"
			data-backdrop="static"
			tabindex="-1"
			role="dialog"
			aria-labelledby="myExtraLargeModalLabel"
			aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-xl">
				<div class="modal-content bg-light-gray">
					<div
						class="loading"
						*ngIf="loading">
						<div class="cssload-spin-box"></div>
					</div>
					<div class="modal-header">
						<h5
							*ngIf="activeState === 'action-append'"
							class="modal-title"
							i18n="adm|@@adm.comMod.modal.users.header.add">
							Append group
						</h5>
						<h5
							*ngIf="activeState === 'action-update'"
							class="modal-title"
							i18n="adm|@@adm.comMod.modal.users.header.edit">
							Edit user
						</h5>
						<button
							#closebutton
							type="button"
							class="close"
							data-dismiss="modal"
							aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="row mb-3">
							<div class="col-4">
								<label i18n="adm|@@adm.comMod.modal.users.field.name"> Username </label>
								<div class="input-group">
									<input
										id="username"
										type="text"
										class="form-control form-control-sm"
										name="username"
										#username="ngModel"
										[(ngModel)]="user.username"
										required />
									<div
										*ngIf="!username?.valid && (username?.dirty || username?.touched)"
										class="invalid-feedback d-block">
										<div
											*ngIf="username.errors.required"
											i18n="adm|@@adm.comMod.modal.users.err.usernameReq">
											Username is required
										</div>
									</div>
								</div>
							</div>
							<div class="col-4">
								<label i18n="adm|@@adm.comMod.modal.users.field.firstName"> First name </label>
								<div class="input-group">
									<input
										id="firstName"
										type="text"
										class="form-control form-control-sm"
										name="firstName"
										#firstName="ngModel"
										[(ngModel)]="user.firstName"
										required />
									<div
										*ngIf="!firstName?.valid && (firstName?.dirty || firstName?.touched)"
										class="invalid-feedback d-block">
										<div
											*ngIf="firstName.errors.required"
											i18n="adm|@@adm.comMod.modal.users.err.firstNameReq">
											First name is required
										</div>
									</div>
								</div>
							</div>
							<div class="col-4">
								<label i18n="adm|@@adm.comMod.modal.users.field.lastName"> Last name </label>
								<div class="input-group">
									<input
										id="lastName"
										type="text"
										class="form-control form-control-sm"
										name="lastName"
										#lastName="ngModel"
										[(ngModel)]="user.lastName"
										required />
									<div
										*ngIf="!lastName?.valid && (lastName?.dirty || lastName?.touched)"
										class="invalid-feedback d-block">
										<div
											*ngIf="lastName.errors.required"
											i18n="adm|@@adm.comMod.modal.users.err.lastNameReq">
											Last name is required
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-10">
								<label i18n="adm|@@adm.comMod.modal.users.field.groups"> Groups </label>
								<ng-select
									class="form-control custom-sm p-0"
									[items]="groups"
									bindLabel="name"
									bindValue="id"
									[(ngModel)]="user.groupsArray"
									#userGroups="ngModel"
									appendTo="body"
									[multiple]="true"
									name="userGroups"
									[closeOnSelect]="false"
									[virtualScroll]="true"
									required>
									<ng-template
										ng-option-tmp
										let-item="item"
										>{{ item.name }}</ng-template
									>
								</ng-select>
								<div
									*ngIf="!userGroups?.valid && (userGroups?.dirty || userGroups?.touched)"
									class="invalid-feedback d-block">
									<div
										*ngIf="userGroups.errors.required"
										i18n="adm|@@adm.comMod.modal.groups.err.groupsReq">
										Groups is required
									</div>
								</div>
							</div>
							<div class="col-2 pt-4 mt-2">
								<div class="form-check">
									<input
										class="form-check-input"
										type="checkbox"
										id="enabled"
										name="enabled"
										[(ngModel)]="user.enabled" />
									<label
										class="form-check-label"
										for="enabled"
										i18n="adm|@@adm.comMod.modal.users.field.enabled">
										Enabled
									</label>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							type="button"
							*ngIf="activeState === 'action-append'"
							class="btn btn-sm btn-blue"
							(click)="addUser()"
							i18n="adm|@@adm.comMod.modal.users.button.append">
							Append
						</button>
						<button
							type="button"
							*ngIf="activeState === 'action-update'"
							class="btn btn-sm btn-blue"
							(click)="updateUser(user.id)"
							i18n="adm|@@adm.comMod.modal.users.button.save">
							Save
						</button>
						<button
							type="button"
							class="btn btn-sm btn-gray-blue-dark"
							data-dismiss="modal"
							i18n="adm|@@adm.comMod.modal.users.button.close">
							Close
						</button>
					</div>
					<div
						*ngIf="errorMessage !== ''"
						class="ms-modal-message">
						<div class="modal-content w-50">
							<div
								class="modal-header"
								[ngClass]="{
									'bg-info': errorType === 'info',
									'bg-warning': errorType === 'warning',
									'bg-danger': errorType === 'error'
								}">
								<h5
									*ngIf="errorType === 'info'"
									i18n="adm|@@adm.comMod.modal.header.information"
									class="modal-title">
									Information
								</h5>
								<h5
									*ngIf="errorType === 'warning'"
									i18n="adm|@@adm.comMod.modal.header.warning"
									class="modal-title">
									Warning
								</h5>
								<h5
									*ngIf="errorType === 'error'"
									i18n="adm|@@adm.comMod.modal.header.error"
									class="modal-title">
									Error
								</h5>
								<button
									type="button"
									class="close"
									(click)="clearErrorMess()"
									aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div class="modal-body">
								{{ errorMessage }}
							</div>
							<div class="modal-footer">
								<button
									type="button"
									*ngIf="userAction === 'delete'"
									class="btn btn-sm btn-blue"
									(click)="deleteGroupFolder()"
									i18n="adm|@@adm.comMod.modal.senders.button.deleteYes">
									Yes
								</button>
								<button
									type="button"
									*ngIf="userAction === 'delete'"
									class="btn btn-sm btn-gray-blue-dark"
									(click)="clearErrorMess()"
									i18n="adm|@@adm.comMod.modal.senders.button.deleteNo">
									No
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>
