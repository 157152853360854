import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { NgZorroAntdModule } from 'src/app/ng-zorro-antd.module';
import { selectComponentsCurrentItem, selectComponentsStatus } from '../../store/control-service.selectors';
import { ControlServiceComponentActions, ControlServiceDefaultActions } from '../../store/control-service.actions';
import { ComponentModalComponent } from '../component-modal/component-modal.component';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-component-detail',
	standalone: true,
	imports: [NgZorroAntdModule, ComponentModalComponent, DatePipe],
	templateUrl: './component-detail.component.html',
	styleUrl: './component-detail.component.less',
})
export class ComponentDetailComponent {
	private readonly store = inject(Store);
	private readonly route: ActivatedRoute = inject(ActivatedRoute);
	private readonly REFRESH_STATUS_TIME = 15000;
	readonly component = this.store.selectSignal(selectComponentsCurrentItem);
	readonly status = this.store.selectSignal(selectComponentsStatus);
	private readonly router: Router = inject(Router);
	private currentItemId = null;
	showComponentModal = false;

	constructor() {
		this.route.params.subscribe(params => (this.currentItemId = params['id']));
		this.store.dispatch(ControlServiceComponentActions.read({ id: this.currentItemId }));
	}

	back() {
		this.store.dispatch(ControlServiceComponentActions.newItem());
		this.router.navigate(['control-service']);
	}

	showEditComponentModal(): void {
		this.showComponentModal = true;
	}

	componentNewModalIsVisibleChange(visibility: boolean) {
		this.showComponentModal = visibility;
	}

	restart() {
		this.store.dispatch(ControlServiceDefaultActions.restart({ id: this.currentItemId }));
		setTimeout(() => {
			this.refresh();
		}, this.REFRESH_STATUS_TIME);
	}

	refresh() {
		this.store.dispatch(ControlServiceDefaultActions.status({ id: this.currentItemId }));
	}
}
