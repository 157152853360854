import { APP_INITIALIZER, inject, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ControlServiceRoutingModule } from './control-service-routing.module';
import { EffectsModule } from '@ngrx/effects';
import { ControlServiceEffects } from './store/control-service.effects';
import {
	AppSupporterApiModule,
	AppSupporterConfiguration,
	AppSupporterConfigurationParameters,
} from '@shared/data-provider/app-supporter';
import { SettingsService } from '@core/services/settings/settings.service';
import { NgZorroAntdModule } from 'src/app/ng-zorro-antd.module';
import { ControlComponent } from './pages/control/control.component';
import { StoreModule } from '@ngrx/store';
import * as fromControlService from './store/control-service.reducer';
import { ComponentModalComponent } from './components/component-modal/component-modal.component';

export function apiConfigFactory(settingsService: SettingsService): AppSupporterConfiguration {
	const params: AppSupporterConfigurationParameters = {
		basePath: 'http://dev.msural.ru:2200', //settingsService.general.applicationConfig$.getValue().apiControlServiceURL,
	};
	return new AppSupporterConfiguration(params);
}

@NgModule({
	declarations: [],
	imports: [
		AppSupporterApiModule.forRoot(() => apiConfigFactory(inject(SettingsService))),
		NgZorroAntdModule,
		CommonModule,
		ControlServiceRoutingModule,
		ControlComponent,
		ComponentModalComponent,
		StoreModule.forFeature(fromControlService.controlServiceFeatureKey, fromControlService.reducer),
		EffectsModule.forFeature([ControlServiceEffects]),
	],
	providers: [SettingsService],
})
export class ControlServiceModule {}
