export class Document {
	preparedAt: string;
	preparedBy: string;
	text: string;
}

export class UpdateDocumentDto {
	constructor(
		flight_id: number,
		flight_number: string,
		airline_iata: string,
		workspace_id: number,
		route: {
			order: number;
			airport_iata: string;
		}[],
		dt_departure_scheduled: Date,
		edition_number: number,
		pilot: string,
		pilot_decision_date: Date,
		status: string = null,
		note: string = null
	) {
		this.flight_id = flight_id;
		this.flight_number = flight_number;
		this.airline_iata = airline_iata;
		this.workspace_id = workspace_id;
		this.route = route;
		this.dt_departure_scheduled = dt_departure_scheduled;
		this.edition_number = edition_number;
		this.pilot = pilot;
		this.pilot_decision_date = pilot_decision_date;
		this.status = status;
		this.note = note;
	}
	flight_id: number;
	flight_number: string;
	airline_iata: string;
	workspace_id: number;
	route: {
		order: number;
		airport_iata: string;
	}[];
	dt_departure_scheduled: Date;
	edition_number: number;
	pilot: string;
	pilot_decision_date: Date;
	status: string;
	note: string;
}
