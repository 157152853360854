import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ControlServiceComponentActions, ControlServiceDefaultActions } from './control-service.actions';
import { map, mergeMap, of, switchMap } from 'rxjs';
import { ComponentsService, DefaultService } from '@shared/data-provider/app-supporter';

@Injectable()
export class ControlServiceEffects {
	constructor(
		private actions$: Actions,
		private componentsService: ComponentsService,
		private defaultService: DefaultService
	) {}

	readAll$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ControlServiceComponentActions.readAll),
			switchMap(() =>
				this.componentsService
					.getComponents()
					.pipe(map(components => ControlServiceComponentActions.readAllSuccess({ components })))
			)
		);
	});

	createComponent$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ControlServiceComponentActions.create),
			mergeMap(arg =>
				this.componentsService
					.createComponent(arg.component)
					.pipe(map(apiResponse => ControlServiceComponentActions.createSuccess({ apiResponse })))
			),
			switchMap(() => of(ControlServiceComponentActions.readAll()))
		);
	});

	updateComponent$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ControlServiceComponentActions.update),
			switchMap(arg =>
				this.componentsService
					.updateComponent(arg.id, arg.component)
					.pipe(
						switchMap(apiResponse => [
							ControlServiceComponentActions.updateSuccess({ apiResponse }),
							ControlServiceComponentActions.read({ id: arg.id }),
						])
					)
			)
		);
	});

	readComponent$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ControlServiceComponentActions.read),
			switchMap(arg =>
				this.componentsService
					.getComponent(arg.id)
					.pipe(
						switchMap(component => [
							ControlServiceComponentActions.clearCurrentItemAndStatus(),
							ControlServiceComponentActions.readSuccess({ component }),
							ControlServiceDefaultActions.status({ id: arg.id }),
						])
					)
			)
		);
	});

	componentRestart$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ControlServiceDefaultActions.restart),
			switchMap(arg =>
				this.defaultService
					.componentsIdRestartPost(arg.id)
					.pipe(map(apiResponse => ControlServiceDefaultActions.restartSuccess({ apiResponse })))
			)
		);
	});

	componentStatus$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ControlServiceDefaultActions.status),
			switchMap(arg =>
				this.defaultService
					.componentsIdStatusPost(arg.id)
					.pipe(map(status => ControlServiceDefaultActions.statusSuccess({ status })))
			)
		);
	});

	componentNewItem$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ControlServiceComponentActions.newItem),
			switchMap(() => of(ControlServiceComponentActions.newItemSuccess()))
		);
	});
}
