import { createReducer, on } from '@ngrx/store';
import { AircraftType, Airline, Airport, Tail, Flight, Document, Desktop } from '../models';
import { WeightBalanceMobileActions, WeightBalanceMobileReferenceActions } from './weight-balance-mobile.actions';
import { ElectronicDocument } from '../../../weight-balance-data/weight-balance';

export const weightBalanceMobileFeatureKey = 'weightBalanceMobile';

export interface State {
	aircraftTypes: AircraftType[];
	airlines: Airline[];
	airports: Airport[];
	tails: Tail[];
	flights: Flight[];
	document: Document | null;
	electronicDocuments: ElectronicDocument[];
	desktops: Desktop[];
}

export const initialState: State = {
	aircraftTypes: [],
	airlines: [],
	airports: [],
	tails: [],
	flights: [],
	document: null,
	electronicDocuments: [],
	desktops: [],
};

export const reducer = createReducer(
	initialState,
	on(
		WeightBalanceMobileReferenceActions.getReferenceAircraftTypeSuccess,
		(state, { data }): State => ({ ...state, aircraftTypes: data })
	),
	on(
		WeightBalanceMobileReferenceActions.getReferenceAirlineSuccess,
		(state, { data }): State => ({ ...state, airlines: data })
	),
	on(
		WeightBalanceMobileReferenceActions.getReferenceAirportSuccess,
		(state, { data }): State => ({ ...state, airports: data })
	),
	on(
		WeightBalanceMobileReferenceActions.getReferenceTailSuccess,
		(state, { data }): State => ({ ...state, tails: data })
	),
	on(WeightBalanceMobileActions.getFlightsSuccess, (state, { data }): State => ({ ...state, flights: data })),
	on(WeightBalanceMobileActions.getLoadSheetSuccess, (state, { data }): State => ({ ...state, document: data })),
	on(
		WeightBalanceMobileActions.getLoadingInstructionSuccess,
		(state, { data }): State => ({ ...state, document: data })
	),
	on(
		WeightBalanceMobileActions.getElectronicDocumentsSuccess,
		(state, { data }): State => ({ ...state, electronicDocuments: data })
	),
	on(WeightBalanceMobileActions.getDesktopsSuccess, (state, { data }): State => ({ ...state, desktops: data }))
);
