import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgZorroAntdModule } from '../../../../ng-zorro-antd.module';
import { RouterLink } from '@angular/router';
import { Flight } from '../../models';
import { CommonModule } from '@angular/common';
import { DocumentStatus } from '../../../../weight-balance-data/weight-balance';
import { DateFormat } from '../../../../pipes/date-format';
import { TimeFormat } from '@shared/models/time-format';
import { SettingsService } from '@core/services/settings/settings.service';
import { GlobalI18n } from '@settings/global-i18n';
import { Module } from '@shared/models/module-name';

@Component({
	selector: 'aac-weight-balance-mobile-list',
	standalone: true,
	imports: [NgZorroAntdModule, RouterLink, CommonModule, DateFormat],
	templateUrl: './weight-balance-mobile-list.component.html',
	styleUrl: './weight-balance-mobile-list.component.less',
})
export class WeightBalanceMobileListComponent implements OnInit {
	@Input()
	flights: Flight[] = [];
	documentStatus = DocumentStatus;

	@Output()
	selectedFlight = new EventEmitter<number>();

	userShowTime = TimeFormat.Z;

	constructor(private settingsService: SettingsService, private globalI18n: GlobalI18n) {}

	selectFlight(id: number) {
		this.selectedFlight.emit(id);
	}

	ngOnInit(): void {
		this.settingsService.user.settings$.subscribe(() => {
			this.userShowTime = this.settingsService.user.getTime();
		});
	}

	getStatusName(status: DocumentStatus) {
		switch (status) {
			case DocumentStatus.SENT:
				return this.globalI18n.getMessage(Module.WeightBalanceMobile, 'sent');
			case DocumentStatus.APPROVED:
				return this.globalI18n.getMessage(Module.WeightBalanceMobile, 'approved');
			case DocumentStatus.REJECTED:
				return this.globalI18n.getMessage(Module.WeightBalanceMobile, 'rejected');
			default:
				return '';
		}
	}
}
