import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appSetItemFocus]',
  standalone: true
})
export class SetItemFocusDirective {
  constructor(private hostElement: ElementRef) {}

  @Input()
  set appSetItemFocus(focus: boolean) {
    if (focus) {
      this.hostElement.nativeElement.focus();
    }
  }
}
