import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ControlComponent } from './pages/control/control.component';
import { AppAuthGuard } from '../../app.authguard';
import { ComponentDetailComponent } from './components/component-detail/component-detail.component';

const routes: Routes = [
	{
		path: 'control-service',
		component: ControlComponent,
		canActivate: [AppAuthGuard],
		data: {
			roles: ['aac_control-service'],
		},
		// children: [],
	},
	{ path: 'control-service/component/:id', component: ComponentDetailComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {})],
	exports: [RouterModule],
})
export class ControlServiceRoutingModule {}
