export class ReportParams {
    constructor() {
        const today = new Date();
        this.finish = new Date(today.setUTCHours(23, 59, 59)).toISOString();
        this.start = new Date(today.setUTCHours(0, 0, 0, 0)).toISOString();
        this.date = today.toISOString();
        this.full = false;
        this.period = 'day';
        this.channel = null;
    }
    id: string;
    start: string;
    finish: string;
    date: string;
    period: string;
    name: string;
    airline: string;
    airlines: string;
    movement: string;
    geo: string;
    aircraft: string;
    airport: string;
    season: string;
    regularity: string;
    export: string;
    full: boolean;
    groupBy: string;
    recipients: string;
    channel: number;
    airports: Array<number>;
    irregularityAirports: Array<number>;
    totals: boolean;
    statementTypes: Array<string>;
    statuses: string;
    irregularities: string;
    reportType: string;
    reportCategory: string;
    reportGrouping: string;
    usernames: Array<string>;
    forwarding: string;
    foundOnBoard: boolean;
    folders: Array<string>;
}

export class ReportDailyPlan {
    arr: Array<ReportDailyPlanItem>;
    dep: Array<ReportDailyPlanItem>;
}

export class ReportDailyPlanItem {
    id: number;
    aircraftType: string;
    airline: string;
    dt: string;
    start: string;
    finish: string;
    flight: string;
    frequency: string;
    geoType: string;
    movementType: string;
    route: Array<string>;
}

export class ReportSynchron {
    id: number;
    aircraftType: string;
    airline: string;
    arrdep: string;
    dt: string;
    start: string;
    finish: string;
    flight: string;
    frequency: string;
    geoType: string;
    movementType: string;
    route: Array<string>;
}

// ReportGroup->ReportSubGroup->ReportItem
// Типы отчетов
export class ReportGroup {
    id: number;
    private title_: Array<string> = [];
    reportSubGroup: Array<ReportSubGroup> = [];
    rights: number;

    set title(value: Array<string>) {
        this.title_ = value;
    }

    /**
     * Функция вывода названия группы отчетов
     * @param {string} language наименования текущего языка системы
     */
    getTitle(language: string): string {
        if ((this.title_) && (this.title_.length >= 1)) {
            if (language == 'EN') {
                return this.title_[0];
            } else if (language == 'RU') {
                return this.title_[1];
            }
        } else {
            return null;
        }
    }
}

export class ReportSubGroup {
    id: number;
    private title_: Array<string> = [];
    reportItem: Array<ReportItem> = [];

    set title(value: Array<string>) {
        this.title_ = value;
    }

    /**
     * Функция вывода названия подгруппы отчетов
     * @param {string} language наименования текущего языка системы
     */
    getTitle(language: string): string {
        if ((this.title_) && (this.title_.length >= 1)) {
            if (language == 'EN') {
                return this.title_[0];
            } else if (language == 'RU') {
                return this.title_[1];
            }
        } else {
            return null;
        }
    }
}

export class ReportItem {
    constructor(id, title: Array<string>) {
        this.id = id;
        this.title_ = title;
    }
    id: number;
    private title_: Array<string> = [];
    description_: Array<string> = [];
    formula_: Array<string> = [];
    // varVisual: Array<string>;
    visualizationOptions: Array<string>;
    visual: Array<string>;
    favorite: number;
    url: string; // URL для получения данных
    port: string;
    params: Array<number>; // Список ID возможных параметров для запроса

    set title(value: Array<string>) {
        this.title_ = value;
    }

    /**
     * Функция вывода названия отчеты
     * @param {string} language наименования текущего языка системы
     */
    getTitle(language: string): string {
        if ((this.title_) && (this.title_.length >= 1)) {
            if (language == 'EN') {
                return this.title_[0];
            } else if (language == 'RU') {
                return this.title_[1];
            }
        } else {
            return null;
        }
    }

    set description(value: Array<string>) {
        this.description_ = value;
    }

    /**
     * Функция вывода примечания к отчету
     * @param {string} language наименования текущего языка системы
     */
    getDescription(language: string): string {
        if ((this.description_) && (this.description_.length >= 1)) {
            if (language == 'EN') {
                return this.description_[0];
            } else if (language == 'RU') {
                return this.description_[1];
            }
        } else {
            return this.description_[0];
        }
    }

    set formula(value: Array<string>) {
        this.formula_ = value;
    }

    /**
     * Функция вывода примечания к отчету
     * @param {string} language наименования текущего языка системы
     */
    getFormula(language: string): string {
        if ((this.formula_) && (this.formula_.length >= 1)) {
            if (language == 'EN') {
                return this.formula_[0];
            } else if (language == 'RU') {
                return this.formula_[1];
            }
        } else {
            return this.formula_[0];
        }
    }
}

// Модель выбранного отчета
export class ReportData {
    id: number; // Должен совпадать с ReportItem
    chart: Array<ChartItem> = []; // Простейшие настройки для диаграмм
    columns: Array<string>; // Названия столбцов в базе данных
    titles: Array<string>; // Отображаемые заголовки столбцов
    titlesEn: Array<string>; // Английские заголовки столбцов
    titlesRu: Array<string>; // Русские заголовки столбцов
    data: any = [];
    visual = 1; // Способ визуализации. 1 - 'Диаграмма', 2 - 'График'
    // Данные приходят в виде объекта, чтобы можно было перебирать их с помощью ngFor,
    // создаем вспомогательный массив с заголовками
    dataNames: Array<string>;
}

// Настройки вывода для диаграммы
export class ChartItem {
    constructor(id, columnTitle, columnData, title) {
        this.id = id;
        this.columnTitle = columnTitle;
        this.columnData = columnData;
        this.title = title;
    }
    id: number;
    columnTitle: string; // Столбик для заголовка
    columnData: string; // Столбик для данных
    title: string; // Заголовок отчета
}

// Пока не используются
// export class ReportVariantVisual {
//     arr: Array<ReportVariantVisualItem> = [];
// }
// export class ReportVariantVisualItem {
//     id: number;
//     title: string;
// }
// Пока не используются
// export class ReportVariantVisual2 {
//     arr: Array<ReportVariantVisual2Item> = [];
// }
// export class ReportVariantVisual2Item {
//     id: number;
//     title: string;
// }


// Example
export class ReportExample {
    id: number;
    aircraftType: string;
}

export class ReportMediaLogs {
    client: string;
    interrupted: boolean;
    media: string;
    start: Date;
}

export class Dashboard {
    constructor() {
        this.id = null;
        this.name = '';
        this.reports = [];
    }

    id: number;
    name: string;
    reports: Array<DashboardChart>;
}

export class DashboardChart {
    constructor() {
        this.id = null;
        this.reportId = null;
        this.configurationId = null;
        this.show = '';
        this.updateTime = null;
        this.size = 4;
    }

    id: number;
    reportId: number;
    configurationId: number;
    show: string;
    updateTime: number;
    size: number;
    dataTitles: Array<string>;
    data: Array<Array<string>>;
}

export class MesssageWindow {
    constructor() {
        this.show = false;
        this.text = '';
        this.type = '';
    }

    show: boolean;
    text: string;
    title: string;
    type: string;
    func: Function;
    id: number;
}

export class reportShow {
    id: number;
    value: string;
    title: string;
}
