import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
	Playlist,
	Media,
	Client,
	Specification,
	InformationAirline,
	InformationAirport,
	InformationStatus,
	ClientType,
	GeoType,
	Template,
} from '../types/informational';
import { AdministrationRestApiService } from '../services/administration-rest-api.service';
import { SettingsService } from '@core/services/settings/settings.service';

@Component({
	selector: 'app-am-informational',
	templateUrl: './am-informational.component.html',
	styleUrls: ['./am-informational.component.less'],
})
export class AmInformationalComponent implements OnInit {
	apiInformationalUrl: string;

	playlists: Array<Playlist> = [];
	playlist: Playlist = new Playlist();
	medias: Array<Media> = [];
	media: Media = new Media();
	clients: Array<Client> = [];
	client: Client = new Client();
	specifications: Array<Specification> = [];
	specification: Specification = new Specification();
	informationAirlines: Array<InformationAirline> = [];
	informationAirline: InformationAirline = new InformationAirline();
	informationAirports: Array<InformationAirport> = [];
	informationAirport: InformationAirport = new InformationAirport();
	informationStatuses: Array<InformationStatus> = [];
	informationStatus: InformationStatus = new InformationStatus();

	clientTypes: Array<ClientType> = [];
	geoTypes: Array<GeoType> = [];
	informationTemplates: Array<Template> = [];

	// Загрузка
	loading = false;

	@ViewChild('modalAddPlaylist') modalAddPlaylist: any;
	@ViewChild('modalAddMedia') modalAddMedia: any;
	@ViewChild('modalAddClient') modalAddClient: any;
	@ViewChild('modalAddSpecification') modalAddSpecification: any;
	@ViewChild('modalAddAirline') modalAddAirline: any;
	@ViewChild('modalAddAirport') modalAddAirport: any;
	@ViewChild('modalAddStatuses') modalAddStatuses: any;

	activeTab = 'tab-playlists';
	activeState = 'action-append';
	errorMessage = '';
	errorType = '';
	userAction = '';

	// Загрузка файла
	selectedFile: File = null;
	logoFiles: Array<File> = [];

	constructor(
		public restApi: AdministrationRestApiService,
		private elRef: ElementRef,
		private settingsService: SettingsService,
	) {
		settingsService.general.applicationConfig$.subscribe(config => {
			this.apiInformationalUrl = config.apiInformationalURL;
		});
	}

	ngOnInit() {
		this.loadPlaylists();
		this.loadMedias();
	}

	// Playlists
	loadPlaylists() {
		this.loading = true;
		return this.restApi.getPlaylists().subscribe(
			(data: Playlist[]) => {
				this.playlists = [];
				for (const item of data) {
					const playlist = new Playlist();
					Object.assign(playlist, item);
					this.playlists.push(playlist);
				}
				this.loading = false;
			},
			err => {
				if (err.type) {
					this.errorType = err.type;
					this.errorMessage = err.message;
					console.log('Error: ' + err.message + '\ndetail:' + err.detail);
				} else {
					this.errorMessage = err;
				}
				this.loading = false;
			}
		);
	}

	loadPlaylist(id: number) {
		return this.restApi.getPlaylist(id).subscribe((data: Playlist) => {
			this.activeState = 'action-update';
			Object.assign(this.playlist, data);
		});
	}

	createPlaylist() {
		this.activeState = 'action-append';
		this.playlist = new Playlist();
	}

	addPlaylist() {
		return this.restApi.addPlaylist(this.playlist).subscribe((data: {}) => {
			this.modalAddPlaylist.nativeElement.click();
			this.loadPlaylists();
		});
	}

	updatePlaylist(id: number) {
		this.restApi.updatePlaylist(id, this.playlist).subscribe(data => {
			this.modalAddPlaylist.nativeElement.click();
			this.loadPlaylists();
		});
	}

	// Medias
	loadMedias() {
		this.loading = true;
		return this.restApi.getMedias().subscribe(
			(data: Media[]) => {
				this.medias = [];
				for (const item of data) {
					const media = new Media();
					Object.assign(media, item);
					this.medias.push(media);
				}
				this.loading = false;
			},
			err => {
				if (err.type) {
					this.errorType = err.type;
					this.errorMessage = err.message;
					console.log('Error: ' + err.message + '\ndetail:' + err.detail);
				} else {
					this.errorMessage = err;
				}
				this.loading = false;
			}
		);
	}

	onFileSelected(event) {
		this.selectedFile = <File>event.target.files[0];
	}

	loadMedia(id: number) {
		return this.restApi.getMedia(id).subscribe((data: Media) => {
			this.activeState = 'action-update';
			Object.assign(this.media, data);
			// Перезагрузка видео
			const player = this.elRef.nativeElement.querySelector('video');
			if (player) {
				player.load();
			}
		});
	}

	createMedia() {
		this.activeState = 'action-append';
		this.media = new Media();
	}

	addMedia() {
		return this.restApi.addMedia(this.media, this.selectedFile).subscribe((data: {}) => {
			this.modalAddMedia.nativeElement.click();
			this.loadMedias();
		});
	}

	// Specifications
	loadSpecifications() {
		this.loading = true;
		return this.restApi.getSpecifications().subscribe(
			(data: Specification[]) => {
				this.specifications = [];
				for (const item of data) {
					const specification = new Specification();
					Object.assign(specification, item);
					this.specifications.push(specification);
				}
				this.loading = false;
			},
			err => {
				if (err.type) {
					this.errorType = err.type;
					this.errorMessage = err.message;
					console.log('Error: ' + err.message + '\ndetail:' + err.detail);
				} else {
					this.errorMessage = err;
				}
				this.loading = false;
			}
		);
	}

	loadSpecification(id: number) {
		return this.restApi.getSpecification(id).subscribe((data: Specification) => {
			this.activeState = 'action-update';
			Object.assign(this.specification, data);
		});
	}

	createSpecification() {
		this.activeState = 'action-append';
		this.specification = new Specification();
	}

	addSpecification() {
		return this.restApi.addSpecification(this.specification).subscribe((data: {}) => {
			this.modalAddSpecification.nativeElement.click();
			this.loadSpecifications();
		});
	}

	updateSpecification(id: number) {
		this.restApi.updateSpecification(id, this.specification).subscribe(data => {
			this.modalAddSpecification.nativeElement.click();
			this.loadSpecifications();
		});
	}

	// Clients
	loadClients() {
		this.loading = true;
		return this.restApi.getClients().subscribe(
			(data: Client[]) => {
				this.clients = [];
				for (const item of data) {
					const client = new Client();
					Object.assign(client, item);
					this.clients.push(client);
				}
				this.loading = false;
			},
			err => {
				if (err.type) {
					this.errorType = err.type;
					this.errorMessage = err.message;
					console.log('Error: ' + err.message + '\ndetail:' + err.detail);
				} else {
					this.errorMessage = err;
				}
				this.loading = false;
			}
		);
	}

	loadClient(id: string) {
		return this.restApi.getClient(id).subscribe((data: Client) => {
			this.activeState = 'action-update';
			Object.assign(this.client, data);
		});
	}

	updateClient(id: string) {
		this.restApi.updateClient(id, this.client).subscribe(data => {
			this.modalAddClient.nativeElement.click();
			this.loadClients();
		});
	}

	// Information Airlines
	loadInformationAirlines() {
		this.loading = true;
		return this.restApi.getInformationAirlines().subscribe(
			(data: InformationAirline[]) => {
				this.informationAirlines = [];
				for (const item of data) {
					const airline = new InformationAirline();
					Object.assign(airline, item);
					this.informationAirlines.push(airline);
				}
				this.loading = false;
			},
			err => {
				if (err.type) {
					this.errorType = err.type;
					this.errorMessage = err.message;
					console.log('Error: ' + err.message + '\ndetail:' + err.detail);
				} else {
					this.errorMessage = err;
				}
				this.loading = false;
			}
		);
	}

	loadInformationAirline(id: number) {
		return this.restApi.getInformationAirline(id).subscribe((data: InformationAirline) => {
			this.activeState = 'action-update';
			Object.assign(this.informationAirline, data);
		});
	}

	logoSelected(event, name: string) {
		this.logoFiles[name] = <File>event.target.files[0];
	}

	updateInformationAirline(id: number) {
		this.restApi.updateInformationAirline(id, this.informationAirline, this.logoFiles).subscribe(data => {
			this.modalAddAirline.nativeElement.click();
			this.loadInformationAirlines();
		});
	}

	// Information Airports
	loadInformationAirports() {
		this.loading = true;
		return this.restApi.getInformationAirports().subscribe(
			(data: InformationAirport[]) => {
				this.informationAirports = [];
				for (const item of data) {
					const airport = new InformationAirport();
					Object.assign(airport, item);
					this.informationAirports.push(airport);
				}
				this.loading = false;
			},
			err => {
				if (err.type) {
					this.errorType = err.type;
					this.errorMessage = err.message;
					console.log('Error: ' + err.message + '\ndetail:' + err.detail);
				} else {
					this.errorMessage = err;
				}
				this.loading = false;
			}
		);
	}

	loadInformationAirport(id: number) {
		return this.restApi.getInformationAirport(id).subscribe((data: InformationAirport) => {
			this.activeState = 'action-update';
			Object.assign(this.informationAirport, data);
			// Перезагрузка картинок
			const img = this.elRef.nativeElement.querySelector('img');
			if (img) img.load();
		});
	}

	updateInformationAirport(id: number) {
		this.restApi.updateInformationAirport(id, this.informationAirport).subscribe(data => {
			this.modalAddAirport.nativeElement.click();
			this.loadInformationAirports();
		});
	}

	// Information Statuses
	loadInformationStatuses() {
		this.loading = true;
		return this.restApi.getInformationStatuses().subscribe(
			(data: InformationStatus[]) => {
				this.informationStatuses = [];
				for (const item of data) {
					const status = new InformationStatus();
					Object.assign(status, item);
					this.informationStatuses.push(status);
				}
				this.loading = false;
			},
			err => {
				if (err.type) {
					this.errorType = err.type;
					this.errorMessage = err.message;
					console.log('Error: ' + err.message + '\ndetail:' + err.detail);
				} else {
					this.errorMessage = err;
				}
				this.loading = false;
			}
		);
	}

	loadInformationStatus(id: number) {
		return this.restApi.getInformationStatus(id).subscribe((data: InformationStatus) => {
			this.activeState = 'action-update';
			Object.assign(this.informationStatus, data);
		});
	}

	updateInformationStatus(id: number) {
		this.restApi.updateInformationStatus(id, this.informationStatus).subscribe(data => {
			this.modalAddStatuses.nativeElement.click();
			this.loadInformationStatuses();
		});
	}

	loadClientTypes() {
		return this.restApi.getClientTypes().subscribe(
			(data: ClientType[]) => {
				this.clientTypes = [];
				for (const item of data) {
					const type = new ClientType();
					Object.assign(type, item);
					this.clientTypes.push(type);
				}
			},
			err => {
				if (err.type) {
					this.errorType = err.type;
					this.errorMessage = err.message;
					console.log('Error: ' + err.message + '\ndetail:' + err.detail);
				} else {
					this.errorMessage = err;
				}
			}
		);
	}

	loadGeoTypes() {
		return this.restApi.getGeoTypes().subscribe(
			(data: GeoType[]) => {
				this.geoTypes = [];
				for (const item of data) {
					const type = new GeoType();
					Object.assign(type, item);
					this.geoTypes.push(type);
				}
			},
			err => {
				if (err.type) {
					this.errorType = err.type;
					this.errorMessage = err.message;
					console.log('Error: ' + err.message + '\ndetail:' + err.detail);
				} else {
					this.errorMessage = err;
				}
			}
		);
	}

	loadInformationTemplates() {
		return this.restApi.getInformationTemplates().subscribe(
			(data: Template[]) => {
				this.informationTemplates = [];
				for (const item of data) {
					const template = new Template();
					Object.assign(template, item);
					this.informationTemplates.push(template);
				}
			},
			err => {
				if (err.type) {
					this.errorType = err.type;
					this.errorMessage = err.message;
					console.log('Error: ' + err.message + '\ndetail:' + err.detail);
				} else {
					this.errorMessage = err;
				}
			}
		);
	}

	changeTab(event: { target: { id: string } }) {
		this.activeTab = event.target.id;
		this.activeState = 'action-append';
		switch (this.activeTab) {
			case 'tab-playlists': {
				this.loadPlaylists();
				this.loadMedias();
				break;
			}
			case 'tab-medias': {
				this.loadMedias();
				break;
			}
			case 'tab-clients': {
				this.loadClients();
				this.loadInformationTemplates();
				this.loadSpecifications();
				break;
			}
			case 'tab-specifications': {
				this.loadSpecifications();
				this.loadClientTypes();
				this.loadGeoTypes();
				break;
			}
			case 'tab-airlines': {
				this.loadInformationAirlines();
				break;
			}
			case 'tab-airports': {
				this.loadInformationAirports();
				break;
			}
			case 'tab-statuses': {
				this.loadInformationStatuses();
				break;
			}
		}
	}

	showTab(item: string): boolean {
		if (item === this.activeTab) {
			return true;
		} else {
			return false;
		}
	}

	showButton(item: string): boolean {
		if (item === this.activeState) {
			return true;
		} else {
			return false;
		}
	}

	parseDate(dateString: string): Date {
		if (dateString) {
			return new Date(dateString);
		}
		return null;
	}

	sortById(arr) {
		arr.sort((a, b) => (a.id > b.id ? 1 : -1));
	}

	getById(arr, search) {
		for (const value in arr) {
			if (arr[value].id === search) {
				return arr[value];
			}
		}
		return null;
	}

	// чтобы не терялся фокус при вводе адресов, столкнулась опять с багом при переборе массива в котором только значения (как с пассажирами в суточнике)
	trackByFn(index: any, item: any) {
		return index;
	}

	updateArray(array: Array<any>, id: number) {
		const index = array.indexOf(id);
		if (index > -1) {
			array.splice(index, 1);
		} else {
			array.push(id);
		}
	}

	clearErrorMess() {
		this.errorMessage = '';
	}

	get language() {
		return this.settingsService.language;
	}
}
