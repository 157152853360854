import { Component, OnInit } from '@angular/core';
import { MasterDataService } from '../../../../shared/data-provider/master-data-api/api/masterData.service';
import { Tail } from '../../../../shared/data-provider/master-data-api/model/tail';
import { map } from 'rxjs';
import { AircraftType } from '@shared/data-provider/master-data-api/model/aircraftType';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { GlobalI18n } from '@settings/global-i18n';
import { Module } from '@shared/models/module-name';
import { convertDateToUTC } from '@shared/functions/dateFunctions';
import { SettingsService } from '@core/services/settings/settings.service';

interface ExtendedTail extends Tail {
	dtRangeStart?: Date;
	dtRangeFinish?: Date;
}

@Component({
	selector: 'app-tails',
	templateUrl: './tails.component.html',
	styleUrls: ['./tails.component.less'],
})
export class TailsComponent implements OnInit {
	constructor(
		private apiMasterData: MasterDataService,
		private settingsService: SettingsService,
		private modal: NzModalService,
		private fb: UntypedFormBuilder,
		private globalI18n: GlobalI18n
	) {}

	private _tails: ExtendedTail[] = [];
	tail: ExtendedTail;
	tailForm: FormGroup;

	listOfDisplayAircraftTypes: { label: string; value: number }[] = [];

	settingValue = {
		loadingList: false,
		loadingModal: false,
		saving: false,
		searchValue: '',
		isVisibleModal: false,
		pageIndex: 1,
	};

	listOfColumn = [];

	ngOnInit() {
		this.loadAircraftTypes();
		this.loadTails();
		this.listOfColumn = [
			{
				title: this.globalI18n.getMessage(Module.MasterData, 'columnTail'),
				compare: (a: ExtendedTail, b: ExtendedTail) => a.tail.localeCompare(b.tail),
				priority: false,
			},
			{
				title: this.globalI18n.getMessage(Module.MasterData, 'columnLastUpdate'),
				compare: (a: ExtendedTail, b: ExtendedTail) => Date.parse(a.lastupdate) - Date.parse(b.lastupdate),
				priority: false,
			},
		];
	}

	loadTails() {
		this.settingValue.loadingList = true;
		this.apiMasterData.selectRecords('tails').subscribe((data: Tail[]) => {
			this._tails = data.map(tail => {
				return {
					...tail,
					nameLocal: this.settingsService.language == 'EN' ? tail.aircraftTypeName[0] : tail.aircraftTypeName[1],
					nameEn: tail.aircraftTypeName[0],
					nameRu: tail.aircraftTypeName[1],
					dtRangeStart: new Date(tail.dtRange[0]),
					dtRangeFinish: new Date(tail.dtRange[1]),
				};
			});
			this.settingValue.loadingList = false;
		});
	}

	get listOfDisplayTails(): ExtendedTail[] {
		// При установленных фильтрах необходимо сбросить
		// пагинацию на 1 страницу для отображения результатов
		if (this.settingValue.searchValue.length !== 0) {
			this.onPageIndexChanged(1);
		}
		return this._tails.filter(item => {
			return item.tail.toLowerCase().indexOf(this.settingValue.searchValue.toLowerCase()) != -1;
		});
	}

	onPageIndexChanged(index: number) {
		this.settingValue.pageIndex = index;
	}

	loadAircraftTypes() {
		this.apiMasterData.selectRecords('aircraft_types').subscribe((data: AircraftType[]) => {
			this.listOfDisplayAircraftTypes = data.map(type => ({
				value: type.id,
				label: this.settingsService.language == 'EN' ? type.name[0] : type.name[1],
			}));
		});
	}

	openTail(id: number) {
		this.settingValue.loadingList = true;
		return this.apiMasterData
			.selectRecord('tails', id.toString())
			.pipe(
				map((tail: Tail) => {
					return {
						...tail,
						dtRangeStart: new Date(tail.dtRange[0]),
						dtRangeFinish: new Date(tail.dtRange[1]),
					};
				})
			)
			.subscribe((tail: ExtendedTail) => {
				this.tail = tail;
				this.tailForm = this.createTailForm(tail);
				this.showModal();
				this.settingValue.loadingList = false;
			});
	}

	addTail() {
		this.tail = {};
		this.tailForm = this.createTailForm();
		this.showModal();
	}

	createTailForm(tail?: ExtendedTail) {
		return this.fb.group({
			tail: [tail ? tail.tail : '', [Validators.required]],
			mtow: [tail ? tail.mtow : null, [Validators.required]],
			aircraftTypeId: [tail ? tail.aircraftTypeId : null, [Validators.required]],
			dtRangeStart: [tail ? convertDateToUTC(new Date(tail.dtRangeStart)) : new Date(), [Validators.required]],
			dtRangeFinish: [
				tail ? convertDateToUTC(new Date(tail.dtRangeFinish)) : new Date('2099-12-31 23:59'),
				[Validators.required],
			],
		});
	}

	add() {
		this.settingValue.saving = true;
		this.apiMasterData.insertRecord('tails', null, this.createTailFromForm()).subscribe({
			next: () => {
				this.closeModal();
				this.loadTails();
			},
			error: err => {
				this.displayError(err);
			},
			complete: () => {
				this.settingValue.saving = false;
			},
		});
		return;
	}

	save() {
		this.settingValue.loadingModal = true;
		this.apiMasterData.updateRecord('tails', this.tail.id.toString(), this.createTailFromForm()).subscribe({
			next: () => {
				this.closeModal();
				this.loadTails();
			},
			error: err => this.displayError(err),
			complete: () => {
				this.settingValue.loadingModal = false;
			},
		});
	}

	delete() {
		this.settingValue.saving = true;
		this.tailForm.get('dtRangeFinish').setValue(new Date());
		this.save();
	}

	duplicate() {
		this.tail.id = undefined;
		this.tail.lastupdate = undefined;
	}

	showDeleteConfirm(): void {
		this.modal.confirm({
			nzTitle: this.globalI18n.getMessage(Module.MasterData, 'deleteItem'),
			nzOkText: this.globalI18n.getMessage(Module.MasterData, 'yes'),
			nzOkType: 'primary',
			nzOkDanger: true,
			nzOnOk: () => this.delete(),
			nzCancelText: this.globalI18n.getMessage(Module.MasterData, 'no'),
			nzOnCancel: () => console.log('Cancel'),
		});
	}

	createTailFromForm(): Tail {
		return {
			...this.tail,
			...this.tailForm.value,
			dtRange: [this.tailForm.get('dtRangeStart').value, this.tailForm.get('dtRangeFinish').value],
		};
	}

	downloadCsvFile() {
		this.apiMasterData
			.selectRecords('tails', 'csv', 'events', true, {
				httpHeaderAccept: 'text/csv',
			})
			.subscribe(() => {});
	}

	showModal(): void {
		this.settingValue.isVisibleModal = true;
	}

	closeModal(): void {
		this.settingValue.isVisibleModal = false;
	}

	displayError(err): void {
		this.modal.create({
			nzTitle: 'Error',
			nzContent: err.message,
			nzClosable: false,
		});
	}
}
