<nz-layout class="h-100">
	<nz-header class="bg-white border-bottom">
		<nz-space>
			<p
				*nzSpaceItem
				class="text-black-50"
				i18n="@@control-service.header.name">
				Control service
			</p>
			<button
				*nzSpaceItem
				nz-button
				nzType="default"
				(click)="showNewComponentModal()"
				i18n="@@control-service.page.control.button.addComponent">
				Add component
			</button>
		</nz-space>
	</nz-header>
	<nz-content class="h-100 bg-white">
		<div
			nz-row
			class="h-100">
			<div
				nz-col
				nzSpan="24">
				<nz-table
					#sortTable
					[nzData]="components()"
					nzTableLayout="fixed">
					<thead>
						<tr>
							@for (column of listOfColumns; track column) {
							<th
								[nzSortFn]="column.compare"
								[nzSortPriority]="column.priority">
								{{ column.name }}
							</th>
							}
						</tr>
					</thead>
					<tbody>
						@for (data of sortTable.data; track data) {
						<tr [routerLink]="['/control-service/component', data.id]">
							<td>{{ data.id }}</td>
							<td>{{ data.org }}</td>
							<td>{{ data.project }}</td>
							<td>{{ data.componentType }}</td>
						</tr>
						}
					</tbody>
				</nz-table>
			</div>
		</div>
		<app-component-modal
			[componentNewModalIsVisible]="showComponentModal"
			(componentNewModalIsVisibleChange)="componentNewModalIsVisibleChange($event)"></app-component-modal>
	</nz-content>
</nz-layout>
