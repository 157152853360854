<nz-layout class="h-100">
	<nz-header class="bg-white border-bottom">
		<nz-space>
			<!-- HEADER CONTENT -->
			<p
				class="text-black-50"
				i18n="@@set.header.name">
				Settings
			</p>
		</nz-space>
	</nz-header>
	<nz-content class="h-100 bg-white px-2">
		<!-- PAGE CONTENT HERE -->
		<nz-tabset>
			<nz-tab
				nzTitle="General"
				i18n-nzTitle="@@set.group.general">
				<h5
					nz-typography
					i18n="@@set.group.general.language.name">
					Language
				</h5>
				<p i18n="@@set.group.general.language.note">Sets the display language.</p>
				<div nz-row>
					<div
						nz-col
						nzSpan="12">
						<nz-select
							class="w-100"
							[(ngModel)]="language">
							<nz-option
								*ngFor="let lang of languageList"
								[nzLabel]="lang.name"
								[nzValue]="lang.code">
							</nz-option>
						</nz-select>
					</div>
				</div>
				<nz-divider></nz-divider>
				<h5
					nz-typography
					i18n="@@set.group.general.defaultPage.name">
					Default page
				</h5>
				<p i18n="@@set.group.general.defaultPage.note">Sets the page to be opened at login.</p>
				<div nz-row>
					<div
						nz-col
						nzSpan="12">
						<nz-select
							class="w-100"
							[(ngModel)]="loadableModule">
							<nz-option
								*ngFor="let item of moduleList"
								[nzLabel]="item.name"
								[nzValue]="item.id">
							</nz-option>
						</nz-select>
					</div>
				</div>
				<nz-divider></nz-divider>
				<h5
					nz-typography
					i18n="@@set.group.general.timeZone.name">
					Time zone
				</h5>
				<p i18n="@@set.group.general.timeZone.note">
					Sets the home time zone. Used to convert dates and times to local time.
				</p>
				<div
					nz-row
					[nzGutter]="12">
					<div
						nz-col
						nzSpan="12">
						<nz-select
							[nzCustomTemplate]="timeZoneTemplate"
							class="w-100"
							[(ngModel)]="timeZone">
							<nz-option
								*ngFor="let item of timeZoneList"
								[nzLabel]="item"
								[nzValue]="item.name"
								[nzKey]="item.name"
								nzCustomContent>
								{{ item.offset }} {{ item.name }}
							</nz-option>
						</nz-select>
						<ng-template
							#timeZoneTemplate
							let-selected>
							{{ selected.nzLabel.offset }} {{ selected.nzLabel.name }}
						</ng-template>
					</div>
					<div
						nz-col
						nzSpan="4">
						<button
							nz-button
							nzType="default"
							i18n="@@set.group.general.timeZone.btn.autoDetect"
							(click)="timeZoneAutoDetect()">
							Auto-detect
						</button>
					</div>
				</div>
				<nz-divider></nz-divider>
				<h5
					nz-typography
					i18n="@@set.group.general.timeFormat.name">
					Time format
				</h5>
				<p i18n="@@set.group.general.timeFormat.note">
					Sets the format for entering and displaying dates and times in the system.
				</p>
				<p>
					<span i18n="@@set.group.general.timeFormat.note2">For local time the offset is taken from the setting</span>
					<span nz-typography><code i18n="@@set.group.general.timeFormat.note3">General > Time zone</code></span>
				</p>
				<p>
					<span i18n="@@set.group.general.timeFormat.note4">Example of time in the selected format:</span>
					{{ exampleDateTimeForUserTimeFormat | dateFormat : 'DD-MM-YYYY HH:mm' : userCurrentTimeFormat }}
				</p>
				<div nz-row>
					<div
						nz-col
						nzSpan="12">
						<nz-select
							class="w-100"
							[(ngModel)]="time">
							<nz-option
								*ngFor="let item of timeList"
								[nzLabel]="item.name"
								[nzValue]="item.code">
							</nz-option>
						</nz-select>
					</div>
				</div>
			</nz-tab>
			<nz-tab
				nzTitle="Notifications"
				i18n-nzTitle="@@set.group.notifications">
				<h5
					nz-typography
					i18n="@@set.group.notifications.settings.name">
					Settings
				</h5>
				<label
					nz-checkbox
					[ngModel]="notificationsIsEnabled"
					(ngModelChange)="changeNotificationState($event)"
					i18n="@@set.group.notifications.settings.checkbox.showNotifications"
					>Show notifications</label
				>
				<br />
				@if (notificationsIsEnabled) { @if (notificationIsSupport) { @if (permissionToNotificationIsGranted) {
				<label
					nz-checkbox
					nzDisabled
					[ngModel]="true"
					i18n="@@set.group.notifications.settings.checkbox.showPopUp"
					>Show pop-up messages on your desktop or mobile device screen</label
				>
				} @else {
				<label
					nz-checkbox
					nzDisabled
					[ngModel]="false"
					i18n="@@set.group.notifications.settings.checkbox.showPopUp"
					>Show pop-up messages on your desktop or mobile device screen</label
				>
				<br />
				<button
					nz-button
					nzType="default"
					class="ml-4"
					i18n="@@set.group.notifications.settings.button.allowPopUp"
					(click)="requestPermissionToShowBrowserNotification()">
					Allow pop-up messages
				</button>
				} } @else {
				<p i18n="@@set.group.notifications.settings.popUpDoNotSupport">
					Sending messages on your desktop or mobile device screen is not supported by your system
				</p>
				} }
				<nz-divider></nz-divider>
				<h5
					nz-typography
					i18n="@@set.group.notifications.subscriptions.name">
					Subscriptions
				</h5>
				<p i18n="@@set.group.general.subscriptions.note">Configuring notifications about events in the system.</p>
				<div nz-row>
					<div
						nz-col
						nzSpan="12">
						<div class="w-100">
							<nz-checkbox-group
								nz-flex
								[nzVertical]="true"
								[(ngModel)]="notificationsSubscribeList"
								(ngModelChange)="changeSubscriptions(notificationsSubscribeList)"></nz-checkbox-group>
						</div>
					</div>
				</div>
				<nz-divider></nz-divider>
			</nz-tab>
			<nz-tab
				nzTitle="Weight balance"
				[nzDisabled]="true"
				i18n-nzTitle="@@set.group.weightBalance"
				>Weight balance</nz-tab
			>
		</nz-tabset>
	</nz-content>
	<nz-footer class="p-2">
		<div
			class="text-black-50"
			nz-tooltip
			nzTooltipTitle="{{ getVersionHashAndDate() }}"
			[nzTooltipPlacement]="['topLeft', 'leftTop']"
			[nzTooltipTrigger]="'click'">
			<span>Version</span>&nbsp;{{ getVersion() }}
		</div>
	</nz-footer>
</nz-layout>
