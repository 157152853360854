import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { GlobalI18n } from '@settings/global-i18n';
import { Module } from '@shared/models/module-name';
import { MasterDataService } from '@shared/data-provider/master-data-api/api/masterData.service';
import { convertDateToUTC } from '@shared/functions/dateFunctions';
import { NzModalService } from 'ng-zorro-antd/modal';
import { map } from 'rxjs';
import { MasterDataFunctionsService } from '../../services/master-data.service';
import { CustomFormValidators } from '@shared/custom-form-validators';
import { Airport } from '@shared/data-provider/master-data-api/model/airport';
import { City } from '@shared/data-provider/master-data-api/model/city';
import { SettingsService } from '@core/services/settings/settings.service';

interface ExtendedAirport extends Airport {
	nameEn?: string;
	nameRu?: string;
	nameLocal?: string;
	codeEn?: string;
	codeRu?: string;
	codeLocal?: string;
	winterTzOffset?: number;
	summerTzOffset?: number;
	dtRangeStart?: Date;
	dtRangeFinish?: Date;
}

@Component({
	selector: 'app-airports',
	templateUrl: './airports.component.html',
	styleUrls: ['./airports.component.less'],
})
export class AirportsComponent implements OnInit {
	constructor(
		private apiMasterData: MasterDataService,
		private settingsService: SettingsService,
		private modal: NzModalService,
		private fb: UntypedFormBuilder,
		private globalI18n: GlobalI18n,
		private functionsService: MasterDataFunctionsService
	) {}

	private formValidators: CustomFormValidators = new CustomFormValidators();

	private _airports: ExtendedAirport[] = [];
	airport: ExtendedAirport;
	airportForm: FormGroup;

	listOfDisplayCities: { value: number; label: string }[] = [];

	settingValue = {
		loadingList: false,
		loadingModal: false,
		saving: false,
		searchIata: '',
		searchIcao: '',
		searchName: '',
		isVisibleModal: false,
		loadedPercent: null,
		pageIndex: 1,
	};

	listOfColumn = [];

	ngOnInit() {
		this.loadAircraftCities();
		this.loadAirports();
		this.listOfColumn = [
			{
				title: 'IATA',
				compare: (a: ExtendedAirport, b: ExtendedAirport) => a.iata.localeCompare(b.iata),
				priority: false,
			},
			{
				title: 'ICAO',
				compare: (a: ExtendedAirport, b: ExtendedAirport) => a.icao.localeCompare(b.icao),
				priority: false,
			},
			{
				title: this.globalI18n.getMessage(Module.MasterData, 'columnCode'),
				compare: (a: ExtendedAirport, b: ExtendedAirport) => a.codeLocal.localeCompare(b.codeLocal),
				priority: false,
			},
			{
				title: this.globalI18n.getMessage(Module.MasterData, 'columnName'),
				compare: (a: ExtendedAirport, b: ExtendedAirport) => a.nameLocal.localeCompare(b.nameLocal),
				priority: false,
			},
			{
				title: this.globalI18n.getMessage(Module.MasterData, 'columnLastUpdate'),
				compare: (a: ExtendedAirport, b: ExtendedAirport) => Date.parse(a.lastupdate) - Date.parse(b.lastupdate),
				priority: false,
			},
		];
	}

	loadAircraftCities() {
		this.apiMasterData.selectRecords('cities').subscribe((data: City[]) => {
			this.listOfDisplayCities = data.map(item => ({
				value: item.id,
				label: this.settingsService.language == 'EN' ? item.name[0] : item.name[1],
			}));
		});
	}

	loadAirports() {
		this.settingValue.loadingList = true;
		this.apiMasterData.selectRecords('airports').subscribe((data: Airport[]) => {
			this._airports = data.map(item => {
				return {
					...item,
					nameEn: item.name[0],
					nameRu: item.name[1],
					nameLocal: this.settingsService.language == 'EN' ? item.name[0] : item.name[1],
					codeEn: item.code[0],
					codeRu: item.code[1],
					codeLocal: this.settingsService.language == 'EN' ? item.code[0] : item.code[1],
					winterTzOffset: item.tzOffset[0],
					summerTzOffset: item.tzOffset[1],
					dtRangeStart: new Date(item.dtRange[0]),
					dtRangeFinish: new Date(item.dtRange[1]),
				};
			});
			this.settingValue.loadingList = false;
		});
	}

	get listOfDisplayAirports(): ExtendedAirport[] {
		// При установленных фильтрах необходимо сбросить пагинацию на 1 страницу
		if (
			this.settingValue.searchIata.length !== 0 ||
			this.settingValue.searchIcao.length !== 0 ||
			this.settingValue.searchName.length !== 0
		) {
			this.onPageIndexChanged(1);
		}
		return this._airports.filter(item => {
			return (
				item.iata.toLowerCase().indexOf(this.settingValue.searchIata.toLowerCase()) != -1 &&
				item.icao.toLowerCase().indexOf(this.settingValue.searchIcao.toLowerCase()) != -1 &&
				(item.nameRu.toLowerCase().indexOf(this.settingValue.searchName.toLowerCase()) != -1 ||
					item.nameEn.toLowerCase().indexOf(this.settingValue.searchName.toLowerCase()) != -1)
			);
		});
	}

	openAirport(id: number) {
		this.settingValue.loadingList = true;
		return this.apiMasterData
			.selectRecord('airports', id.toString())
			.pipe(
				map((item: Airport) => {
					return {
						...item,
						nameEn: item.name[0],
						nameRu: item.name[1],
						nameLocal: this.settingsService.language == 'EN' ? item.name[0] : item.name[1],
						codeEn: item.code[0],
						codeRu: item.code[1],
						codeLocal: this.settingsService.language == 'EN' ? item.code[0] : item.code[1],
						winterTzOffset: item.tzOffset[0],
						summerTzOffset: item.tzOffset[1],
						dtRangeStart: new Date(item.dtRange[0]),
						dtRangeFinish: new Date(item.dtRange[1]),
					};
				})
			)
			.subscribe((airport: ExtendedAirport) => {
				this.airport = airport;
				this.airportForm = this.createForm(airport);
				this.showModal();
				this.settingValue.loadingList = false;
			});
	}

	addNew() {
		this.airport = {};
		this.airportForm = this.createForm();
		this.showModal();
	}

	createForm(data?: ExtendedAirport) {
		return this.fb.group(
			{
				iata: [
					data ? data.iata : '',
					[this.formValidators.onlyEnglishAndNumbers(), this.formValidators.lengthValidator(3)],
				],
				icao: [
					data ? data.icao : '',
					[this.formValidators.onlyEnglishAndNumbers(), this.formValidators.lengthValidator(4)],
				],
				nameEn: [data ? data.nameEn : '', [Validators.required, this.formValidators.onlyEnglish()]],
				nameRu: [data ? data.nameRu : '', [Validators.required]],
				codeEn: [data ? data.codeEn : '', [this.formValidators.onlyEnglish()]],
				codeRu: [data ? data.codeRu : ''],
				countryId: [data ? data.countryId : '', [Validators.required]],
				cityId: [data ? data.cityId : '', [Validators.required]],
				winterTzOffset: [
					data ? data.winterTzOffset : '',
					[Validators.required, this.formValidators.maxMinValue(-12, 24)],
				],
				summerTzOffset: [
					data ? data.summerTzOffset : '',
					[Validators.required, this.formValidators.maxMinValue(-12, 24)],
				],
				dtRangeStart: [data ? convertDateToUTC(new Date(data.dtRangeStart)) : new Date(), [Validators.required]],
				dtRangeFinish: [
					data ? convertDateToUTC(new Date(data.dtRangeFinish)) : new Date('2099-12-31 23:59'),
					[Validators.required],
				],
			},
			{
				validators: [this.formValidators.requiredFieldValidator(['iata', 'icao', 'codeRu'])],
			}
		);
	}

	add() {
		this.airportForm.markAllAsTouched();
		if (this.airportForm.valid) {
			this.settingValue.saving = true;
			this.apiMasterData.insertRecord('airports', null, this.createFromForm()).subscribe({
				next: () => {
					this.closeModal();
					this.loadAirports();
				},
				error: err => {
					this.displayError(err);
				},
				complete: () => {
					this.settingValue.saving = false;
				},
			});
		}
	}

	save() {
		this.airportForm.markAllAsTouched();
		if (this.airportForm.valid) {
			this.settingValue.saving = true;
			this.apiMasterData.updateRecord('airports', this.airport.id.toString(), this.createFromForm()).subscribe({
				next: () => {
					this.closeModal();
					this.loadAirports();
				},
				error: err => this.displayError(err),
				complete: () => {
					this.settingValue.saving = false;
				},
			});
		}
	}

	delete() {
		this.settingValue.saving = true;
		this.airportForm.get('dtRangeFinish').setValue(new Date());
		this.apiMasterData.updateRecord('airports', this.airport.id.toString(), this.createFromForm()).subscribe({
			next: () => {
				this.closeModal();
				this.loadAirports();
			},
			error: err => this.displayError(err),
			complete: () => {
				this.settingValue.saving = false;
			},
		});
	}

	duplicate() {
		this.airport.id = undefined;
		this.airport.lastupdate = undefined;
	}

	showDeleteConfirm(): void {
		this.modal.confirm({
			nzTitle: this.globalI18n.getMessage(Module.MasterData, 'deleteItem'),
			nzOkText: this.globalI18n.getMessage(Module.MasterData, 'yes'),
			nzOkType: 'primary',
			nzOkDanger: true,
			nzOnOk: () => this.delete(),
			nzCancelText: this.globalI18n.getMessage(Module.MasterData, 'no'),
			nzOnCancel: () => console.log('Cancel'),
		});
	}

	createFromForm(): Airport {
		return {
			...this.airport,
			...this.airportForm.value,
			name: [this.airportForm.get('nameEn').value, this.airportForm.get('nameRu').value],
			code: [this.airportForm.get('codeEn').value, this.airportForm.get('codeRu').value],
			tzOffset: [this.airportForm.get('winterTzOffset').value, this.airportForm.get('summerTzOffset').value],
			dtRange: [this.airportForm.get('dtRangeStart').value, this.airportForm.get('dtRangeFinish').value],
		};
	}

	downloadCsvFile() {
		this.functionsService.downloadCsvFile('airports', this.settingValue.loadingList);
	}

	downloadXmlFile() {
		this.functionsService.downloadXmlFile('airports', this.settingValue.loadingList);
	}

	onPageIndexChanged(index: number) {
		this.settingValue.pageIndex = index;
	}

	showModal(): void {
		this.settingValue.isVisibleModal = true;
	}

	closeModal(): void {
		this.settingValue.isVisibleModal = false;
	}

	displayError(err): void {
		this.modal.create({
			nzTitle: 'Error',
			nzContent: err.message,
			nzClosable: false,
		});
	}
}
