import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgZorroAntdModule } from '../../../../ng-zorro-antd.module';
import { CommonModule } from '@angular/common';
import { Flight, Document } from '../../models';
import { TimeFormat } from '@shared/models/time-format';
import { SettingsService } from '@core/services/settings/settings.service';
import { DateFormat } from '../../../../pipes/date-format';
import { DocumentStatus } from '../../../../weight-balance-data/weight-balance';
import { Module } from '@shared/models/module-name';
import { GlobalI18n } from '@settings/global-i18n';

@Component({
	selector: 'aac-weight-balance-mobile-item',
	standalone: true,
	imports: [CommonModule, NgZorroAntdModule, DateFormat],
	templateUrl: './weight-balance-mobile-item.component.html',
	styleUrl: './weight-balance-mobile-item.component.less',
})
export class WeightBalanceMobileItemComponent implements OnInit {
	@Input()
	flight: Flight = null;

	@Input()
	document: Document = null;

	currentDocumentIndex = 0;

	@Output()
	documentChange = new EventEmitter<number>();

	documentType = ['Loadsheet', 'Loading instruction'];
	userShowTime = TimeFormat.Z;
	DocumentStatus = DocumentStatus;

	constructor(private settingsService: SettingsService, private globalI18n: GlobalI18n) {}

	ngOnInit(): void {
		this.settingsService.user.settings$.subscribe(() => {
			this.userShowTime = this.settingsService.user.getTime();
		});
	}

	reloadDocument() {
		this.document = null;
		this.documentChange.emit(this.currentDocumentIndex);
	}

	documentIndexChange(e: number): void {
		this.currentDocumentIndex = e;
		this.reloadDocument();
	}

	getStatusName(status: DocumentStatus) {
		switch (status) {
			case DocumentStatus.SENT:
				return this.globalI18n.getMessage(Module.WeightBalanceMobile, 'sent');
			case DocumentStatus.APPROVED:
				return this.globalI18n.getMessage(Module.WeightBalanceMobile, 'approved');
			case DocumentStatus.REJECTED:
				return this.globalI18n.getMessage(Module.WeightBalanceMobile, 'rejected');
			default:
				return '';
		}
	}
}
